import xhr from '../plugins/axios';

export default {
  namespaced: true,
  state: {
    filterDepartements: [],
  },

  mutations: {
    setFilterDepartements(state, data) {
      state.filterDepartements = data;
    },
  },

  actions: {
    loadById(store, { cdpeId }) {
      return xhr.get(`api/admin/cdpe/${cdpeId}`);
    },
    loadAllDepartements() {
      return xhr.get('api/admin/cdpe');
    },
    loadFilterDepartements({ commit, state }) {
      if (state.filterDepartements.length > 0) {
        return;
      }

      return xhr.get('api/admin/cdpe').then(response => {
        commit('setFilterDepartements', response.data.map(({ id, code, nom }) => ({ id, code, nom })));
      });
    },
  },

  getters: {
    getFilterDepartements(state) {
      return state.filterDepartements;
    },
  },
};
