<template>
  <v-data-table
    dense
    no-data-text="Pas de part local"
    :hide-default-footer="true"
    :headers="headers"
    item-key="id"
    :items="partsLocales"
  >
    <template v-slot:item="props">
      <tr>
        <td v-if="hasActions">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="modifier(props.item.cl.id)">
                <v-list-item-title>Modifier</v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="deletePart(props.item.cl.id)"
              >
                <v-list-item-title>Supprimer</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>{{ props.item.cl.nom }}</td>
        <td>{{ props.item.niveau.libelle }}</td>
        <td>{{ getMoyenPaiementById(props.item.moyen_paiement).libelle }}</td>
        <td>{{ props.item.montant | currency }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { getMoyenPaiementById } from '../../../../config/moyenPaiement';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ListePartsAdhesion',
  props: {
    hasActions: {
      type: Boolean,
      default: true,
    },
    adhesion: {
      type: Object,
      default() {
        return undefined;
      },
    },
  },
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
    isAdherent() {
      return this.adhesion != undefined;
    },
    partsLocales() {
      return this.adhesion ? this.adhesion.parts.local : [];
    },
    headers() {
      let headers = [
        { text: 'Conseil local', value: 'Conseil local', sortable: false },
        { text: 'Niveau', value: 'Niveau', sortable: false },
        { text: 'Moyen de paiement', value: 'Moyen de paiement', sortable: false },
        { text: 'Montant', value: 'Montant', sortable: true },
      ];
      if (this.hasActions) {
        headers.unshift({ text: 'Action', value: 'action', sortable: false });
      }

      return headers;
    },
  },

  methods: {
    ...mapActions('adhesion', [
       'sendParts',
    ]),

    getMoyenPaiementById,

    deletePart(conseilLocalId) {
      let parts = this.adhesion.parts;
      parts.local = parts.local.filter((part) => {
        return part.cl.id !== conseilLocalId;
      });

      this.sendParts({
        parentId: this.parent.id,
        parts,
        date: this.adhesion.date,
        isAdherent: this.isAdherent,
      });

    },

    modifier(conseilLocalId) {
      this.$router.push({
        name: 'edition-adhesion',
        params: {
          parentId: this.$route.params.parentId,
          conseilLocalId,
        },
      });
    },
  },
};
</script>
