import Vue from 'vue';
import Vuex from 'vuex';

// Layers of VueX: getters, stare, mutations, actions
import state from './state/index';
import getters from './getters/index';
import mutations from './mutations/index';
import actions from './actions/index';
import modules from './modules';
import createPersistedState from 'vuex-persistedstate';

Vue.use (Vuex);

/**
 * On Store for everywhere
 * Single Source of Truth
 * components -> state access -> action -> mutations -> modify a bit of state -> good components refreshed
 */
const vuex = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: [
        'conseilLocal.degreEnseignement',
        'conseilLocal.secteurGeographique',
        'conseilLocal.statutsJuridique',
        'conseilLocal.etablissements.list',
        'conseilLocal.filterConseilsLocaux',
        'cdpe.filterDepartements',
        'parametrages.anneeScolaire.filterAnneesScolaires',
        'recherche.appliedFilters',
        'recherche.selectedFilters',
        'recherche.filterConseilsLocaux',
        'recherche.filterDepartements',
        'responsabilites.responsabilitesLocal',
        'responsabilites.responsabilitesDepartemental',
      ],
    }),
  ],
});

// HACK call resetState first in order to set initialState
vuex.dispatch('resetState');

export default vuex;
