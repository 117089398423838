/**
 * This mixin aims to change the default behavior of the "select all"
 * checkbox on v-datatable vuetify compononent : it selects ALL items 
 * of the list not only the visible ones.
 * 
 * Import the mixin in the component which insert the <v-data-table> and 
 * use v-slot:header.data-table-select like this :
 *
    <v-data-table
      v-model="selected"
      :items="items"
      show-select
    >

    <template v-slot:header.data-table-select >
    <v-checkbox
        :input-value="selectAllValue"
        :indeterminate="selectAllIndeterminate"
        @click.stop="toggleAll"
    ></v-checkbox>                 
    </template>

    You will have to populate items properties with your entities
 */
export default {
  data: function() {
    return {
      selected: [],
    };
  },
  methods: {
    toggleAll() {
      this.selected =
        this.selected && this.selected.length > 0 ? [] : this.items.slice();
    },
  },
  computed: {
    items() {
      return [];
    },
    selectAllValue() {
      return (
        this.selected.length > 0 && this.selected.length === this.items.length
      );
    },
    selectAllIndeterminate() {
      return (
        this.selected.length > 0 && this.selected.length !== this.items.length
      );
    },
  },
};
