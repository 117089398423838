<template>
  <div 
    v-if="globalLoading"
    class="text-center"
  >
    <v-progress-circular 
      color="primary"
      indeterminate
    />
  </div>
  <div v-else>
    <v-card>
      <v-window
        v-model="anneeScolaireId"
        reverse
        class="elevation-1"
      >
        <v-window-item
          v-for="annee in listAnneeScolaireSaisie"
          :key="annee.id"
          :value="annee.id"
          class="pt-1"
          align="center"
          justify="center"
        >
          <v-btn
            :disabled="position <= 0"
            text
            @click="position--"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <strong class="title error--text">Année scolaire {{ annee.libelle }}</strong>
          <v-btn
            :disabled="position >= availableAnneesIds.length-1"
            text
            @click="position++"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer />
        </v-window-item>
      </v-window>

      <v-stepper
        v-model="step"
      >
        <v-stepper-header>
          <v-stepper-step
            v-for="step in steps"
            :key="step.value"
            class="subheading"
            :editable="step.editable"
            :complete="step.complete"
            :step="step.value"
            @click="saveParentIfModified(false, false)"
          >
            <template>
              {{ step.label }}
            </template>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <ParentForm
              :fake-send-parent="fakeSendParent"
              :parent="parent"
              @changeConseilLocal="changeConseilLocalParent"
              @leaveToRoute="leaveToRoute"
              @parentModified="parentModified"
              @goToNextStep="goToNextStep"
            />
          </v-stepper-content>

          <v-stepper-content step="2">
            <EnfantForm
              :parent="parent"
              :default-etablissements-scolaires="defaultEtablissementsScolaires"
              @reloadEnfants="reloadEnfants"
            />
            <ListeEnfants
              :enfants="enfants"
              @reloadEnfants="reloadEnfants"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <div>
              <v-row>
                <v-col
                  cols="12"
                  md="8"
                >
                  <AdditionalTabLayout 
                    :parent="parent" 
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pl-0"
                >
                  <AdditionalRecapitulatif
                    :recap-financier="recapFinancier"
                    :parent="parent"
                  />
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>

          <v-stepper-content
            v-if="showResponsabilitesStep"
            step="4"
          >
            <div>
              <v-row>
                <v-col cols="12">
                  <ResponsabiliteForm
                    :is-user-local="isUserLocal"
                    :is-user-national="isUserNational"
                    :parent="parent"
                  />
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>

    <v-footer
      color=""
      fixed
    >
      <v-row>
        <v-col>
          <v-btn
            class="my-2 mx-1"
            :disabled="!isPreviousStepAvailable"
            @click="goToPreviousStep"
          >
            Étape précédente
          </v-btn>
          <v-btn
            class="my-2 mx-1"
            :disabled="!isNextStepAvailable"
            :loading="parent_loading || parent_saving"
            @click="saveAndGoToNextStep"
          >
            Étape suivante
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn
            v-if="editMode"
            class="my-2 mx-1"
            color="primary"
            :loading="parent_loading || parent_saving"
            @click="saveAndLeave({ name: 'creation-parent'})"
          >
            Créer parent suivant
          </v-btn>
          <v-btn
            class="my-2 mx-1"
            color="primary"
            :disabled="saveAndCloseDisabled"
            :loading="parent_loading || parent_saving"
            @click="saveAndClose"
          >
            <v-icon>mdi-chevron-left</v-icon>{{ labelSaveAndCloseButton }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <v-bottom-sheet v-model="alertParentModified">
      <v-sheet
        class="text-center"
        height="100px"
      >
        <p>Vous avez des modifications non enregistrées.</p>
        <v-btn
          @click="leaveToRoute()"
        >
          Abandonner
        </v-btn>
        <v-btn
          class="ml-5"
          @click="alertParentModified=false"
        >
          Modifier
        </v-btn>
        <v-btn
          class="ml-5"
          color="primary"
          @click="saveAndClose"
        >
          Enregistrer
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<style lang="scss" scoped>
  @import './ParentContainer.scss';
</style>

<script>
import ParentForm from '../components/parent/Form';
import EnfantForm from '../components/parent/enfant/Form';
import ListeEnfants from '../components/parent/enfant/Liste';
import AdditionalTabLayout from '../components/parent/additional/Layout';
import ResponsabiliteForm from '../components/responsabilite/Form';
import AdditionalRecapitulatif from '../components/parent/additional/Recapitulatif';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'ParentContainer',
  components: {
    ParentForm,
    EnfantForm,
    ListeEnfants,
    ResponsabiliteForm,
    AdditionalTabLayout,
    AdditionalRecapitulatif,
  },
  data: () => ({
    position: 0,
    availableAnneesIds: [],
    step: 1,
    fakeSendParent: { send:false, leaveToRoute: false, nextStep: false },
    isParentModified: false,
    alertParentModified: false,
    leaveRoute: { name: 'recherche-parents' },
    globalLoading: true,
  }),
  async beforeRouteLeave(to, from, next) {
    if (this.isParentModified) {
      this.alertParentModified = true;
      this.leaveRoute = to;
      return next(false);
    }
    this.resetState();
    return next();
  },
  computed: {
    ...mapGetters({
      parent: 'getParent',
      parent_loading: 'getParentLoading',
      parent_saving: 'getParentSaving',
      enfants: 'getEnfants',
      getDefaultEtablissementsScolaires: 'getDefaultEtablissementsScolaires',
    }),
    ...mapGetters('session', {
        isUserLocal: 'isUserLocal',
        isUserNational: 'isUserNational',
        getUserCdpeId: 'getUserCdpeId',
        canAffecteResponsabiliteLocale: 'canAffecteResponsabiliteLocale',
        canAffecteResponsabiliteDepartementale: 'canAffecteResponsabiliteDepartementale',
        canAffecteResponsabiliteNationale: 'canAffecteResponsabiliteNationale',
    }),
    ...mapGetters('parametrages/anneeScolaire', {
        listAnneeScolaireSaisie: 'listAnneeScolaireSaisie',
        getAnneeScolaireCourante: 'getAnneeScolaireCourante',
    }),
    ...mapGetters('recapFinancier', {
        recapFinancier: 'getRecapFinancier',
        recapFinancierAnneeScolaireId: 'getAnneeScolaireId',
    }),

    ...mapState('_parent', ['formValid']),

    anneeScolaireId() {
      return this.availableAnneesIds[this.position];
    },

    showResponsabilitesStep() {
      return this.canAffecteResponsabiliteNationale || this.canAffecteResponsabiliteDepartementale || this.canAffecteResponsabiliteLocale;
    },

    steps() {
      let steps = [
        {
          label: this.editMode ? 'Editer le parent' :'Créer le parent',
          editable: true,
          complete: true,
          value: 1,
        },
        {
          label: this.editMode ? 'Editer les enfants' :'Créer les enfants',
          editable: this.editMode,
          complete: this.editMode && this.enfants.length > 0,
          value: 2,
        },
        {
          label: 'Adhésion Abonnement Dons',
          editable: this.editMode,
          complete: this.editMode && this.hasAdhesionOrAbonnementOrDon,
          value: 3,
        },
      ];
      if (this.showResponsabilitesStep) {
        steps.push({
          label: 'Responsabilités',
          editable: this.editMode,
          complete: this.editMode && this.hasResponsabilites,
          value: 4,
        });
      }
      return steps;
    },

    editMode() {
      return this.$route.params.hasOwnProperty('parentId');
    },
    hasAdhesionOrAbonnementOrDon() {
      return (
        this.recapFinancier && (
        this.recapFinancier.adhesion.national !== 0 ||
        this.recapFinancier.don.departemental !== 0 ||
        this.recapFinancier.don.local !== 0 ||
        this.recapFinancier.abonnement.length > 0)
      );
    },
    hasResponsabilites() {
      let responsablites = this.$store.getters['responsabilites/getResponsabilitesParent'];
      return (
        responsablites.local.length > 0 ||
        responsablites.parAppartenance.length > 0 ||
        responsablites.departemental.length > 0
      );
    },
    isNextStepAvailable() {
      let nextStep = this.steps.find(step => {
        return step.value === this.step + 1;
      });
      return !!nextStep && nextStep.editable;
    },
    isPreviousStepAvailable() {
      let prevStep = this.steps.find(step => {
        return step.value === this.step - 1;
      });
      return !!prevStep && prevStep.editable;
    },
    labelSaveAndCloseButton() {
      if (!this.editMode) {
        return 'Créer le parent';
      }
      return this.step === 1 ? 'Enregistrer et fermer' : 'Fermer';
    },
    saveAndCloseDisabled() {
      return this.step === 1 && !this.formValid;
    },
    defaultEtablissementsScolaires() {
      // on ajoute le champ `perimetre_id` aux etablissements car il est utilisé dans enfant/EditForm#changeWillAffectParentCL
      let etablissements = this.getDefaultEtablissementsScolaires;
      etablissements.forEach(el => {
        el.perimetre_id = this.parent.conseil_local.id;
      });
      return etablissements;
    },
  },
  watch: {
    enfants(val) {
      if (!val.length) {
        return;
      }
      this.loadCLs();
    },
    '$route.params.parentId': function(parentId) {
      if (parentId) {
        this.loadParent(parentId);
      } else {
        this.step = 1;
      }
    },
    position() {
      this.setStatesAnneeScolaireId(this.anneeScolaireId);
    },
  },
  created() {
    let parentId = this.$route.params.parentId;
    Promise.all([
      this.loadFilterAnneesScolaires(),
      this.loadParent(parentId),
    ]).then(() => {
      this.availableAnneesIds = this.listAnneeScolaireSaisie.map(el => el.id).sort((a, b) => a - b);

      // si une anneeScolaire avait été selectionnée précédement, on la reselectionne (retour de modification par exemple)
      if(this.recapFinancierAnneeScolaireId) {
        let p = this.availableAnneesIds.findIndex(aId => aId === this.recapFinancierAnneeScolaireId);
        if(p > 0) this.position = p;
      }

      this.setStatesAnneeScolaireId(this.anneeScolaireId);
      if (this.$route.params.stepId) {
        this.step = parseInt(this.$route.params.stepId);
      } else {
        this.step = 1;
      }
      this.globalLoading = false;
    });
  },
  methods: {
    leave() {},
    reloadEnfants() {
      this.loadEnfantsByParentId({ parentId: this.$route.params.parentId });
    },
    ...mapActions('session', {
        setSuccess: 'setSuccess',
    }),
    ...mapActions('parametrages/anneeScolaire', {
        loadFilterAnneesScolaires: 'loadFilterAnneesScolaires',
    }),
    ...mapActions([
      'changeConseilLocalParent',
      'loadEnfantsByParentId',
      'loadParentInfosById',
      'loadAllFinancialDatasByParentId',
      'getDefaultEtablissementScolaireByConseilLocalId',
      'resetState',
    ]),
    ...mapActions('adhesion', [
      'loadNiveauxPartDepart',
      'loadCLs',
    ]),
    ...mapActions('abonnement', {
      loadTypeAbonnements: 'loadTypeAbonnements',
    }),
    ...mapActions('responsabilites', {
      loadResponsabilitesLocal: 'loadResponsabilitesLocal',
      loadResponsabilitesLocalParAppartenance: 'loadResponsabilitesLocalParAppartenance',
      loadResponsabilitesDepartemental: 'loadResponsabilitesDepartemental',
      loadResponsabilitesNational: 'loadResponsabilitesNational',
    }),
    ...mapMutations('recapFinancier', {
      setRecapFinancierAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),
    ...mapMutations('adhesion', {
      setAdhesionAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),
    ...mapMutations('abonnement', {
      setAbonnementAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),
    ...mapMutations('don', {
      setDonAnneeScolaireId: 'SET_ANNEE_SCOLAIRE_ID',
    }),

    setStatesAnneeScolaireId(anneeId) {
      this.setRecapFinancierAnneeScolaireId(anneeId);
      this.setAdhesionAnneeScolaireId(anneeId);
      this.setAbonnementAnneeScolaireId(anneeId);
      this.setDonAnneeScolaireId(anneeId);
    },

    saveAndGoToNextStep() {
      if (this.isParentModified && this.step === 1) {
        this.saveParentIfModified(false, true);
      } else {
        this.goToNextStep();
      }
    },
    goToNextStep() {
      if (this.isNextStepAvailable) {
        this.step++;
      }
    },
    saveParentIfModified(nextRoute, nextStep) {
      if (this.isParentModified && this.step === 1) {
        this.fakeSendParent = { send:true, leaveToRoute: nextRoute, nextStep: nextStep };
      }
    },
    parentModified(value) {
      this.isParentModified = (value !== false);
      this.setSuccess({ info: 'Le parent a bien été modifié' });
    },
    goToPreviousStep() {
      if(this.isPreviousStepAvailable) {
        this.step--;
      }
    },
    loadParent(parentId) {
      if (parentId) {
        this.loadTypeAbonnements();
        this.loadParentInfosById({ parentId }).then(parent => {
          this.getDefaultEtablissementScolaireByConseilLocalId({
            conseilLocalId: parent.conseil_local.id,
            cdpeId: parent.conseil_local.cdpe_id,
          });
          this.loadEnfantsByParentId({ parentId })
            .then(() => {
              this.loadResponsabilitesLocalParAppartenance();
            });
          this.loadAllFinancialDatasByParentId({ parentId });
          this.loadNiveauxPartDepart(parent.conseil_local.id);
          this.loadResponsabilitesLocal(parent.conseil_local.id);
          if(!this.isUserLocal) { // cdpe or nat
            this.loadResponsabilitesDepartemental(parent.conseil_local.cdpe_id);
          }
          if(this.isUserNational) {
            this.loadResponsabilitesNational();
          }
        });
      }
    },
    saveAndClose() {
      this.saveAndLeave(this.leaveRoute);
    },
    saveAndLeave(toRoute) {
      if (this.step === 1) {
        this.fakeSendParent = { send:true, leaveToRoute: toRoute, nextStep: false };
      } else {
        this.leaveToRoute(toRoute);
      }
    },
    leaveToRoute(toRoute) {
      if (!toRoute) {
        toRoute = this.leaveRoute;
      }
      this.alertParentModified = false;
      this.isParentModified = false;
      if (toRoute.replace) {
        this.$router.replace(toRoute);
      } else {
        this.$router.push(toRoute);
      }
    },
  },
};
</script>
