import types from '../actions/types';

const workflowMutateur = {
  [types.WORKFLOW_TOGGLE_LOADING](state, toggle) {
    state.workflow.loading = toggle;
  },
  [types.WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_OR_CL_ID](
    state,
    cdpeOrClId,
  ) {
    state.workflow.validation_saisies_adhesion_local.cdpeOrClId = cdpeOrClId;
  },
  [types.WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_ID](state, cdpeId) {
    state.workflow.validation_saisies_adhesion_local.cdpeId = cdpeId;
  },
  [types.WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_LOAD](state, data) {
    state.workflow.validation_saisies_adhesion_local.results = data;
  },
  [types.WORKFLOW_RECEPTION_ADHESION_DEPARTEMENT_LOAD](state, data) {
    state.workflow.reception_adhesion_departement.results = data;
  },
  [types.WORKFLOW_TRANSFERT_ADHESION_DEPARTEMENT_LOAD](state, data) {
    state.workflow.transfert_adhesions_departemental.results = data;
  },
  [types.WORKFLOW_VALIDATION_ADHESIONS_EN_LIGNE_LOAD](state, data) {
    state.workflow.validation_adhesions_en_ligne.results = data;
  },
};

export default workflowMutateur;
