import Vue from 'vue';
import Router from 'vue-router';
import { formatDate } from './config/helpers';
import ParentContainer from './containers/ParentContainer';
import EnfantContainer from './containers/EnfantContainer';
import SearchParentContainer from './containers/SearchParentContainer';
import EditionPartLocalContainer from './containers/EditionPartLocalContainer';
import EditionDonContainer from './containers/EditionDonContainer';
import LegacyContainer from './containers/LegacyContainer';

import auth from './auth';
import droits from './auth/droits.js';
import typePerimetre from './auth/typePerimetre.js';
import store from './store';
import _includes from 'lodash/includes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: SearchParentContainer,
      meta: {
        droits: [droits.FOYER_RECHERCHE],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/creation-parent',
      name: 'creation-parent',
      component: ParentContainer,
      meta: {
        droits: [droits.FOYER_CREATION],
      },
    },
    {
      path: '/information-parent/:parentId',
      name: 'information-parent',
      component: () => import('./containers/parent/Information'),
      meta: {
        droits: [droits.FOYER_RECHERCHE],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/tableau-bord-parent/:parentId',
      name: 'tableau-bord-parent',
      component: () => import('./containers/parent/Logs'),
      meta: {
        droits: [droits.TABLEAUX_BORD],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/edition-parent/:parentId',
      name: 'edition-parent',
      component: ParentContainer,
      meta: {
        droits: [droits.FOYER_MODIFICATION],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/edition-parent/:parentId/step/:stepId',
      name: 'edition-parent-stepId',
      component: ParentContainer,
      meta: {
        droits: [droits.FOYER_MODIFICATION],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/edition-enfant/:parentId/:enfantId',
      name: 'edition-enfant',
      component: EnfantContainer,
      meta: {
        droits: [droits.FOYER_MODIFICATION],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/edition-adhesion/:parentId/cl/:conseilLocalId',
      name: 'edition-adhesion',
      component: EditionPartLocalContainer,
      meta: {
        droits: [droits.FOYER_MODIFICATION],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/edition-don/:parentId/:donId',
      name: 'edition-don',
      component: EditionDonContainer,
      meta: {
        droits: [droits.FOYER_MODIFICATION],
        menu_active_route: 'recherche-parents',
      },
    },
    {
      path: '/creation-etablissement',
      name: 'creation-etablissement',
      component: () => import('./containers/etablissementScolaire/CreateEtablissementScolaireContainer'),
      meta: {
        droits: [droits.ETABLISSEMENT_CREATION],
        menu_active_route: 'recherche-etablissement',
      },
    },
    {
      path: '/edition-etablissement/:etablissementId',
      name: 'edition-etablissement',
      component: () => import('./containers/etablissementScolaire/EditEtablissementScolaireContainer'),
      meta: {
        droits: [droits.ETABLISSEMENT_MODIFICATION],
        menu_active_route: 'recherche-etablissement',
      },
    },
    {
      path: '/view-etablissement/:etablissementId',
      name: 'view-etablissement',
      component: () => import('./containers/etablissementScolaire/ViewEtablissementScolaireContainer'),
      meta: {
        droits: [droits.ETABLISSEMENT_RECHERCHE],
        menu_active_route: 'recherche-etablissement',
      },
    },
    {
      path: '/recherche-parents',
      name: 'recherche-parents',
      component: SearchParentContainer,
      meta: {
        droits: [droits.FOYER_RECHERCHE],
      },
    },
    {
      path: '/validation-saisies-adhesion',
      name: 'validation-saisies-adhesion',
      component: () => import('./containers/workflow/ValidationSaisiesAdhesionContainer'),
      meta: {
        droits: [droits.FOYER_VALIDATION_CREATION_LOCALE],
      },
    },
    {
      path: '/validation-adhesions-national',
      name: 'validation-adhesions-national',
      component: () => import('./containers/workflow/ValidationsNationalesContainer'),
      meta: {
        droits: [droits.FOYER_VALIDATION_RECEPTION_CDPE],
      },
    },
    {
      path: '/reception-adhesion-departement',
      name: 'reception-adhesion-departement',
      component: () => import('./containers/workflow/ReceptionAdhesionDepartementContainer'),
      meta: {
        droits: [droits.FOYER_VALIDATION_RECEPTION_CONSEIL_LOCAL],
      },
    },
    {
      path: '/transfert-adhesions-departemental',
      name: 'transfert-adhesions-departemental',
      component: () => import('./containers/workflow/TransfertAdhesionsDepartementalContainer'),
      meta: {
        droits: [droits.FOYER_VALIDATION_SAISIE_CDPE],
      },
    },
    {
      path: '/recherche-cdpe',
      name: 'recherche-cdpe',
      component: () => import('./containers/cdpe/SearchContainer'),
      meta: {
        droits: [droits.PERIMETRE_RECHERCHE],
        typesPerimetres: [typePerimetre.NATIONAL],
      },
    },
    {
      path: '/editer-mon-cdpe',
      name: 'editer-mon-cdpe',
      component: () => import('./containers/cdpe/FormContainer'),
      meta: {
        droits: [droits.PERIMETRE_MODIFICATION],
        typesPerimetres: [typePerimetre.DEPARTEMENTAL],
      },
    },
    {
      path: '/editer-cdpe/:cdpeId',
      name: 'editer-cdpe',
      component: () => import('./containers/cdpe/FormContainer'),
      meta: {
        droits: [droits.PERIMETRE_MODIFICATION],
        typesPerimetres: [typePerimetre.NATIONAL],
      },
    },
    {
      path: '/recherche-conseil-local',
      name: 'recherche-conseil-local',
      component: () => import('./containers/conseilLocal/SearchContainer'),
      meta: {
        droits: [droits.PERIMETRE_RECHERCHE],
        typesPerimetres: [typePerimetre.NATIONAL, typePerimetre.DEPARTEMENTAL],
      },
    },
    {
      path: '/creation-conseil-local',
      name: 'creation-conseil-local',
      component: () => import('./containers/conseilLocal/FormContainer'),
      meta: {
        droits: [droits.PERIMETRE_CREATION],
        typesPerimetres: [typePerimetre.NATIONAL, typePerimetre.DEPARTEMENTAL],
      },
    },
    {
      path: '/edition-conseil-local/:cdpeId/:conseilLocalId',
      name: 'edition-conseil-local',
      component: () => import('./containers/conseilLocal/FormContainer'),
      meta: {
        droits: [droits.PERIMETRE_MODIFICATION],
        menu_active_route: 'creation-conseil-local',
      },
    },
    {
      path: '/editer-mon-conseil-local',
      name: 'editer-mon-conseil-local',
      component: () => import('./containers/conseilLocal/FormContainer'),
      meta: {
        droits: [droits.PERIMETRE_MODIFICATION],
        typesPerimetres: [typePerimetre.LOCAL],
      },
    },
    {
      path: '/voir-mon-conseil-local',
      name: 'voir-mon-conseil-local',
      component: () => import('./containers/conseilLocal/FormContainer'),
      meta: {
        droits: [droits.PERIMETRE_RECHERCHE],
        typesPerimetres: [typePerimetre.LOCAL],
      },
    },
    {
      path: '/validation-dons',
      name: 'validation-dons',
      component: () => import('./containers/workflow/ValidationDonsContainer'),
      meta: {
        droits: [droits.FOYER_VALIDATION_DONS],
      },
    },
    {
      path: '/validation-import-bourse-livres',
      name: 'validation-import-bourse-livres',
      component: () => import('./containers/workflow/ValidationImportBourseAuxLivresContainer'),
      meta: {
        droits: [droits.FOYER_VALIDATION_IMPORT_BL],
      },
    },
    {
      path: '/reception-adhesion-en-ligne',
      name: 'reception-adhesion-en-ligne',
      component: () =>
        import('./containers/workflow/ReceptionAdhesionsEnLigneContainer'),
      meta: {
        droits: [droits.FOYER_VALIDATION_ADHESIONS_EN_LIGNE],
      },
    },
    {
      path: '/recherche-etablissement',
      name: 'recherche-etablissement',
      component: () => import('./containers/etablissementScolaire/SearchEtablissementScolaireContainer'),
      meta: {
        droits: [droits.ETABLISSEMENT_RECHERCHE],
      },
    },
    {
      path: '/impressions-predefinies',
      name: 'impressions-predefinies',
      component: () => import('./containers/impressions/ImpressionsContainer'),
    },
    {
      path: '/export-routage',
      name: 'export-routage',
      component: () => import('./containers/importsExports/ExportRoutageContainer'),
      meta: {
        droits: [droits.EXPORT_ROUTAGE],
      },
    },
    {
      path: '/export-bourse-aux-livres',
      name: 'export-bourse-aux-livres',
      component: () => import('./containers/importsExports/ExportBourseAuxLivresContainer'),
      meta: {
        droits: [droits.EXPORT_BOURSE],
      },
    },
    {
      path: '/import-bourse-aux-livres',
      name: 'import-bourse-aux-livres',
      component: () => import('./containers/importsExports/ImportBourseAuxLivresContainer'),
      meta: {
        droits: [droits.IMPORT_BOURSE],
      },
    },
    {
      path: '/exports-comptables',
      name: 'exports-comptables',
      component: () =>
        import('./containers/importsExports/ExportsComptablesContainer'),
      meta: {
        droits: [droits.EXPORT_COMPTABLES],
      },
    },
    {
      path: '/annee-scolaire',
      name: 'annee-scolaire',
      component: () => import('./containers/parametrage/AnneeScolaireContainer'),
      meta: {
        droits: [droits.PARAMETRAGE_ANNEE_SCOLAIRE],
      },
    },
    {
      path: '/communes',
      name: 'communes',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_COMMUNE],
      },
    },
    {
      path: '/secteur-geographique',
      name: 'secteur-geographique',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_SECTEUR_GEOGRAPHIQUE],
      },
    },
    {
      path: '/degres-enseignement',
      name: 'degres-enseignement',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_DEGRE_ENSEIGNEMENT],
      },
    },
    {
      path: '/niveau-enseignement',
      name: 'niveau-enseignement',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_NIVEAU_ENSEIGNEMENT],
      },
    },
    {
      path: '/classes',
      name: 'classes',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_CLASSE],
      },
    },
    {
      path: '/niveaux-adhesion-abonnement',
      name: 'niveaux-adhesion-abonnement',
      component: () =>
        import('./containers/parametrage/niveauAdhesionEtAbonnement/ListContainer'),
      meta: {
        droits: [droits.PARAMETRAGE_NIVEAU_ADHESION], // droits.PARAMETRAGE_REVUE_TYPE_ABONNEMENT
      },
    },
    {
      path: '/codes-comptables',
      name: 'codes-comptables',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_CODE_COMPTABLE],
      },
    },
    {
      path: '/responsabilites-departementales',
      name: 'responsabilites-departementales',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_RESPONSABILITE_DEPARTEMENTALE],
      },
    },
    {
      path: '/delegations-departementales',
      name: 'delegations-departementales',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_DELEGATION_DEPARTEMENTALE],
      },
    },
    {
      path: '/responsabilites-locales',
      name: 'responsabilites-locales',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_RESPONSABILITE_LOCALE],
      },
    },
    {
      path: '/delegations-etablissement',
      name: 'delegations-etablissement',
      component: LegacyContainer,
      meta: {
        droits: [droits.PARAMETRAGE_DELEGATION_ETABLISSEMENT],
      },
    },
    {
      path: '/utilisateurs',
      name: 'utilisateurs',
      component: () => import('./containers/utilisateurs/ListContainer'),
      meta: {
        droits: [droits.PARAMETRAGE_UTILISATEUR],
      },
    },
    {
      path: '/utilisateurs/edit/:userId',
      name: 'edition-utilisateur',
      component: () => import('./containers/utilisateurs/EditContainer'),
      meta: {
        droits: [droits.PARAMETRAGE_UTILISATEUR],
        menu_active_route: 'utilisateurs',
      },
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('./containers/roles/ListContainer'),
      meta: {
        droits: [droits.PARAMETRAGE_ROLE],
      },
    },
    {
      path: '/roles/edit/:roleName',
      name: 'edition-role',
      component: () => import('./containers/roles/EditContainer'),
      meta: {
        droits: [droits.PARAMETRAGE_ROLE],
        menu_active_route: 'roles',
      },
    },
    {
      path: '/statistiques',
      name: 'statistiques',
      component: LegacyContainer,
      meta: {
        droits: [droits.TABLEAUX_BORD],
      },
    },
    {
      path: '/blocage',
      name: 'blocage',
      component: () => import('./containers/cdpe/BlocageContainer'),
    },
    {
      path: '/blocage-utilisateur',
      name: 'blocage-utilisateur',
      component: () => import('./containers/utilisateurs/BlocageContainer'),
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('./containers/ForbiddenContainer'),
    },
    {
      path: '/debug',
      name: 'debug',
      component: () => import('./containers/DebugContainer'),
    },
  ],
});

function isRouteAuthorized(route) {
  // Exclure les routes non autorisé par le type de perimetre de l'utilisateur connecté
  if (typeof route.meta.typesPerimetres !== 'undefined') {
    if (
      !_includes(
        route.meta.typesPerimetres,
        store.getters['session/getPerimetreUser'].type_perimetre_id
      )
    ) {
      return false;
    }
  }

  // Exclure les routes non autorisées par les droits de l'utilisateur connecté
  if (typeof route.meta.droits !== 'undefined') {
    if (!route.meta.droits.some(droit => store.getters['session/getDroits'].includes(droit))) {
      return false;
    }
  }

  return true;
}

/**
 * Router each auth
 */
router.beforeEach(async (to, from, next) => {

  console.log('before each');

  await auth.checkAuth(store);

  if (!isRouteAuthorized(to)) {
    return next({ name: 'forbidden' });
  }

  await store.dispatch('session/checkPerimetreUserIsBlocked');
  let perimetreUser = store.getters['session/getPerimetreUser'];
  let user = store.getters['session/getUser'];
  
  if (user.bloque) {
    if (to.name !== 'blocage-utilisateur') {
      return next({ name: 'blocage-utilisateur' });
    }
  }

  if (store.getters['session/isUserDepartemental']) {
    if (perimetreUser.bloque_dans_x_jours < 30) {
      if (perimetreUser.bloque_dans_x_jours < 0) {
        if (to.name !== 'blocage') {
          return next({ name: 'blocage' });
        }
      } else {
        store.dispatch('session/setErrors', {
          info: `Veuillez renseigner la date de Prochain Congrès dans votre fiche CDPE avant le ${formatDate(perimetreUser.date_blocage)}, au-delà de cette date l'application sera bloquée jusqu'à ce que vous renseigniez votre date de prochain congrès.`,
          message: '',
        });
      }
    }
  }

  if (store.getters['session/isUserLocal']) {
    if (perimetreUser.bloque_dans_x_jours < 0) {
      if (to.name !== 'blocage') {
        return next({ name: 'blocage' });
      }
    }
  }
  return next();
});

export default router;
export { isRouteAuthorized };
