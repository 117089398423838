<template>
  <div class="mb-5">
    <v-data-table
      v-model="selected"
      light
      dense
      :headers="filteredHeaders"
      :items="parents"
      item-key="id"
      show-select
      class="elevation-3"
      :loading="loading"
      loading-text="Chargement en cours"
      no-data-text="Aucun parent trouvé"
      :sort-by="['conseil_local.nom', 'personne.nom']"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageText: 'Parents par page:',
        itemsPerPageOptions: [20, 50, 100, 200]
      }"
    >
      <v-progress-linear
        v-if="loading"
        v-slot:progress
        color="primary"
      />

      <template v-slot:header.data-table-select>
        <v-checkbox
          :input-value="selectAllValue"
          :indeterminate="selectAllIndeterminate"
          @click.stop="toggleAll"
        />
      </template>

      <template v-slot:item="props">
        <tr
          :class="`${colori(props.item)}--text`"
          :active="props.isSelected"
          @click.stop="props.select(!props.isSelected)"
        >
          <td>
            <v-checkbox
              :input-value="props.isSelected"
              @click.stop="props.select(!props.isSelected)"
            />
          </td>
          <td>
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click.stop
                  >
                    more_vert
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    :to="{ name: 'information-parent', params: { parentId: props.item.id }}"
                  >
                    <v-list-item-title
                      :key="props.item.id"
                    >
                      Voir
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="showHistory(props.item)">
                    <v-list-item-title>
                      Voir historique
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canEdit(props.item)"
                    :to="{ name: 'edition-parent', params: { parentId: props.item.id }}"
                  >
                    <v-list-item-title
                      :key="props.item.id"
                    >
                      Modifier
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="canDelete(props.item)"
                    @click="remove(props.item)"
                  >
                    <v-list-item-title>
                      Supprimer
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canViewTableauBordParent"
                    :to="{ name: 'tableau-bord-parent', params: { parentId: props.item.id }}"
                  >
                    <v-list-item-title
                      :key="props.item.id"
                    >
                      Tableau de bord
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
          <td class="text-center">
            <i>{{ props.item.statut.libelle }}</i>
          </td>
          <td class="text-center">
            <i>{{ props.item.code }}</i>
          </td>
          <td
            v-if="!hideConseilLocalColumn"
            class="text-center"
          >
            {{ props.item.conseil_local.nom.toLowerCase() }}
          </td>
          <td class="text-center">
            {{ props.item.personne.nom }}
          </td>
          <td class="text-center">
            {{ props.item.personne.prenom }}
          </td>
          <td
            v-if="!hideResponsabiliteColumn"
            class="text-center"
          >
            {{ props.item.responsabilite }}
          </td>
          <td class="text-center">
            <v-btn
              v-if="props.item.contact.email"
              :href="`mailto:${props.item.contact.email}`"
              style="text-transform: none !important;"
              text
              small
              depressed
              :color="colori(props.item)"
              class="white--text"
            >
              <v-icon
                small
                left
              >
                email
              </v-icon>
              {{ props.item.contact.email }}
            </v-btn>
          </td>
          <td class="text-center white-text">
            <v-btn
              v-if="props.item.contact.telephone_fixe"
              :href="`tel:${removeDots(props.item.contact.telephone_fixe)}`"
              text
              small
              depressed
              :color="colori(props.item)"
              class="white--text"
            >
              <v-icon
                small
                left
              >
                phone
              </v-icon>
              {{ props.item.contact.telephone_fixe }}
            </v-btn>
            <v-btn
              v-if="props.item.contact.telephone_mobile"
              :href="`tel:${removeDots(props.item.contact.telephone_mobile)}`"
              text
              small
              depressed
              :color="colori(props.item)"
              class="white--text"
            >
              <v-icon
                small
                left
              >
                smartphone
              </v-icon>
              {{ props.item.contact.telephone_mobile }}
            </v-btn>
          </td>
          <td class="text-center">
            {{ props.item.abonne }}
          </td>
          <td class="text-center">
            {{ props.item.adherent }}
          </td>
          <td class="text-center">
            {{ props.item.nombre_enfants }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
    />
    <Historique
      :parent="historyParent"
      :should-show="showHistoricSheet"
      @close="showHistoricSheet = !showHistoricSheet"
    />
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import { colorParent } from '../../../config/statutParent';
import { removeDots } from '../../../config/helpers';
import Historique from '../Historique';
import selectAllMixin from '../../../mixins/vTableDataSelectAll';
import droitsMixin from '../droits.mixin';

export default {
  name: 'ListeParents',
  components: {
    Historique,
  },
  mixins: [
    selectAllMixin,
    droitsMixin,
  ],
  props: {
    loading: Boolean,
    parents: {
      type: Array,
      default: () => {},
    },
  },
  data: () => ({
    filterOpen: false,
    headers: [
      {
        text: 'Action',
        sortable: false,
        value: 'action',
      },
      {
        text: 'Statut',
        sortable: true,
        value: 'statut.libelle',
      },
      {
        text: 'Code',
        sortable: true,
        value: 'code',
      },
      {
        text: 'CL Principal',
        sortable: true,
        value: 'conseil_local.nom',
        hideConseilLocalColumn: true,
      },
      {
        text: 'Nom',
        sortable: true,
        value: 'personne.nom',
      },
      {
        text: 'Prénom',
        sortable: true,
        value: 'personne.prenom',
      },
      {
        text: 'Responsabilité',
        sortable: true,
        value: 'responsabilite',
        hideResponsabiliteColumn: true,
      },
      {
        text: 'Email',
        sortable: true,
        value: 'contact.email',
      },
      {
        text: 'Téléphone',
        sortable: true,
        value: 'contact',
        sort(a, b) {
          let ta = a.telephone_fixe !== '' ? a.telephone_fixe : a.telephone_mobile;
          let tb = b.telephone_fixe !== '' ? b.telephone_fixe : b.telephone_mobile;
          if(ta === tb) return 0;
          if(ta < tb) return -1;
          return 1;
        },
      },
      {
        text: 'Abonné',
        sortable: true,
        value: 'abonne',
      },
      {
        text: 'Adhérent',
        sortable: true,
        value: 'adherent',
      },
      {
        text: 'Nb Enfants',
        sortable: true,
        value: 'nombre_enfants',
      },
    ],
    search: '',
    showHistoricSheet: false,
    historyParent: {},
  }),
  computed: {
    ...mapGetters('session', {
      getPerimetreUser: 'getPerimetreUser',
      isUserLocal: 'isUserLocal',
    }),

      ...mapState('recherche', {
          appliedFilters: state => state.appliedFilters,
      }),
    hideConseilLocalColumn() {
      return (this.appliedFilters.filterConseilLocalId || this.isUserLocal) && !this.appliedFilters.filterAddParentParAppartenance;
    },
    hideResponsabiliteColumn() {
      return this.appliedFilters.filterResponsabiliteId != -1;
    },
    filteredHeaders() {
      let self = this;
      return this.headers.filter(h => {
          if (h.hideConseilLocalColumn) {
            return !self.hideConseilLocalColumn;
          }
          if (h.hideResponsabiliteColumn) {
            return !self.hideResponsabiliteColumn;
          }
          return true;
        });
    },
    items() {
      return this.parents;
    },
  },
  watch: {
    selected(val) {
      this.$emit('changeSelected', val);
    },
    parents() {
      this.selected = [];
    },
  },
  methods: {
    removeDots,
    remove(parentDeleted) {
      this.$emit('delete', parentDeleted);
    },
    showHistory(parent) {
      this.historyParent = parent;
      this.showHistoricSheet = true;
    },
    filter() {
      this.filterOpen = !this.filterOpen;
    },
    colori(parent) {
      let txtColor = colorParent(parent);
      if (this.appliedFilters.filterConseilLocalId && parent.conseil_local.id !== this.appliedFilters.filterConseilLocalId) {
        txtColor = 'blue-grey';
      }
      return txtColor;
    },
  },
};
</script>
