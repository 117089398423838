import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    cdpe: {
      adresse_legale: {
        commune: {
          code_postal: '',
          id: 0,
          ville: '',
        },
        id: 0,
        ligne1: '',
        ligne2: '',
      },
      code: '',
      contact: {
        email: '',
        id: 0,
        telephone_fixe: '',
        telephone_mobile: '',
      },
      date_prochain_congres: '',
      horaires: {
        commentaire: '',
        lundi: {
          matin: '',
          soir: '',
        },
        mardi: {
          matin: '',
          soir: '',
        },
        mercredi: {
          matin: '',
          soir: '',
        },
        jeudi: {
          matin: '',
          soir: '',
        },
        vendredi: {
          matin: '',
          soir: '',
        },
        samedi: {
          matin: '',
          soir: '',
        },
      },
      id: 0,
      nom: '',
      numero_urgence: '',
      siren: '',
      secretaire: '',
      site_web: '',
    },
    saving:false,
  },

  mutations: {
    setCurrentCdpe(state, cdpe) {
      state.cdpe = cdpe;
    },

    setDateProchainCongres(state, date) {
      state.cdpe.date_prochain_congres = date;
    },

    setPrenomSecretaire(state, prenom) {
      let [oldPrenom, oldNom] = state.cdpe.secretaire.split(' '); // eslint-disable-line no-unused-vars

      state.cdpe.secretaire = `${prenom} ${oldNom}`;
    },

    setNomSecretaire(state, nom) {
      let [oldPrenom, oldNom] = state.cdpe.secretaire.split(' '); // eslint-disable-line no-unused-vars

      state.cdpe.secretaire = `${oldPrenom} ${nom}`;
    },

    setCommune(state, commune) {
      state.cdpe.adresse_legale.commune = commune;
    },

    setHoraire(state, horaire) {
      let day = horaire.label;
      delete horaire.label;
      state.cdpe.horaires[day] = horaire;
    },

    setSaving(state, saving) {
      state.saving = saving;
    },

  },

  actions: {
    /**
     * Unused right now
     *
     * @param store
     * @param cdpe
     * @returns {*}
     */
    create({ commit } , { cdpe }) {
      commit('setSaving', true);
      return xhr.post('api/admin/cdpe', cdpe)
        .then(response => response.data)
        .finally(() => commit('setSaving', false));
    },

    save({ commit }, { cdpe, cdpeId }) {
      commit('setSaving', true);
      return xhr.put(`api/admin/cdpe/${cdpeId}`, cdpe)
        .finally(() => commit('setSaving', false));
    },

    uploadSignature(store, formData) {
      return xhr.post('/importSignature', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  },

  getters: {
    getPrenomSecretaire(state) {
      let [prenom, nom] = state.cdpe.secretaire.split(' '); // eslint-disable-line no-unused-vars

      return prenom;
    },

    getNomSecretaire(state) {
      let [prenom, nom] = state.cdpe.secretaire.split(' '); // eslint-disable-line no-unused-vars
      return nom;
    },

    /**
     * Format opening hours to retrieve only days and add exemple
     * @param state
     * @returns {Array}
     */
    getHoraires(state) {
      let horaires = [];
      horaires.push({
        label: 'exemple',
        matin: '8h00-12h00',
        soir: '13h00-18h00',
        immutable: true,
      });

      Object.keys(state.cdpe.horaires).forEach(key => {
        let elem = state.cdpe.horaires[key];
        if ('object' === typeof elem) {
          horaires.push({
            label: key,
            ...elem,
          });
        }
      });

      return horaires;
    },
  },
};
