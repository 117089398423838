import xhr from '../plugins/axios.js';

export default {
    namespaced: true,
    state: {
        loading: false,
        items: [],
    },
    mutations: {
        SET_ITEMS(state, _items) {
            state.items = _items;
        },
        MERGE_PARENTS(state, { idToKeep, idToDelete }) {
            state.items = state.items.filter(parent => parent.id != idToDelete);
            state.items.filter(parent => parent.id == idToKeep).forEach(parent => {
                parent.homonymes = parent.homonymes.filter(homonyme => homonyme.id != idToDelete);
            });
        },
        KEEP_PARENTS(state, { idToKeepA, idToKeepB }) {
            state.items.filter(parent => parent.id == idToKeepA).forEach(parent => {
                parent.homonymes = parent.homonymes.filter(homonyme => homonyme.id != idToKeepB);
            });
            state.items.filter(parent => parent.id == idToKeepB).forEach(parent => {
                parent.homonymes = parent.homonymes.filter(homonyme => homonyme.id != idToKeepA);
            });
        },
        SET_LOADING(state, _loading) {
            state.loading = _loading;
        },
    },
    actions: {
        loadImportBourseAuxLivres({ commit }) {
            commit('SET_LOADING', true);
            return xhr
                .get('api/admin/validation/bal')
                .then(response => {
                    commit('SET_ITEMS', response.data);
                })
                .finally(() => commit('SET_LOADING', false));
        },
        sendValidationImportBourseAuxlivres({ commit, dispatch }, { items }) {
            commit('SET_LOADING', true);
            let params = items.map(el => ({ id: el.id }));
            return xhr
                .post('api/admin/validation/bal', params)
                .then(() => {
                    // Don't commit WORKFLOW_TOGGLE_LOADING here, because it's done after in loadImportBourseAuxLivres
                    dispatch('loadImportBourseAuxLivres');
                })
                .catch(() => commit('SET_LOADING', false));
        },
        sendMergeParentValidationImportBourseAuxlivres({ commit }, { idToKeep, idToDelete }) {
            let params = { id_to_keep: idToKeep, id_to_delete: idToDelete };
            return xhr
                .post('api/admin/validation/bal/merge', params)
                .then(() => {
                    commit('MERGE_PARENTS', { idToKeep: idToKeep, idToDelete: idToDelete });
                });
        },
        keepParentValidationImportBourseAuxlivres({ commit }, { idParentA, idParentB }) {
            commit('KEEP_PARENTS', { idToKeepA: idParentA, idToKeepB: idParentB });
        },
    },
    getters: {
        getLoading(state) {
            return state.loading;
        },
        getImportBourseAuxLivres(state) {
            return state.items;
        },
    },
};
