import xhr from '../plugins/axios';

export default {
  namespaced: true,
  state: {
    etablissementActif: {},
    etablissements: [],
    filter: { type: null, q: '' },
    cdpeId: null,
    clId: null,
  },

  mutations: {
    SET_ETABLISSEMENT_ACTIF(state, data) {
      state.etablissementActif = data;
    },
    SET_ETABLISSEMENTS(state, data) {
      state.etablissements = data;
    },
    SET_FILTER(state, { type, q }) {
      state.filter = { type, q };
    },
    SET_CDPE_ID(state, data) {
      state.cdpeId = data;
    },
    SET_CL_ID(state, data) {
      state.clId = data;
    },
  },
  actions: {
    loadEtablissements({ commit }, { cdpeId }) {
      return xhr.get(`api/admin/etablissementScolaire?cdpe=${cdpeId}`).then(response => {
        commit('SET_CDPE_ID', cdpeId);
        commit('SET_ETABLISSEMENTS', response.data);
      });
    },
    loadEtablissementActif({ commit }, { etablissementId }) {
      return xhr.get(`api/admin/etablissementScolaire/${etablissementId}`).then(response => {
        commit('SET_ETABLISSEMENT_ACTIF', response.data);
      });
    },
    createEtablissement({ commit }, { etablissement }) {
      return xhr.post('api/admin/etablissementScolaire', etablissement)
                .then(response => {
                  commit('SET_ETABLISSEMENT_ACTIF', response.data);
                });
    },
    updateEtablissement({ dispatch }, { etablissementId, etablissement }) {
      return xhr.put(`api/admin/etablissementScolaire/${etablissementId}`, etablissement)
        .then(() => dispatch('loadEtablissementActif', { etablissementId }));
    },
    deleteEtablissement({ state }, { etablissementId }) {
      return xhr.delete(`api/admin/etablissementScolaire/${etablissementId}`)
        .then(() => state.etablissements = state.etablissements.filter(it => it.id != etablissementId));
    },

  },
  getters: {
    getEtablissements(state) {
      return state.etablissements;
    },
    getFilteredEtablissements(state) {
      return state.etablissements
        .filter(eta => 
            !state.filter.q || 
            ((!state.filter.type || state.filter.type === 'nom'                ) && eta.nom                .toLowerCase().includes(state.filter.q.toLowerCase())) ||
            ((!state.filter.type || state.filter.type === 'adresse.commune.nom') && eta.adresse.commune.nom.toLowerCase().includes(state.filter.q.toLowerCase())) ||
            ((!state.filter.type || state.filter.type === 'cl.nom'             ) && eta.cl.nom             .toLowerCase().includes(state.filter.q.toLowerCase())) ||
            ((!state.filter.type || state.filter.type === 'cl.code'            ) && eta.cl.code            .toLowerCase().includes(state.filter.q.toLowerCase()))
            )
        ;
    },
    getEtablissementActif(state) {
      return state.etablissementActif;
    },
    getCloneEtablissementActifForUpdate(state) {
      let clone = JSON.parse(JSON.stringify(state.etablissementActif));
      return {
        nom: clone.nom,
        code_uai: clone.code_uai,
        cap_accueil: clone.cap_accueil,
        cl_id: clone.cl.id,
        commentaire: clone.commentaire,
        is_etablissement_principal: clone.is_etablissement_principal,
        niveaux_enseignement_ids: clone.niveaux_enseignement.map(ne => ne.id),
        adresse: {
          commune_id: clone.adresse.commune.id,
          ligne1: clone.adresse.ligne1,
          ligne2: clone.adresse.ligne2,
        },
        contact: {
          abonne_newsletter: clone.contact.abonne_newsletter,
          email: clone.contact.email,
          telephone_fixe: clone.contact.telephone_fixe,
          telephone_mobile: clone.contact.telephone_mobile,
        },
      };
    },
    getFilter(state) {
      return state.filter;
    },
    getCdpeId(state) {
      return state.cdpeId;
    },
    getClId(state) {
      return state.clId;
    },
  },
};
