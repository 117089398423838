<template>
  <div class="pa-5">
    <form
      @submit.prevent="addDon"
    >
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <DatePicker
            label="Date"
            :date="donDate"
            :readonly="true"
            :required="true"
            @changeDate="changeDonDate"
          />

          <v-text-field
            v-model="montant"
            v-validate="'required|min_value:0'"
            required
            type="float"
            suffix="€"
            label="Montant *"
            data-vv-name="montant"
            :error-messages="errors.collect('montant')"
            @input="changeMontant"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="selectedRecipient"
            v-validate="'required'"
            no-data-text="Aucun destinataire disponible pour ce parent"
            label="Destinataires *"
            required
            :items="availableRecipients"
            data-vv-name="destinataire"
            item-text="nom"
            return-object
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <PaiementSelect
            :loaded-moyen-paiement="loadedMoyenPaiement"
            @moyenPaiementSelected="updateMoyenPaiement"
          />
          <v-btn
            type="submit"
            :disabled="!canAddDon"
            color="primary"
          >
            Ajouter
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import DatePicker from '../../partials/DatePicker';
import PaiementSelect from '../../partials/paiement/select';
import { mapGetters, mapActions } from 'vuex';
import { getDefaultMoyenPaiement } from '../../../config/moyenPaiement';

export default {
  name: 'DonForm',

  components: {
    DatePicker,
    PaiementSelect,
  },

  data: () => ({
    montant: '',
    selectedRecipient: null,
    selectedMoyenPaiement: null,
    loadedMoyenPaiement: getDefaultMoyenPaiement(),
  }),

  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
    ...mapGetters('don', {
      donDate: 'getDonDate',
      dons: 'getDons',
    }),
    ...mapGetters('adhesion', {
      conseilLocaux: 'getConseilLocauxParent',
    }),

    canAddDon() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },

    /**
     * Filters available cls with the ones who were already donated to
     * Adds a generic entry if no departemental don was made
     *
     * @returns {Array}
     */
    availableRecipients() {
      let filteredCls,
          departementalRecipients;

      filteredCls = this.conseilLocaux.map((cl) => {
            return {
              ...cl,
              is_local: true,
            };
          });

      departementalRecipients = [{
            id: 0,
            nom: 'Conseil Départemental',
            is_local: false,
          }];
      return departementalRecipients.concat(filteredCls);
    },
  },

  methods: {
    ...mapActions('don', [
        'changeDonDate',
        'addDonDepartemental',
        'addDonLocal',
    ]),
    changeMontant(montant) {
      this.montant = montant.replace(',', '.');
    },
    updateMoyenPaiement(moyenPaiement) {
      this.selectedMoyenPaiement = moyenPaiement;
    },

    addDon() {
      let addMethod;

      let don = {
        date: this.donDate,
        montant: this.montant,
        moyen_paiement: this.selectedMoyenPaiement,
      };

      if (this.selectedRecipient.is_local) {
        addMethod = this.addDonLocal;
        Object.assign(don, {
          cl: {
            id: this.selectedRecipient.id,
          },
        });
      } else {
        addMethod = this.addDonDepartemental;
      }

      let self = this;

      addMethod({
        parentId: this.parent.id,
        don,
      }).then(() => {
        self.resetForm();
      });
    },

    resetForm() {
      this.selectedRecipient = null;
      this.montant = '';

      //Need 3 nextTick because a validation is triggered before
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
             this.errors.clear();
          });
        });
      });
    },

  },
};
</script>