import xhr from '../../plugins/axios.js';
import typePerimetre from '../../auth/typePerimetre';

function initialState() {
  return {
    userById: {
      droits: [
        {
          actif: true,
          effectif: true,
          nom: '',
        },
      ],
      email: '',
      id: 0,
      nom: '',
      perimetreId: 0,
      typePerimetreId: 0,
      prenom: '',
      role: '',
      bloque: false,
      rolesDisponibles: [''],
    },
    loading: false,
    loadingUpdate: false,
    loadingUpdateRole: null,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setUser(state, data) {
      state.userById = data;
    },
    setUserRights(state, data) {
      state.userById.droits = data;
    },
    setLoading(state, toggle) {
      state.loading = toggle;
    },
    setLoadingUpdate(state, toggle) {
      state.loadingUpdate = toggle;
    },
    setLoadingUpdateRole(state, toggle) {
      state.loadingUpdateRole = toggle;
    },
  },

  actions: {
    toggleLoading({ commit }, { stateLoading }) {
      commit('setLoading', stateLoading);
    },
    loadUserById({ commit  }, userId) {
      commit('setLoading', true);
      return xhr
        .get(`api/admin/parametrages/utilisateur/${userId}`)
        .then((response) => {
          commit('setUser', response.data);
          commit('setLoading', false);
        });
    },
    updateUser({ commit, dispatch }, user) {
      commit('setLoadingUpdate', true);
      dispatch('session/hideSuccess', { root: true });
      return xhr
        .put(`/api/admin/parametrages/utilisateur/${user.id}`, {
          nom: user.nom,
          prenom: user.prenom,
          role: user.role,
          bloque: user.bloque,
        })
        .then(() => {
          commit('setUser', user);
          commit('setLoadingUpdate', false);
          dispatch(
            'session/setSuccess',
            { info: "L'utilisateur a été mis à jour", message: '' },
            { root: true }
          );
        });
    },
    activateRight({ commit }, { droit, user }) {
      commit('setLoadingUpdateRole', droit);
      return xhr
        .post(`/api/admin/parametrages/utilisateur/${user.id}/droit/${droit}`)
        .then(() => {
          commit('setUserRights', user.droits);
          commit('setLoadingUpdateRole', null);
        });
    },
    deactivateRight({ commit }, { droit, user }) {
      commit('setLoadingUpdateRole', droit);
      return xhr
        .delete(`/api/admin/parametrages/utilisateur/${user.id}/droit/${droit}`)
        .then(() => {
          commit('setUserRights', user.droits);
          commit('setLoadingUpdateRole', null);
        });
    },
  },
  getters: {
    getUserRights(state, getters, rootState, rootGetters) {
      if (!state.userById.id) {
        return [];
      }
      let listDroits = state.userById.droits;
      const typePerimetreUtilisateurId = state.userById.typePerimetreId;
      const listDroitsUtilisateurConnecte = rootState.session.user.droits;
      const isUserConnecteNational = rootGetters['session/isUserNational'];
      let listDroitsModifiable = listDroits.filter((droit) => {
        return (
          droitEstModifiableSurUtilisateur(droit, typePerimetreUtilisateurId) &&
          (listDroitsUtilisateurConnecte.includes(droit.nom) ||
            isUserConnecteNational)
        );
      });
      listDroitsModifiable.map(
        (x) => (x.displayNom = x.nom.split('_').join(' '))
      );
      return listDroitsModifiable;
    },
  },
};

function droitEstModifiableSurUtilisateur(droit, typePerimetreUtilisateurId) {
  return (
    (droit.national && typePerimetreUtilisateurId === typePerimetre.NATIONAL) ||
    (droit.departemental &&
      typePerimetreUtilisateurId === typePerimetre.DEPARTEMENTAL) ||
    (droit.local && typePerimetreUtilisateurId === typePerimetre.LOCAL)
  );
}
