<template>
  <div class="mb-5">
    <v-row>
      <v-col cols="12">
        <h2 class="title">
          Liste des enfants
        </h2>

        <v-data-table
          no-data-text="Pas d'enfant pour ce parent"
          :hide-default-footer="true"
          :headers="headers"
          :items="enfants"
          :loading="getEnfantsLoading"
          loading-text="Chargement en cours"
          class="elevation-3"
        >
          <template v-slot:item="props">
            <tr>
              <td v-if="hasActions">
                <div class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        more_vert
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item @click="modifyEnfant(props.item.id)">
                        <v-list-item-title>Modifier</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="confirmDeleteEnfant(props.item.id)">
                        <v-list-item-title>Supprimer</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </td>
              <td>{{ props.item.nom }}</td>
              <td>{{ props.item.prenom }}</td>
              <td>{{ props.item.date_de_naissance | formatDate }}</td>
              <td>{{ _capitalize(props.item.classe.etablissement_scolaire.nom) }}</td>
              <td>{{ _capitalize(props.item.classe.etablissement_scolaire.conseil_local.nom) }}({{ props.item.classe.etablissement_scolaire.conseil_local.commune.ville }})</td>
              <td>{{ _capitalize(props.item.classe.etablissement_scolaire.commune.ville) }}</td>
              <td>{{ props.item.classe.nom }}</td>
              <td>{{ props.item.section }}</td>
            </tr>
          </template>
        </v-data-table>
        <ConfirmedModal
          v-if="showConfirmDelete"
          :sentence="modalSentence"
          :btn-action="modalBtnAction"
          @close="showConfirmDelete = false"
          @confirmed="doDeleteEnfant"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import { mapGetters, mapActions } from 'vuex';
import ConfirmedModal from './../../reusableComponents/confirmedModal';


export default {
  name: 'ListeEnfants',
  components:{
    ConfirmedModal,
  },
  props: {
    enfants: {
      type: Array,
      default() {
        return [];
      },
    },

    hasActions: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    modalSentence: 'Êtes-vous sûr de vouloir supprimer cet enfant ?',
    modalBtnAction: 'Supprimer',
    showConfirmDelete: false,
  }),

  computed: {
    ...mapGetters([
        'getEnfantsLoading',
    ]),

    headers() {
      let headers = [
        {
          text: 'nom',
          sortable: true,
          value: 'nom',
        },
        { text: 'Prénom', value: 'prenom', sortable: true },
        { text: 'Date de naissance', value: 'dob' },
        { text: 'Etablissement', value: 'etbs', sortable: false },
        { text: "Nom CL de l'établissement", value: 'nomCl', sortable: false },
        { text: 'Commune', value: 'commune', sortable: false },
        { text: 'Classe', value: 'classe', sortable: false },
        { text: 'Section', value: 'section', sortable: false },
      ];

      if (this.hasActions) {
        headers.unshift({ text: 'Action', value: 'action', sortable: false });
      }

      return headers;
    },
  },

  methods: {
    ...mapActions([
      'deleteEnfant',
    ]),
    _capitalize,
    modifyEnfant(enfantId) {
      let parentId = this.$route.params.parentId;
      this.$router.push({
        name: 'edition-enfant',
        params: {
          parentId,
          enfantId,
        },
      });
    },
    confirmDeleteEnfant(enfantId) {
      this.showConfirmDelete = true;
      this.enfantIdToDelete = enfantId;
    },
    doDeleteEnfant() {
      let parentId = this.$route.params.parentId;
      this.deleteEnfant({
        parentId: parentId,
        enfantId: this.enfantIdToDelete,
      }).then(() => {
        this.$emit('reloadEnfants');
      }).finally(() => {
        this.enfantIdToDelete = 0;
        this.showConfirmDelete = false;
      });
    },
  },
};
</script>

<style>
</style>
