<template>
  <div>
    <v-progress-linear
      v-if="showProgress"
      indeterminate
    />
    <v-alert 
      color="primary"
      dense
      type="info"
      :value="showAlertPasEnfantsScolarises"
    >
      La saisie d'adhésion n'est possible que s'il existe au moins un enfant scolarisé dans le conseil local du parent.
    </v-alert>
    <v-alert 
      color="primary"
      dense
      type="warning"
      :value="showAlertPasNiveauxAdhesion"
    >
      La saisie d'adhésion n'est pas possible car les niveaux d'adhésion ne sont pas encore définis sur l'année scolaire courrante
    </v-alert>
    <div v-if="showAdhesionForm">
      <v-card class="elevation-3">
        <AdhesionInfo />
      </v-card>
      <v-card
        v-if="isAdherent"
        class="elevation-3"
        style="margin-top: 10px"
      >
        <PartForm
          :conseil-locaux="conseilLocaux"
          :parent="parent"
          :niveaux-c-l="niveauxCL"
        />
      </v-card>
      <v-card
        v-if="isAdherent"
        class="elevation-3"
        style="margin-top: 10px"
      >
        <div>
          <v-row>
            <v-col cols="12">
              <div class="pa-2">
                <h3 class="title">
                  Liste des parts locales
                </h3>
                <ListePartsLocal :adhesion="adhesion" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import ListePartsLocal from './part/Liste';
import AdhesionInfo from './Info';
import PartForm from './part/Form';
import { mapGetters } from 'vuex';

export default {
  name: 'AdhesionForm',
  components: {
    AdhesionInfo,
    ListePartsLocal,
    PartForm,
  },
  computed: {
    ...mapGetters({
      enfants: 'getEnfants',
      enfants_loading: 'getEnfantsLoading',
      parent: 'getParent',
      parent_loading: 'getParentLoading',
    }),
    ...mapGetters('adhesion', {
      niveauxDP: 'getNiveauxDEP',
      niveauxCL: 'getNiveauxCL',
      niveauxDepartementalLoading: 'getNiveauxDepartementalLoading',
      conseilLocaux: 'getConseilLocauxParentPourPartLocal',
      adhesion: 'getAdhesion',
    }),
    isAdherent() {
      return this.adhesion != undefined;
    },
    showProgress() {
      return  !this.hasParentLoaded || this.parent_loading || this.enfants_loading || this.niveauxDepartementalLoading;
    },
    enfantsCLPrincipal() {
      return this.enfants.filter((enfant)=> enfant.classe.etablissement_scolaire.conseil_local.id === this.parent.conseil_local.id);
    },
    showAlertPasEnfantsScolarises() {
      return this.hasParentLoaded && !this.parent_loading && !this.enfants_loading && this.enfantsCLPrincipal.length === 0;
    },
    showAlertPasNiveauxAdhesion() {
      return this.hasParentLoaded && !this.parent_loading && !this.niveauxDepartementalLoading && this.niveauxDP.length === 0; 
    },
    showAdhesionForm() {
      return this.hasParentLoaded && !this.parent_loading
        && !this.enfants_loading && this.enfantsCLPrincipal.length > 0 
        && !this.niveauxDepartementalLoading && this.niveauxDP.length > 0; 
    },
    hasParentLoaded() {
      return this.parent && this.parent.id;
    },
  },
  methods: {
    leave() {},
  },
};
</script>
