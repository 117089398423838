<template>
  <v-select
    v-model="selectedMoyenPaiement"
    v-validate="`${required ? 'required': ''}`"
    :items="moyensPaiement"
    :label="formattedLabel"
    item-value="id"
    item-text="libelle"
    data-vv-name="moyen de paiement"
    :error-messages="errors.collect('moyen de paiement')"
    @input="moyenPaiementSelected"
  />
</template>

<script>
import MOYENS_PAIEMENT from '../../../config/moyenPaiement';
export default {
  name: 'PaymentSelect',

  props: {
    required: {
      type: Boolean,
      default: true,
    },

    loadedMoyenPaiement: {
      type: String,
      default: '',
    },
  },

  /**
   * This component should never have a specific validator instance
   * We inject the parent validator
   */
  inject: [
    '$validator',
  ],

  data: () => ({
    moyensPaiement: MOYENS_PAIEMENT,
    label: 'Moyen de paiement',
    selectedMoyenPaiement: null,
  }),

  computed: {
    formattedLabel() {
      return `${this.label}${this.required ? ' *': ''}`;
    },
  },
  watch: {
    loadedMoyenPaiement: {
      immediate: true,
      handler(val) {
        this.selectedMoyenPaiement = val;
        this.moyenPaiementSelected(val);
      },
    },
  },

  methods: {
    moyenPaiementSelected(moyen) {
      this.$emit('moyenPaiementSelected', moyen);
    },
  },
};
</script>
