<template>
  <div>
    <v-card>
      <div
        class="pa-5
      mb-5"
      >
        <h2 class="title">
          Recherche parents / adhérents
        </h2>
        <div>
          <FilterForm
            changer="changer"
            :perimetre-filter-id="perimetreFilterId"
          />
        </div>
      </div>
    </v-card>
    <v-card>
      <div class="pa-5">
        <div
          primary-title
          class="row gutters justify-space-between title"
        >
          <span
            v-if="parents.length"
            class="col"
          >Resultat de recherche : {{ parents.length > 0 ? `${parents.length} parents` : '' }}</span>
          <div class="col flex-grow-0">
            <v-btn
              outlined
              large
              color="primary"
              @click="toggleFilter"
            >
              <v-icon>filter_list</v-icon>Filtrer
            </v-btn>
          </div>
        </div>

        <v-slide-y-transition>
          <div v-show="showFilter">
            <v-row class="xs14">
              <v-col
                v-if="showFilterDepartements"
                cols="4"
                class="text-right"
              >
                <v-autocomplete
                  v-model="filterDepartement"
                  clearable
                  :items="filterDepartements"
                  label="Département"
                  :item-text="perimetreFilterLabel"
                  item-value="id"
                />
              </v-col>
              <v-col
                v-if="showFilterConseilsLocaux"
                cols="4"
                class="text-right"
              >
                <v-autocomplete
                  v-model="filterConseilLocal"
                  clearable
                  :items="filterConseilsLocaux"
                  label="Conseil local"
                  :item-text="perimetreFilterLabelConseilLocal"
                  item-value="id"
                />
              </v-col>
              <v-col
                v-if="showFilterResponsabilites"
                cols="4"
                class="text-right"
              >
                <ResponsabilitesAutocomplete
                  v-if="showFilterResponsabiliteLocale"
                  label="Responsabilité locale"
                  :current-responsabilite-id="filterResponsabiliteId"
                  :responsabilites="listeResponsabilitesLocal"
                  :fake-reload="fakeReloadResponsabilite"
                  :with-all-option="true"
                  :display-responsables="false"
                  @changedResponsabilite="changedResponsabilite"
                />
                <ResponsabilitesAutocomplete
                  v-if="showFilterResponsabiliteDepartemental"
                  label="Responsabilité départementale"
                  :current-responsabilite-id="filterResponsabiliteId"
                  :responsabilites="responsabilitesDepartemental"
                  :fake-reload="fakeReloadResponsabilite"
                  :with-all-option="true"
                  :display-responsables="false"
                  @changedResponsabilite="changedResponsabilite"
                />
              </v-col>
              <v-col
                v-if="showFilterParentsParApparenance"
                cols="4"
                class="text-right"
              >
                <v-checkbox
                  v-model="filterParentsParAppartenance"
                  label="Ajouter les parents par appartenance"
                />
              </v-col>
            </v-row>
          </div>
        </v-slide-y-transition>

        <v-alert
          :value="parents.length > 0"
        >
          Il y a
          <b>{{ selected.length }}</b> résultats séléctionnés
          <v-btn
            v-if="displayRecusFiscaux()"
            color="primary"
            class="ml-3"
            small
            @click="generateRecusFiscaux"
          >
            {{ selected.length > 1 ? 'Générer les reçus fiscaux' : 'Générer le reçu fiscal' }}
          </v-btn>
          <v-btn
            v-if="parentsEmails.length > 0"
            color="primary"
            class="ml-3"
            small
            :href="sendMailTo"
          >
            Envoyer un email
          </v-btn>
        </v-alert>

        <ListeParents
          v-if="showListParent"
          :loading="loading"
          :parents="parents"
          @delete="confirmDelete"
          @changeSelected="changeSelected"
        />

        <div v-if="showListParent">
          <ColorsStatuts />
        </div>
      </div>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      bottom="bottom"
      :timeout="-1"
    >
      Attention, tous les parents ne sont pas affichés, veuillez affiner les résultats en filtrant votre recherche
      <v-btn
        color="pink"
        @click="snackbar = false"
      >
        Fermer
      </v-btn>
    </v-snackbar>
    <ConfirmedModal
      v-if="parentToDeleteDialog"
      :sentence="`Voulez-vous vraiment supprimer le parent ${parentToDeleteName} ?`"
      :btn-action="modalBtnAction"
      @close="parentToDelete = null"
      @confirmed="deleteParent"
    />
    <v-dialog
      v-model="parentDeletedDialog"
      max-width="300"
    >
      <v-card>
        <div class="pa-5">
          <v-card-text> La fiche parent de {{ parentDeletedName }} a bien été supprimé.</v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="parentDeleted = null"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <Downloader
      :downloading-file="downloadingFile"
      label="recu fiscal"
    />
  </div>
</template>

<style lang="scss" scoped>
@import './Recherche.scss';
</style>

<script>
import FilterForm from './FilterForm';
import ListeParents from './Liste';
import ColorsStatuts from './ColorsStatuts';
import Downloader from '../../partials/Downloader';
import typePerimetre from '../../../auth/typePerimetre';
import ResponsabilitesAutocomplete from '../../partials/ResponsabilitesAutocomplete';
import ConfirmedModal from './../../reusableComponents/confirmedModal';

import { mapGetters, mapActions, mapState } from 'vuex';
import _debounce from 'lodash/debounce';

export default {
  components: {
    FilterForm,
    ListeParents,
    ColorsStatuts,
    Downloader,
    ResponsabilitesAutocomplete,
    ConfirmedModal,
  },
  data: () => ({
    modalBtnAction: 'Supprimer',
    showFilter: false,
    selected: [],
    parentToDelete: null,
    parentDeleted: null,
    fakeReloadResponsabilite: false,
    downloadingFile: null,
  }),
  computed: {
    ...mapGetters('session', {
      getPerimetreUser: 'getPerimetreUser',
      isUserNational: 'isUserNational',
      isUserDepartemental: 'isUserDepartemental',
      isUserLocal: 'isUserLocal',
      getUserCdpeId: 'getUserCdpeId',
      getUserConseilLocalId: 'getUserConseilLocalId',
    }),


    ...mapState('recherche', {
        parents: state => state.results.parents,
        loading: state => state.loading,
        limit: state => state.limit,
        messageLimit: state => state.messageLimit,
        filters: state => state.selectedFilters,
    }),

    ...mapState('responsabilites', [
        'responsabilitesLocal',
        'responsabilitesDepartemental',
    ]),

    ...mapState('conseilLocal', {
      filterConseilsLocaux: state => state.filterConseilsLocaux,
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),

    filterDepartement: {
      get() {
        return this.filters.filterDepartementId;
      },
      set(value) {
        let filters = {
          ...this.filters,
          filterDepartementId: value ? value : 0,
          filterConseilLocalId: 0,
          filterAddParentParAppartenance: false,
          filterResponsabiliteId: 0,
          searchOnLoad: false,
        };
        this.setSelectedFilters(filters);
        if (value) {
          this.loadFilterConseilsLocaux(value);
          this.loadResponsabilitesDepartemental(value);
        }
      },
    },
    filterConseilLocal: {
      get() {
        return this.filters.filterConseilLocalId;
      },
      set(value) {
        let filters = {
          ...this.filters,
          filterConseilLocalId: value,
          filterResponsabiliteId: 0,
          searchOnLoad: true,
        };
        if (value === 0) {
          filters.filterAddParentParAppartenance = false;
          filters.searchOnLoad = false;
        }
        this.setSelectedFilters(filters);
        if (value) {
          this.loadResponsabilitesLocal(value);
        }
        this.doSearch();
      },
    },
    filterParentsParAppartenance: {
      get() {
        return this.filters.filterAddParentParAppartenance;
      },
      set(value) {
        let filters = {
          ...this.filters,
          filterAddParentParAppartenance: value,
          searchOnLoad: true,
        };
        this.setSelectedFilters(filters);
        this.doSearch();
      },
    },
    filterResponsabiliteId: {
      get() {
        return this.filters.filterResponsabiliteId;
      },
      set(value) {
        let filters = {
          ...this.filters,
          filterResponsabiliteId: value,
          searchOnLoad: true,
        };
        this.setSelectedFilters(filters);
        this.doSearch();
      },
    },
    listeResponsabilitesLocal() {
      return this.filterConseilLocal ? this.responsabilitesLocal[this.filterConseilLocal] : [];
    },
    snackbar: {
      get() {
        return this.messageLimit;
      },
      set(value) {
        this.setMessageLimit(value);
      },
    },
    parentDeletedDialog() {
      return this.parentDeleted !== null;
    },
    parentDeletedName() {
      return this.parentDeleted !== null
              ? `${this.parentDeleted.personne.prenom} ${this.parentDeleted.personne.nom} (${this.parentDeleted.code})`
              : '';
    },
    parentToDeleteDialog() {
      return this.parentToDelete !== null;
    },
    parentToDeleteName() {
      return this.parentToDelete !== null
        ? `${this.parentToDelete.personne.prenom} ${this.parentToDelete.personne.nom} (${this.parentToDelete.code})`
        : '';
    },
    perimetreFilterId() {
      let perimetreFilterId = this.filters.filterConseilLocalId
        ? this.filters.filterConseilLocalId
        : this.filters.filterDepartementId ? this.filters.filterDepartementId : typePerimetre.NATIONAL;
      if (this.showFilterDepartements) {
        return perimetreFilterId;
      } else {
        return perimetreFilterId ? perimetreFilterId : this.getPerimetreUser.perimetre_id;
      }
    },
    showFilterDepartements() {
      return this.isUserNational;
    },
    showFilterConseilsLocaux() {
      return this.perimetreFilterId > typePerimetre.NATIONAL && !this.isUserLocal;
    },
    showFilterParentsParApparenance() {
      return this.filterConseilLocal > 0;
    },
    showFilterResponsabilites() {
      return this.showFilterResponsabiliteLocale || this.showFilterResponsabiliteDepartemental;
    },
    showFilterResponsabiliteLocale() {
      return !!this.filters.filterConseilLocalId;
    },
    showFilterResponsabiliteDepartemental() {
      return !!this.filters.filterDepartementId && !this.filters.filterConseilLocalId;
    },
    showListParent() {
      return this.perimetreFilterId > 0;
    },
    parentsEmails() {
      return this.selected
          .filter(parent => parent.contact && parent.contact.email)
          .map(parent => parent.contact.email);
    },
    sendMailTo() {
      return `mailto:${this.parentsEmails.join(',')}`;
    },

  },
  created() {
    this.doSearch = _debounce(() => {
      if (this.perimetreFilterId && this.filters.searchOnLoad) {
        let query = {
          limit: this.limit,
          perimetre_id: this.perimetreFilterId,
        };
        if (this.filters.filterResponsabiliteId) {
          query.responsabilite_id = this.filters.filterResponsabiliteId;
        }
        if (this.filters.filterQuery) {
          query.q = this.filters.filterQuery;
          query.filterType = this.filters.filterType ? this.filters.filterType : 'tous_criteres';
        }
        if (this.filters.filterAddParentParAppartenance) {
          query.addParentParAppartenance = this.filters.filterAddParentParAppartenance;
        }

        this.search(query).then(() => {
          this.selected = [];
        });
      }
    }, 10); // debounce 10ms because of watchers conflicts
    let self = this;
    if (this.isUserNational) {
      this.loadFilterDepartements().then(() => {
        self.toggleFilter();
        self.fakeReloadResponsabilite=true;
      });
    }
    if (this.isUserDepartemental) {
      let filters = { ...this.filters, filterDepartementId: this.getPerimetreUser.perimetre_id, searchOnLoad: this.filters.filterConseilLocalId };
      this.setSelectedFilters(filters);
      this.loadFilterConseilsLocaux(this.getPerimetreUser.perimetre_id).then(() => {
        self.toggleFilter();
      });
      this.loadResponsabilitesDepartemental(this.getUserCdpeId);
    }
    if (this.isUserLocal) {
      let filters = { ...this.filters, filterConseilLocalId: this.getPerimetreUser.perimetre_id,  searchOnLoad: true };
      this.setSelectedFilters(filters);
      this.loadResponsabilitesLocal(this.getUserConseilLocalId);
    }
    this.doSearch();
  },
  beforeDestroy() {
    this.resetResultParents();
    if (!this.filters.filterType
        && !this.filters.filterQuery
        && !this.filters.filterAddParentParAppartenance
        && !this.filters.filterResponsabiliteId
        ) {
          this.setSelectedFilters({ ...this.filters, searchOnLoad: false });
    }
  },
  methods: {
    ...mapActions([
        'deleteParentById',
    ]),

    ...mapActions('recherche', [
        'search',
        'setSelectedFilters',
        'setMessageLimit',
        'resetResultParents',
        'updateResultParentsAfterDelete',
    ]),

    ...mapActions('responsabilites', [
        'loadResponsabilitesLocal',
        'loadResponsabilitesDepartemental',
    ]),
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
    ...mapActions('conseilLocal', [
      'loadFilterConseilsLocaux',
    ]),
    changedResponsabilite({ responsabilite }) {
      this.filterResponsabiliteId = responsabilite ?
          responsabilite.id :
          null;
      this.filterParentsParAppartenance = !!responsabilite;
    },
    perimetreFilterLabel(item) {
      return `${item.code} - ${item.nom}`;
    },
    perimetreFilterLabelConseilLocal(item) {
      if(typeof item.adresse_legale !== 'undefined') {
        return `${item.code} - ${item.nom} (${item.adresse_legale.ville})`;
      }

      return this.perimetreFilterLabel(item);
    },
    confirmDelete(parentToDelete) {
      this.parentToDelete = parentToDelete;
    },
    deleteParent() {
      let self = this;
      this.deleteParentById({ parentId: self.parentToDelete.id }).then(() => {
        self.parentDeleted = self.parentToDelete ;
        self.parentToDelete = null;
      });
    },
    changeSelected(val) {
      this.selected = val;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    displayRecusFiscaux() {
      return this.selected.length > 0 && this.isUserNational;
    },

    generateRecusFiscaux() {
      let paramsUrl = { parentIds: this.selected.map(parent => parent.id ) };
      this.downloadingFile = {
        url: '/api/resources/recusdonsoeuvres',
        params: paramsUrl,
      };
    },
  },
};
</script>
