export default {
  namespaced: true,
  state: {
    defaultConseilLocal: {
      commune: {
        code_postal: '',
        id: null,
        ville: '',
      },
      id: null,
    },
  },

  mutations: {
    setDefaultConseilLocal(state, conseilLocal) {
        state.defaultConseilLocal = conseilLocal;
    },
  },

  actions: {},

  getters: {},
};
