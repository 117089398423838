import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    niveaux: [],
  },

  mutations: {
    SET_NIVEAUX_ENSEIGNEMENT(state, data) {
      state.niveaux = data;
    },
  },

  actions: {
    loadNiveauxEnseignement({ commit }) {
      return xhr.get('api/admin/parametrages/niveauenseignement').then(response => {
        commit('SET_NIVEAUX_ENSEIGNEMENT', response.data);
      });
    },
  },

  getters: {
    getNiveauxEnseignement(state) {
      return state.niveaux;
    },
  },
};
