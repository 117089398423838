import xhr from '../../plugins/axios.js';

export default {
  namespaced: true,
  state: {
    usersByPerimetreId: [],
    loading: false,
    currentCdpe: {
      id: 0,
      nom: '',
      code: '',
    },
    currentConseilLocal: {
      id: 0,
      nom: '',
      code: '',
    },
    listParents: [],
  },

  mutations: {
    setUsersByPerimetreId(state, data) {
      state.usersByPerimetreId = data;
    },
    setLoading(state, toggle) {
      state.loading = toggle;
    },
    setCurrentCdpe(state, perimetre) {
      state.currentCdpe = perimetre;
    },
    setCurrentConseilLocal(state, perimetre) {
      state.currentConseilLocal = perimetre;
    },
    setListParents(state, listParents) {
      state.listParents = listParents;
    },
  },

  actions: {
    toggleLoading({ commit }, { stateLoading }) {
      commit('setLoading', stateLoading);
    },
    loadUsersByPerimetreId({ commit }, { cdpe, conseilLocal }) {
      if (!cdpe) {
        commit('setUsersByPerimetreId', []);
        return;
      }

      let recursive = false;
      let perimetreId;
      
      if (conseilLocal) {
        perimetreId = conseilLocal.id !== 0 ? conseilLocal.id : cdpe.id;
        recursive = conseilLocal.id === 0;
      } else {
        perimetreId = cdpe.id;
      }

      commit('setLoading', true);
      return xhr
        .get(`api/admin/parametrages/utilisateur/?perimetreId=${perimetreId}&recursive=${recursive}`)
        .then((response) => {
          let users = response.data;
          if (recursive) {
            users = users.filter(user => user.perimetreId !== perimetreId);
          }
          commit('setUsersByPerimetreId', users);
          commit('setCurrentCdpe', cdpe);
          commit('setCurrentConseilLocal', conseilLocal);
          commit('setLoading', false);
        })
        .catch((error) => {
          commit('setUsersByPerimetreId', []);
          commit('setLoading', false);
          throw error;
        });
    },
    createListParents({ commit }, perimetreId) {
      return xhr
        .get(
          `/api/admin/parent?limit=10000&perimetre_id=${perimetreId}&responsabilite_id=-1`
        )
        .then((response) => {
          commit('setListParents', response.data);
        });
    },
    createUser({ dispatch, state }, userToCreate) {
      return xhr
        .post('api/admin/parametrages/utilisateur', userToCreate)
        .then(() => {
          dispatch('loadUsersByPerimetreId', { cdpe: state.currentCdpe, conseilLocal: state.currentConseilLocal });
          dispatch(
            'session/setSuccess',
            { info: "L'utilisateur a été créé", message: '' },
            { root: true }
          );
        });
    },
    deleteUser({ dispatch, state }, utilisateurId) {
      return xhr
        .delete(`api/admin/parametrages/utilisateur/${utilisateurId}`)
        .then(() => {
          dispatch('loadUsersByPerimetreId', {
            cdpe: state.currentCdpe,
            conseilLocal: state.currentConseilLocal,
          });
          dispatch(
            'session/setSuccess',
            { info: "L'utilisateur a été supprimé", message: '' },
            { root: true }
          );
        });
    },
  },
  getters: {
    getCurrentCdpe(state) {
      return state.currentCdpe;
    },
    getCurrentConseilLocal(state) {
      return state.currentConseilLocal;
    },
    getListParentsFiltered(state) {
      if (state.listParents.length === 0) {
        return [];
      } else if (state.usersByPerimetreId.length === 0) {
        return state.listParents;
      } else {
        let listParentsFiltered = state.listParents.filter(
          (o1) =>
            !state.usersByPerimetreId.some(
              (o2) => o1.contact.email === o2.email
            )
        );
        return listParentsFiltered;
      }
    },
  },
};
