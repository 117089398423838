<template>
  <v-card>
    <div class="pa-5">
      <v-btn
        class="mb-3"
        color="primary"
        @click.stop="returnToAdhesion"
      >
        <v-icon>mdi-chevron-left</v-icon>Retour
      </v-btn>
      <EditPartForm
        :part="part"
        :parent="parent"
        :edition-mode="true"
        @partModified="returnToAdhesion"
      />
    </div>
  </v-card>
</template>

<script>
import EditPartForm from '../components/parent/adhesion/part/EditForm';
import { mapActions, mapGetters } from 'vuex';
import _find from 'lodash/find';

export default {
  components: {
    EditPartForm,
  },
  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
    ...mapGetters('adhesion', {
      adhesion: 'getAdhesion',
    }),

    part() {
      return _find(this.adhesion.parts.local, (part) => {
        return part.cl.id == this.$route.params.conseilLocalId;
      });
    },
  },
  created() {
    let self = this;
    let parentId = this.$route.params.parentId;
    let conseilLocalId = this.$route.params.conseilLocalId;
    this.loadParentInfosById({ parentId })
        .then(() => {
          self.loadPartsByParentId({ parentId });
          self.loadNiveauxPartLocal(conseilLocalId);
        });
  },

  methods: {
    ...mapActions([
        'loadParentInfosById',
        'setParentIsLoading',
    ]),
    ...mapActions('adhesion', [
      'loadNiveauxPartLocal',
      'loadPartsByParentId',
    ]),
    returnToAdhesion() {
      this.setParentIsLoading();
      this.$router.push({
        name: 'edition-parent-stepId',
        params: {
          parentId: this.parent.id,
          stepId: 3,
        },
        hash: '#adhesions',
      });
    },
  },
};
</script>
