import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    idParentLogs: '',
    logs: [
      {
        date: '',
        message: '',
        operation: '',
        utilisateurNom: '',
        utilisateurPrenom: '',
        utilisateurEmail: '',
      },
    ],
    loading: false,
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLogs(state, logs) {
      state.logs = logs;
    },
    setIdParent(state, IdParent) {
      state.idParentLogs = IdParent;
    },
  },

  actions: {
    loadLogs({ commit }, { parentId }) {
      commit('setLoading', true);
      return xhr
        .get(`api/admin/parent/${parentId}/logging`)
        .then(response => {
          commit('setLogs', response.data);
          commit('setIdParent', parentId);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
  },

  getters: {
    getLogs(state) {
      return state.logs;
    },
  },
};
