const parentGetter = {
  getStep(state) {
    return state.step;
  },
  getParent(state) {
    return state.parent;
  },
  getParentLoading(state) {
    return state.parent_loading;
  },
  getParentSaving(state) {
    return state.parent_saving;
  },
  getEnfants(state) {
    return state.enfants;
  },
  getEnfantsLoading(state) {
    return state.enfants_loading;
  },
  getDefaultEtablissementsScolaires(state) {
    return state.defaultEtablissementsScolaires;
  },
};

export default parentGetter;
