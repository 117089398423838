import parentAction from './ParentAction';
import resetAction from './ResetAction';
import communeAction from './CommuneAction';
import classeAction from './ClasseAction';
import enfantAction from './EnfantAction';
import etablissementAction from './EtablissementAction';
import workflowAction from './WorkflowAction';

const actions = {
  ...parentAction,
  ...parentAction,
  ...resetAction,
  ...communeAction,
  ...classeAction,
  ...enfantAction,
  ...etablissementAction,
  ...workflowAction,
};
export default actions;
