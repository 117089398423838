import types from '../actions/types';
import queryString from 'query-string';
import xhr from '../plugins/axios';

function initialState() {
    return {
      results: {
        parents: [],
      },
      loading: false,
      filterDepartements: [],
      filterConseilsLocaux: [],
      selectedFilters: {
        searchOnLoad: false,
        filterDepartementId: 0,
        filterConseilLocalId: 0,
        filterAddParentParAppartenance: false,
        filterResponsabiliteId: 0,
        filterType: '',
        filterQuery: '',
      },
      appliedFilters: {
        searchOnLoad: false,
        filterDepartementId: 0,
        filterConseilLocalId: 0,
        filterAddParentParAppartenance: false,
        filterResponsabiliteId: 0,
        filterType: '',
        filterQuery: '',
      },
      limit: 10000,
      messageLimit: false,
    };
}


export default {
    namespaced: true,
    state: initialState,
    mutations: {
        resetState(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach(key => {
                state[key] = s[key];
            });
        },
        [types.SEARCH_PARENTS](state, data) {
            data.forEach(p => {
                if (p.conseil_local.nom === null) {
                    p.conseil_local.nom = '';
                }
                if (p.personne === null) {
                    p.personne = {
                        nom: '',
                        prenom: '',
                    };
                }
            });
            state.results.parents = data;
            if (data.length === state.limit) {
                state.messageLimit=true;
            }
        },
        [types.SEARCH_PARENTS_TOGGLE_LOADING](state, toggle) {
            state.loading = toggle;
        },
        [types.SEARCH_PARENTS_SELECTED_FILTERS](state, filters) {
            state.selectedFilters = filters;
        },
        [types.SEARCH_PARENTS_APPLY_FILTERS](state) {
            state.appliedFilters = { ...state.selectedFilters };
        },
        [types.SEARCH_PARENTS_MESSAGE_LIMIT](state, messageLimit) {
            state.messageLimit = messageLimit;
        },
    },
    actions: {
        search({ commit }, params) {
            let query;
            commit(types.SEARCH_PARENTS_TOGGLE_LOADING, true);
            commit(types.SEARCH_PARENTS_MESSAGE_LIMIT, false);
            commit(types.SEARCH_PARENTS, []);
            query = queryString.stringify(params);
            return xhr.get(`api/admin/parent?${query}`).then(response => {
                commit(types.SEARCH_PARENTS, response.data);
                commit(types.SEARCH_PARENTS_TOGGLE_LOADING, false);
                commit(types.SEARCH_PARENTS_APPLY_FILTERS);
            });
        },
        setSelectedFilters({ commit }, filters) {
            commit(types.SEARCH_PARENTS_SELECTED_FILTERS, filters);
        },
        setMessageLimit({ commit }, messageLimit) {
            commit(types.SEARCH_PARENTS_MESSAGE_LIMIT, messageLimit);
        },
        resetResultParents({ commit }) {
            commit(types.SEARCH_PARENTS, []);
        },
        updateResultParentsAfterDelete({ commit ,state }, { parentId }) {
          let parent = state.results.parents.filter(elt => elt.id != parentId);
          commit(types.SEARCH_PARENTS, parent);
        },
    },
    getters: {},
};
