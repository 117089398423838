import xhr from '../../plugins/axios';
import _difference from 'lodash/difference';

export default {
  namespaced: true,
  state: {
    list: {},
    etablissementsForCurrentCdpe: [],

    assignedEtablissements: [],
  },
  mutations: {
    populateList(state, { cdpeId, etablissements }) {
      state.list[cdpeId] = etablissements;
    },
    populateAssigned(state, etablissements) {
      state.assignedEtablissements = etablissements;
    },

    addEtablissementToAssigned(state, etablissement) {
      state.assignedEtablissements.push(etablissement);
    },

    setAssignedEtablissements(state, etablissements) {
      state.assignedEtablissements = etablissements;
    },
    /**
     *
     * @param state
     * @param cdpeId
     */
    setEtablissementsForCurrentCdpe(state, cdpeId) {
      state.etablissementsForCurrentCdpe = state.list[cdpeId];
    },
  },
  actions: {
    loadAssigned({ commit }, { cdpeId, conseilLocalId }) {
      return xhr
          .get(`api/admin/cdpe/${cdpeId}/cl/${conseilLocalId}/etablissementScolaire`)
          .then(response => {
            commit('populateAssigned', response.data);
          });
    },
    loadEtablissementsByCdpeId({ commit, state }, { cdpeId }) {
      if (typeof state.list[cdpeId] !== 'undefined') {
        commit('setEtablissementsForCurrentCdpe', cdpeId);
        return;
      }
      return xhr
          .get(`api/admin/cdpe/${cdpeId}/etablissementScolaire`)
          .then(response => {
            commit('populateList', {
              etablissements: response.data,
              cdpeId,
            });
            commit('setEtablissementsForCurrentCdpe', cdpeId);
          });
    },

    saveAssignedEtablissements(store, { cdpeId, conseilLocalId, data }) {
      return xhr
          .put(`api/admin/cdpe/${cdpeId}/cl/${conseilLocalId}/etablissementScolaire`, data);
    },
  },
  getters: {
    getAvailableEtablissements(state) {
      return _difference(
        state.etablissementsForCurrentCdpe,
        state.assignedEtablissements
      );
    },
  },
};