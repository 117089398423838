const types = {
  SESSION_LOGIN: 'SESSION_LOGIN',
  SESSION_LOGOUT: 'SESSION_LOGOUT',
  SESSION_SET_BLOQUE: 'SESSION_SET_BLOQUE',
  RESET_STATE: 'RESET_STATE',

  SEARCH_PARENTS: 'SEARCH_PARENTS',
  SEARCH_PARENTS_TOGGLE_LOADING: 'SEARCH_PARENTS_TOGGLE_LOADING',
  SEARCH_PARENTS_SELECTED_FILTERS: 'SEARCH_PARENTS_SELECTED_FILTERS',
  SEARCH_PARENTS_APPLY_FILTERS: 'SEARCH_PARENTS_APPLY_FILTERS',
  SEARCH_PARENTS_MESSAGE_LIMIT: 'SEARCH_PARENTS_MESSAGE_LIMIT',

  ERRORS_MESSAGE: 'ERRORS_MESSAGE',
  ERRORS_HIDE: 'ERRORS_HIDE',

  SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
  SUCCESS_HIDE: 'SUCCESS_HIDE',

  MENU: 'MENU',

  PARENT_FORM_STEP: 'PARENT_FORM_STEP',
  PARENT_FORM_CHANGE_CONSEIL_LOCAL: 'PARENT_FORM_CHANGE_CONSEIL_LOCAL',
  PARENT_FORM_CHANGE_DEFAULT_ETABLISSEMENTS: 'PARENT_FORM_CHANGE_DEFAULT_ETABLISSEMENTS',
  PARENT_LOAD_ENFANTS: 'PARENT_LOAD_ENFANTS',
  PARENT_ENFANTS_LOADING: 'PARENT_ENFANTS_LOADING',
  PARENT_LOAD_PARENT: 'PARENT_LOAD_PARENT',
  PARENT_PARENT_LOADING: 'PARENT_PARENT_LOADING',
  PARENT_PARENT_SAVING: 'PARENT_PARENT_SAVING',
  PARENT_UPDATE_PARENT: 'PARENT_UPDATE_PARENT',
  PARENT_DELETE: 'PARENT_DELETE',

  WORKFLOW_TOGGLE_LOADING: 'WORKFLOW_TOGGLE_LOADING',
  WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_OR_CL_ID: 'WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_OR_CL_ID',
  WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_ID: 'WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_ID',
  WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_LOAD: 'WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_LOAD',
  WORKFLOW_RECEPTION_ADHESION_DEPARTEMENT_LOAD: 'WORKFLOW_RECEPTION_ADHESION_DEPARTEMENT_LOAD',
  WORKFLOW_TRANSFERT_ADHESION_DEPARTEMENT_LOAD: 'WORKFLOW_TRANSFERT_ADHESION_DEPARTEMENT_LOAD',
  WORKFLOW_VALIDATION_ADHESIONS_EN_LIGNE_LOAD: 'WORKFLOW_VALIDATION_ADHESIONS_EN_LIGNE_LOAD',

  PERIMETRE_FILTER_CL: 'PERIMETRE_FILTER_CL',
};

export default types;
