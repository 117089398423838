const MOYENS_PAIEMENT = [
  {
    id: 'cheque',
    libelle: 'Chèque',
  },
  {
    id: 'cb',
    libelle: 'CB',
  },
  {
    id: 'virement',
    libelle: 'Virement',
  },
  {
    id: 'especes',
    libelle: 'Espèces',
  },
];

export default MOYENS_PAIEMENT;


export function getDefaultMoyenPaiement() {
  return MOYENS_PAIEMENT.find((moyenPaiement) => {
    return moyenPaiement.id === 'cheque';
  }).id;
}

export function getMoyenPaiementById(moyenPaiementId) {
  let moyenPaiement = MOYENS_PAIEMENT.find((moyenPaiement) => {
    return moyenPaiement.id === moyenPaiementId;
  });

  if (moyenPaiement) {
    return moyenPaiement;
  }

  return {
    id: '',
    libelle: '',
  };
}