import types from './types';

const resetAction = {
  resetState({ commit }) {    
    commit(types.RESET_STATE);
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      commit(`session/${types.SESSION_LOGIN}`, user);
    }
  },
};

export default resetAction;