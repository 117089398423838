const STATUTS_PARENT = {
    INACTIF: 1,
    EN_COURS_VALIDATION: 2,
    ACTIF: 3,
    IMPORT_BOURSE_LIVRES: 4,
    EN_COURS_MODIFICATION: 5,
    ADHESION_EN_LIGNE: 6,
};

export default STATUTS_PARENT;

/**
 * Define if a parent can be edited, on the basis of his statut
 * @param {*} parent
 */
export function statutCanEditParent(parent) { 
    return parent.statut.id === STATUTS_PARENT.INACTIF 
    || parent.statut.id === STATUTS_PARENT.ACTIF 
    || parent.statut.id === STATUTS_PARENT.EN_COURS_MODIFICATION;
}

/**
 * Define if a parent can be deleted, on the basis of his statut
 * @param {*} parent
 */
export function statutCanDeleteParent(parent) { 
    return parent.statut.id === STATUTS_PARENT.INACTIF; 
}

export function colorParent(parent) {
    let txtColor = STATUTS_PARENT_COLORS[parent.statut.id];
    if (parent.adherent === 'oui (N+1)') {
        txtColor = 'purple';
    }
    return txtColor;
}

const STATUTS_PARENT_COLORS = {
    [STATUTS_PARENT.INACTIF]: 'blue',
    [STATUTS_PARENT.EN_COURS_VALIDATION]: 'amber',
    [STATUTS_PARENT.ACTIF]: 'green',
    [STATUTS_PARENT.EN_COURS_MODIFICATION]: 'orange',
    [STATUTS_PARENT.ADHESION_EN_LIGNE]: 'red',
};

export { STATUTS_PARENT_COLORS };