import Vue from 'vue';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import './plugins/vue-currency-filter';
import inputMask from './plugins/v-mask';
import { formatDate } from './config/helpers';
import config from './config';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import 'array-flat-polyfill';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error.isAxiosError) {
        return null;
      }
      return event;
    },
  });
}

Vue.filter('formatDate', formatDate);

import App from './App.vue';
import store from './store';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  inputMask,
  render: h => h(App),
}).$mount('#app');
