import xhr from '../plugins/axios';

export default {
  namespaced: true,
  state: {
    typeAbonnements: [],
    parentAbonnements: [],
    popups: {
      typeChanged: false,
    },

    anneeScolaireId: null,
  },

  mutations: {
    LOAD_PARENT_ABONNEMENTS(state, abonnements) {
      state.parentAbonnements = abonnements;
    },
    DISPLAY_TYPE_CHANGED_WARNING_POPUP(state, toggle) {
      state.popups.typeChanged = toggle;
    },
    LOAD_TYPE_ABONNEMENTS(state, typesAbonnements) {
      state.typeAbonnements = typesAbonnements;
    },
    SET_ANNEE_SCOLAIRE_ID(state, anneeId) {
      state.anneeScolaireId = anneeId;
    },
  },

  actions: {
    loadAbonnementByParentId({ commit }, { parentId }) {
      return xhr.get(`api/admin/parent/${parentId}/abonnement`).then(response => {
        commit('LOAD_PARENT_ABONNEMENTS', response.data);
      });
    },
  
    loadTypeAbonnements({ commit }) {
      return xhr.get('api/admin/type_abonnement').then(response => {
        commit('LOAD_TYPE_ABONNEMENTS', response.data);
      });
    },
  
    subscribeToRevue({ dispatch, state }, { parentId, email, date, revueId }) {
      return xhr
        .post(`api/admin/parent/${parentId}/abonnement`, {
          date, 
          email: email,
          revue: { id: revueId },
          annee_scolaire_id: state.anneeScolaireId,
        }).then(() => {
          dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
          dispatch('loadAbonnementByParentId', { parentId });
        });
    },
  
    activateAbonnement({ dispatch }, { parentId, abonnementId }) {
      return xhr
        .post(`api/admin/parent/${parentId}/abonnement/${abonnementId}/activer`)
        .then(() => {
          dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
          dispatch('loadAbonnementByParentId', { parentId });
        });
    },
    suspendAbonnement({ dispatch }, { parentId, abonnementId }) {
      return xhr
        .post(`api/admin/parent/${parentId}/abonnement/${abonnementId}/suspendre`)
        .then(() => {
          dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
          dispatch('loadAbonnementByParentId', { parentId });
        });
    },
    updateEmailAbonnement({ dispatch }, { parentId, abonnementId, abonnementEmail }) {
      return xhr
        .patch(`api/admin/abonnement/${abonnementId}`, { email: abonnementEmail })
        .then(() => {
          dispatch('loadAbonnementByParentId', { parentId });
        });
    },
    deleteAbonnement({ dispatch }, { parentId, abonnementId }) {
      return xhr
        .delete(`api/admin/parent/${parentId}/abonnement/${abonnementId}`)
        .then(() => {
          dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
          dispatch('loadAbonnementByParentId', { parentId });
        });
    },
  
    displayTypeChangedWarningPopup({ commit }, toggle) {
      commit('DISPLAY_TYPE_CHANGED_WARNING_POPUP', toggle);
    },
  },

  getters: {
    getTypeAbonnements(state) {
      return state.typeAbonnements.filter(ta => ta.annee_scolaire_id === state.anneeScolaireId);
    },
    getParentAbonnements(state) {
      return state.parentAbonnements.filter(a => a.annee_scolaire.id === state.anneeScolaireId);
    },
  
    getDisplayAbonnementChangedTypePopup(state) {
      return state.popups.typeChanged;
    },
  },
};
  