import types from '../actions/types';
import parentStore from '../state/ParentStore';

const parentMutateur = {
  [types.PARENT_FORM_STEP](state) {
    state.step++;
  },
  [types.PARENT_FORM_CHANGE_CONSEIL_LOCAL](state, conseilLocal) {
    state.parent.conseil_local = conseilLocal;
  },
  [types.PARENT_LOAD_ENFANTS](state, enfants) {
    state.enfants = enfants;
  },
  [types.PARENT_ENFANTS_LOADING](state, loading) {
    state.enfants_loading = loading;
  },
  [types.PARENT_LOAD_PARENT](state, parent) {
    state.parent = parent;
  },
  [types.PARENT_PARENT_LOADING](state, loading) {
    state.parent_loading = loading;
  },
  [types.PARENT_PARENT_SAVING](state, loading) {
    state.parent_saving = loading;
  },
  [types.PARENT_UPDATE_PARENT](state, parent) {
    state.parent = parent;
  },
  [types.PARENT_DELETE](state) {
    state.parent = { ...parentStore.parent };
  },
  [types.PARENT_FORM_CHANGE_DEFAULT_ETABLISSEMENTS](state, etablissements) {
    state.defaultEtablissementsScolaires = etablissements;
  },

};

export default parentMutateur;
