<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    persistent
    max-width="1000px"
  >
    <v-card class="pa-5">
      <v-card-title>
        <span class="headline"> Liste des homonymes trouvés </span>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="homonymes"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="primary"
              @click="() => { dialog = false; $emit('modify-homonyme', item.id); }"
            >
              Modifier ce parent
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="primary"
          @click="dialog=false"
        >
          {{ isModification ? 'Continuer la modification' : 'Continuer la création' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ListHomonymesComponent',
  props: {
    homonymes: { type: Array, default: undefined },
    isModification: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Code parent', value: 'code' },
        { text: 'Commune', value: 'cl_nom' },
        { text: 'Code Postal', value: 'cl_code' },
        { text: 'Nom', value: 'nom' },
        { text: 'Prénom', value: 'prenom' },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.$emit('cancel');
      }
    },
    homonymes(val) {
      if (val === undefined || val === null) this.dialog = false;
      else {
        this.dialog = true;
      }
    },
  },
  created() {
  },
  methods: {
  },
};
</script>