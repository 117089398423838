import { uniq } from '../config/helpers.js';
import xhr from '../plugins/axios';

/**
 * This vuex module is empty for now
 * The global store parent (state/mutations/actions/getter) should be 
 * migrated here (TODO Refacto) 
 * After that, it could be renamed parent instead of _parent
 */
export default {
  namespaced: true,
  state: {
    formValid: false,
  },

  mutations: {
    setFormValid(state, valid) {
      state.formValid = valid;
    },
  },

  actions: {
    loadAndGetHomonymes(opts, { cdpeId, excludeParentId, nom, prenom }) {
      return xhr.post('api/admin/parent/homonymes', {
        cdpeId: cdpeId,
        excludeParentId: excludeParentId,
        nom: nom,
        prenom: prenom,
      });
    },
  },

  getters: {
    conseilLocalPrincipal(state, getters, rootState) {
      return rootState.parent.conseil_local;
    },
    conseilsLocauxParAppartenance(state, getters, rootState) {
      return uniq(
        rootState.enfants
          .map(enfant => enfant.classe.etablissement_scolaire.conseil_local)
          .filter(conseil => conseil.id !== getters.conseilLocalPrincipal.id)
        , 'id');
    },
    conseilsLocauxTous(state, getters, rootState) {
      let conseils = rootState.enfants.map(
        enfant => enfant.classe.etablissement_scolaire.conseil_local
      );
      conseils.push(getters.conseilLocalPrincipal);
      return uniq(conseils, 'id');
    },
  },
};
