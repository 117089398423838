import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    communes: [],
  },

  mutations: {
    SET_COMMUNES(state, data) {
      state.communes = data;
    },
  },

  actions: {
    loadCommunes({ commit }, { cdpeId }) {
      return xhr.get(`api/admin/parametrages/commune?cdpe=${cdpeId}`).then(response => {
        commit('SET_COMMUNES', response.data);
      });
    },
  },

  getters: {
    getCommunes(state) {
      return state.communes;
    },
  },
};
