import types from './types';
import xhr from '../plugins/axios.js';
import queryString from 'query-string';

const workflowAction = {
  // Validation Saisies Locales
  loadValidationSaisiesAdhesionLocal(
    { commit },
    { cdpeOrConseilLocalId, cdpeId },
  ) {
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    let parameters = queryString.stringify({
      cdpe_or_cl_id: cdpeOrConseilLocalId,
    });
    return xhr
      .get(
        `api/admin/validation/transfert/adhesion/local/?${parameters}`
      )
      .then(response => {
        commit(
          types.WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_OR_CL_ID,
          cdpeOrConseilLocalId
        );
        commit(types.WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_CDPE_ID, cdpeId);
        commit(
          types.WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_LOAD,
          response.data
        );
      })
      .finally(() => {
        commit(types.WORKFLOW_TOGGLE_LOADING, false);
      });
  },
  validateSaisiesAdhesionLocal({ commit, state }, { parentIds }) {
    let params = {
      cdpe: { id: state.workflow.validation_saisies_adhesion_local.cdpeId },
      parents: parentIds,
    };
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    commit(types.WORKFLOW_VALIDATION_SAISIES_ADHESION_LOCAL_LOAD, []);
    return xhr
      .post('api/admin/validation/saisie/adhesion/local/', params)
      .then((response) => {
        return response.data;
      });
  },

  // Réception Départementale
  loadReceptionAdhesionDepartementLots({ commit }, { cdpeId }) {
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    commit(types.WORKFLOW_RECEPTION_ADHESION_DEPARTEMENT_LOAD, []);
    let parameters = queryString.stringify({
      cdpe_id: cdpeId,
    });

    return xhr
      .get(
        `api/admin/validation/reception/adhesion/departemental/?${parameters}`
      )
      .then(response => {
        commit(
          types.WORKFLOW_RECEPTION_ADHESION_DEPARTEMENT_LOAD,
          response.data.lots
        );
      })
      .finally(() => {
        commit(types.WORKFLOW_TOGGLE_LOADING, false);
      });
  },

  receiveAdhesionDepartementLots({ commit, dispatch }, { lots, cdpeId }) {
    let params = {
      lots,
    };
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    return xhr
      .post('api/admin/validation/reception/adhesion/departemental/', params)
      .then(() => {
        dispatch('loadReceptionAdhesionDepartementLots', {
          cdpeId: cdpeId,
        });
      })
      .finally(() => {
        commit(types.WORKFLOW_TOGGLE_LOADING, false);
      });
  },
  // Transfert Adhésions Départementales
  loadLotsTransfertAdhesionsDepartemental({ commit }, { cdpeId }) {
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    let parameters = queryString.stringify({
      cdpe_id: cdpeId,
    });
    return xhr
      .get(
        `api/admin/validation/transfert/adhesion/departemental/?${parameters}`
      )
      .then(response => {
        commit(
          types.WORKFLOW_TRANSFERT_ADHESION_DEPARTEMENT_LOAD,
          response.data.lots
        );
      })
      .finally(() => commit(types.WORKFLOW_TOGGLE_LOADING, false));
  },
  transfertAdhesionsDepartemental({ commit }, { lots }) {
    let params = {
      lots,
    };
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    return xhr
      .post('api/admin/validation/transfert/adhesion/departemental/', params)
      .then(response => {
        // Don't commit WORKFLOW_TOGGLE_LOADING here, because it's done after in loadLotsTransfertAdhesionsDepartemental
        return response.data;
      })
      .catch(() => commit(types.WORKFLOW_TOGGLE_LOADING, false));
  },

  // Validation Adhésions en ligne
  loadValidationAdhesionsEnLigne({ commit }, { cdpeId, modesPaiement, inclusEnAttente }) {
    let params = {
      cdpe_id: cdpeId,
      types_paiement: modesPaiement,
      inclus_en_attente: inclusEnAttente,
    };
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    return xhr
      .post('api/admin/workflow/adhesionenligne', params)
      .then(response => {
        commit(
          types.WORKFLOW_VALIDATION_ADHESIONS_EN_LIGNE_LOAD,
          response.data
        );
      })
      .finally(() => commit(types.WORKFLOW_TOGGLE_LOADING, false));
  },
  updateConseilLocalParent({ commit }, { parentId, conseilLocalId }) {
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    return xhr
      .put(`api/admin/parent/${parentId}/changecl`, {
        cpe_principal_id: conseilLocalId,
      })
      .finally(() => commit(types.WORKFLOW_TOGGLE_LOADING, false));
  },
  validateAdhesionsEnLigne({ commit }, paiementIds) {
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    return xhr
      .post('api/admin/workflow/adhesionenligne/validation', {
        paiementIds: paiementIds,
      })
      .finally(() => commit(types.WORKFLOW_TOGGLE_LOADING, false));
  },
  cancelAdhesionsEnLigne({ commit }, paiementIds) {
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    return xhr
      .post('api/admin/workflow/adhesionenligne/annulation', {
        paiementIds: paiementIds,
      })
      .finally(() => commit(types.WORKFLOW_TOGGLE_LOADING, false));
  },
  mettreEnAttenteAdhesionsEnLigne({ commit }, paiementIds) {
    commit(types.WORKFLOW_TOGGLE_LOADING, true);
    return xhr
      .post('api/admin/workflow/adhesionenligne/attentepaiement', {
        paiementIds: paiementIds,
      })
      .finally(() => commit(types.WORKFLOW_TOGGLE_LOADING, false));
  },
};

export default workflowAction;
