
export default {
  namespaced: true,
  state: {
    menu: [],
  },

  mutations: {
    setMenu(state, menu) {
      state.menu = menu;
    },
  },

  actions: {},

  getters: {
    getMenuActiveGroup: state => {
      let activeGroup = state.menu.find(group => group.active);
      return activeGroup ? activeGroup : null;
    },
    getMenuActiveSubgroup: state => {
      let activeSubGroup = state.menu
        .flatMap(group => (group.subgroups ? group.subgroups : []))
        .find(subgroup => subgroup.active);
      return activeSubGroup ? activeSubGroup : null;
    },
  },
};
