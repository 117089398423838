export const STATUTS_JURIDIQUE = [
    {
        statut_juridique_id: 1,
        nom: 'Association',
        description: 'Association régie par la loi de 1901',
        position: 2,
    },
    {
        statut_juridique_id: 2,
        nom: 'Inconnu',
        description: '',
        position: 3,
    },
    {
        statut_juridique_id: 3,
        nom: 'Section',
        description: 'Section de votre CDPE',
        position: 1,
    },
];