import xhr from '../../plugins/axios';

const LA_REVUE_DES_PARENTS_ID = 1;

const TYPE_ABONNEMENT_SIMILAIRE_SERVICE_RP = 1;
const TYPE_ABONNEMENT_SIMILAIRE_DIRECT = 2;

const TAILLE_HISTORIQUE_ANNEE_SCOLAIRE = 5;

export default {
  namespaced: true,
  state: {
    niveauxAdhesionNational: [],
    niveauxAdhesionDepartemental: [],
    codesComptables: [],
    codesComptablesAdhesionManuelle: [],
    codesComptablesAdhesionEnLigne: [],
    cdpeId: null,
    unavailableClIds: [],
  },

  mutations: {
    SET_NIVEAUX_ADHESION_NATIONAL(state, data) {
      state.niveauxAdhesionNational = data;
    },
    SET_NIVEAUX_ADHESION_DEPARTEMENTAL(state, data) {
      state.niveauxAdhesionDepartemental = data;
    },
    SET_CODES_COMPTABLES(state, data) {
      state.codesComptables = data;
    },
    SET_CODES_COMPTABLES_ADHESION_MANUELLE(state, data) {
      state.codesComptablesAdhesionManuelle = data;
    },
    SET_CODES_COMPTABLES_ADHESION_EN_LIGNE(state, data) {
      state.codesComptablesAdhesionEnLigne = data;
    },
    SET_CDPE(state, cdpeId) {
      state.cdpeId = cdpeId;
    },
    DELETE_NIVEAU_ADHESION_DEPARTEMENTAL(state, { id }) {
      let annee = state.niveauxAdhesionDepartemental.find(el => el.niveaux.map(it => it.id).includes(id));
      annee.niveaux = annee.niveaux.filter(el => el.id != id);
    },
    DELETE_NIVEAU_ADHESION_SURCHARGE(state, { id }) {
      let pere = state.niveauxAdhesionDepartemental
        .flatMap(an => an.niveaux)
        .find(el => el.surcharges.map(su => su.id).includes(id));

      pere.surcharges = pere.surcharges.filter(el => el.id != id);
    },
    CREATE_NIVEAU_ADHESION_DEPARTEMENTAL(state, niveau) {
      let annee = state.niveauxAdhesionDepartemental.find(el => el.annee_scolaire.id == niveau.annee_scolaire_id);
      annee.niveaux = [...annee.niveaux, niveau];
    },
    CREATE_NIVEAU_ADHESION_SURCHARGE(state, niveau) {
      let pere = state.niveauxAdhesionDepartemental
        .flatMap(an => an.niveaux)
        .find(el => el.id_local == niveau.pere_id);

      pere.surcharges = [...pere.surcharges, niveau];
    },
    CREATE_NIVEAU_ADHESION_NATIONAL(state, { annee_scolaire_id, id, abo_sans_id, abo_avec_id }) {
      let annee = state.niveauxAdhesionNational.find(el => el.annee_scolaire.id == annee_scolaire_id);
      annee.niveau.id = id;
      annee.niveau.type_abonnement_avec_adhesion.id = abo_avec_id;
      annee.niveau.type_abonnement_sans_adhesion.id = abo_sans_id;
    },

    SET_UNAVAILABLE_CL_ID(state, ids) {
      state.unavailableClIds = ids;
    },

  },
  actions: {
    loadNiveauxAdhesionNational({ commit, dispatch, rootGetters }) {
      return dispatch('parametrages/anneeScolaire/loadFilterAnneesScolaires', {}, { root: true })
        .then(() => xhr.get('api/admin/parametrages/niveauadhesion/national'))
        .then(listNivAdNat => xhr.get(`api/admin/parametrages/revue/${LA_REVUE_DES_PARENTS_ID}/typeabonnement`)
          .then(listTypeAbo => {
            let listTypeAboByAS = listTypeAbo.data.reduce((acc, val) => {
              if (val.annee_scolaire_id && val.type_abonnement_similaire_id) {
                if (acc[val.annee_scolaire_id] == undefined) {
                  acc[val.annee_scolaire_id] = [];
                }
                acc[val.annee_scolaire_id][val.type_abonnement_similaire_id] = val;
              }
              return acc;
            }, []);
            commit('SET_NIVEAUX_ADHESION_NATIONAL', rootGetters['parametrages/anneeScolaire/getAnneesScolaires']
              .sort((a, b) => b.id - a.id)
              .slice(0,TAILLE_HISTORIQUE_ANNEE_SCOLAIRE)
              .map(annee => {
                let niv = listNivAdNat.data.find(el => el.annee_scolaire.id === annee.id);
                let nullableNiv = niv === undefined ?
                  {
                    id: null,
                    libelle: null,
                    montant: null,
                    position: null,
                    type_abonnement_avec_adhesion: null,
                    type_abonnement_sans_adhesion: null,

                    show_libelle: '',
                    show_montant: undefined,
                    show_montant_abonnement_avec_adhesion: undefined,
                    show_montant_abonnement_sans_adhesion: undefined,
                  }
                  :
                  {
                    id: niv.id,
                    libelle: niv.libelle,
                    montant: niv.montant,
                    position: niv.position,
                    type_abonnement_avec_adhesion: listTypeAboByAS[annee.id][TYPE_ABONNEMENT_SIMILAIRE_SERVICE_RP],
                    type_abonnement_sans_adhesion: listTypeAboByAS[annee.id][TYPE_ABONNEMENT_SIMILAIRE_DIRECT],
                    // quand on affiche / modif on modifie les champs 'show_'
                    // de cette manière on assure que les vrais champs sont valides
                    show_libelle: niv.libelle,
                    show_montant: niv.montant,
                    show_montant_abonnement_avec_adhesion: listTypeAboByAS[annee.id][TYPE_ABONNEMENT_SIMILAIRE_SERVICE_RP].montant_unitaire,
                    show_montant_abonnement_sans_adhesion: listTypeAboByAS[annee.id][TYPE_ABONNEMENT_SIMILAIRE_DIRECT].montant_unitaire,
                  };
                return {
                  annee_scolaire: annee,
                  niveau: nullableNiv,
                };
              })
            );
          })
        );
    },
    loadNiveauxAdhesionDepartemental({ state, getters, commit, dispatch, rootGetters }, { cdpeId }) {
      commit('SET_CDPE', cdpeId);
      return dispatch('parametrages/anneeScolaire/loadFilterAnneesScolaires', {}, { root: true })
        .then(() => {
          // un utilisateur departemental n'aura pas chargé les données nationales avant d'arrvier ici, 
          // il faut donc les charger.
          // Comme un utilisateur departemental a moins de droits on ne charge que le nécessaire 
          if (state.niveauxAdhesionNational.length == 0) {
            return xhr.get('api/admin/parametrages/niveauadhesion/national')
              .then(listNivAdNat => commit('SET_NIVEAUX_ADHESION_NATIONAL', rootGetters['parametrages/anneeScolaire/getAnneesScolaires']
                .sort((a, b) => b.id - a.id)
                .slice(0,TAILLE_HISTORIQUE_ANNEE_SCOLAIRE)
                .map(annee => {
                  let niv = listNivAdNat.data.find(el => el.annee_scolaire.id === annee.id);
                  let nullableNiv = niv === undefined ? null :
                    {
                      id: niv.id,
                      libelle: niv.libelle,
                      montant: niv.montant,
                      position: niv.position,
                    };
                  return {
                    annee_scolaire: annee,
                    niveau: nullableNiv,
                  };
                })
              ));
          }
          else return Promise.resolve();
        })
        .then(() => dispatch('conseilLocal/loadFilterConseilsLocaux', cdpeId, { root: true }))
        .then(() => xhr.get(`api/admin/parametrages/niveauadhesion/departemental/${cdpeId}`))
        .then(listNivAdDep => xhr.get(`api/admin/parametrages/niveauadhesion/departemental/${cdpeId}/surcharge/`)
          .then(listSurcharge => commit('SET_NIVEAUX_ADHESION_DEPARTEMENTAL', rootGetters['parametrages/anneeScolaire/getAnneesScolaires']
            .sort((a, b) => b.id - a.id)
            .slice(0,TAILLE_HISTORIQUE_ANNEE_SCOLAIRE)
            .map(annee => (
              {
                annee_scolaire: annee,
                niveaux: listNivAdDep.data
                  .filter(niv => niv.annee_scolaire.id === annee.id)
                  .map(niv => {
                    let nat = getters['getNiveauxAdhesionNational'].find(el => el.annee_scolaire.id === annee.id).niveau;
                    let montant_nat = nat === null ? 0 : nat.montant;
                    return {
                      code_comptable: niv.code_comptable[0],
                      id: niv.id,
                      id_local: niv.id_local,
                      libelle: niv.libelle,
                      montant: niv.montant,
                      montant_local: niv.montant_local,
                      montant_national: montant_nat,
                      position: niv.position,
                      position_local: niv.position_local,
                      adhesion_en_ligne: niv.adhesion_en_ligne,
                      surcharges: listSurcharge.data
                        .filter(sur => sur.pere_id === niv.id_local)
                        .map(sur => (
                          {
                            id: sur.id,
                            libelle: sur.libelle,
                            montant_departemental: niv.montant,
                            montant_surcharge: sur.montant,
                            montant_national: montant_nat,
                            pere_id: sur.pere_id,
                            position: sur.position,
                            cl: rootGetters['conseilLocal/getFilterConseilsLocaux'].find(cl => cl.id === sur.cl_id),
                          }
                        )),
                    };
                  }),
              }))
          ))
        );
    },
    loadCodesComptables({ state, commit }) {
      if (state.codesComptables.length > 0)
        return Promise.resolve();
      return xhr.get('api/admin/parametrages/codecomptable/?type=ADHESION_EBP')
        .then(result => commit('SET_CODES_COMPTABLES', result.data));
    },
    loadCodesComptablesAdhesionManuelle({ state, commit }) {
      if (state.codesComptables.length > 0)
        return Promise.resolve();
      return xhr.get('api/admin/parametrages/codecomptable/?type=ADHESION_MANUELLE')
        .then(result => commit('SET_CODES_COMPTABLES_ADHESION_MANUELLE', result.data));
    },
    loadCodesComptablesAdhesionEnLigne({ state, commit }) {
      if (state.codesComptables.length > 0)
        return Promise.resolve();
      return xhr.get('api/admin/parametrages/codecomptable/?type=ADHESION_EN_LIGNE')
        .then(result => commit('SET_CODES_COMPTABLES_ADHESION_EN_LIGNE', result.data));
    },
    updateNiveauAdhesion(opts, { id, code_comptable_id, libelle, montant, position }) {
      return xhr.put(`api/admin/parametrages/niveauadhesion/${id}`,
        {
          code_comptable_id: code_comptable_id,
          libelle: libelle,
          montant: montant,
          position: position,
        });
    },
    updateNiveauAdhesionEtTypeAbonnement({ dispatch }, niveau) {
      return dispatch('updateNiveauAdhesion', niveau)
        .then(() => xhr.put(`api/admin/parametrages/revue/${LA_REVUE_DES_PARENTS_ID}/typeabonnement/${niveau.type_abonnement_avec_adhesion.id}`,
          {
            libelle: niveau.type_abonnement_avec_adhesion.libelle,
            montant_unitaire: niveau.type_abonnement_avec_adhesion.montant_unitaire,
            position: niveau.type_abonnement_avec_adhesion.position,
          }))
        .then(() => xhr.put(`api/admin/parametrages/revue/${LA_REVUE_DES_PARENTS_ID}/typeabonnement/${niveau.type_abonnement_sans_adhesion.id}`,
          {
            libelle: niveau.type_abonnement_sans_adhesion.libelle,
            montant_unitaire: niveau.type_abonnement_sans_adhesion.montant_unitaire,
            position: niveau.type_abonnement_sans_adhesion.position,
          }));
    },
    deleteNiveauAdhesionDepartemental({ commit }, { id }) {
      return xhr.delete(`api/admin/parametrages/niveauadhesion/${id}`)
        .then(() => commit('DELETE_NIVEAU_ADHESION_DEPARTEMENTAL', { id }));
    },
    deleteNiveauAdhesionSurcharge({ commit }, { id }) {
      return xhr.delete(`api/admin/parametrages/niveauadhesion/${id}`)
        .then(() => commit('DELETE_NIVEAU_ADHESION_SURCHARGE', { id }));
    },

    createNiveauAdhesionDepartemental({ state, commit }, niveau) {
      return xhr.post(`api/admin/parametrages/niveauadhesion/departemental/${state.cdpeId}`,
        {
          annee_scolaire_id: niveau.annee_scolaire_id,
          code_comptable_id: niveau.code_comptable.id,
          libelle: niveau.libelle,
          montant: niveau.montant,
          montant_local: niveau.montant_local,
          position: niveau.position,
          position_local: niveau.position_local,
          adhesion_en_ligne: niveau.adhesion_en_ligne,
        })
        .then(response => {
          niveau.id = response.data.id;
          niveau.id_local = response.data.id_local;
          return commit('CREATE_NIVEAU_ADHESION_DEPARTEMENTAL', niveau);
        });
    },
    createNiveauAdhesionSurcharge({ commit }, niveau) {
      return xhr.post(`api/admin/parametrages/niveauadhesion/local/${niveau.cl.id}`,
        {
          libelle: niveau.libelle,
          montant: niveau.montant_surcharge,
          pere_id: niveau.pere_id,
          position: niveau.position,
        })
        .then(response => {
          niveau.id = response.data.id;
          return commit('CREATE_NIVEAU_ADHESION_SURCHARGE', niveau);
        });
    },
    createNiveauAdhesionNationalEtTypeAbonnement({ commit }, { annee_scolaire, niveau }) {
      return xhr.post('api/admin/parametrages/niveauadhesion/national',
        {
          annee_scolaire_id: annee_scolaire.id,
          libelle: niveau.libelle,
          montant: niveau.montant,
          position: niveau.position,
        })
        .then(response_nat => xhr.post(`api/admin/parametrages/revue/${LA_REVUE_DES_PARENTS_ID}/typeabonnement`,
          {
            annee_scolaire_id: annee_scolaire.id,
            libelle: niveau.type_abonnement_avec_adhesion.libelle,
            montant_unitaire: niveau.type_abonnement_avec_adhesion.montant_unitaire,
            position: niveau.type_abonnement_avec_adhesion.position,
            type_abonnement_similaire_id: TYPE_ABONNEMENT_SIMILAIRE_SERVICE_RP,
          })
          .then(response_avec => xhr.post(`api/admin/parametrages/revue/${LA_REVUE_DES_PARENTS_ID}/typeabonnement`,
            {
              annee_scolaire_id: annee_scolaire.id,
              libelle: niveau.type_abonnement_sans_adhesion.libelle,
              montant_unitaire: niveau.type_abonnement_sans_adhesion.montant_unitaire,
              position: niveau.type_abonnement_sans_adhesion.position,
              type_abonnement_similaire_id: TYPE_ABONNEMENT_SIMILAIRE_DIRECT,
            })
            .then(response_sans => commit('CREATE_NIVEAU_ADHESION_NATIONAL', {
              annee_scolaire_id: annee_scolaire.id,
              id: response_nat.data.id,
              abo_sans_id: response_sans.data.id,
              abo_avec_id: response_avec.data.id,
            }))
          )
        );
    },

  },
  getters: {
    getNiveauxAdhesionNational(state) {
      return state.niveauxAdhesionNational;
    },
    getNiveauxAdhesionDepartemental(state) {
      return state.niveauxAdhesionDepartemental;
    },
    getCodesComptables(state) {
      return state.codesComptables;
    },
    getCodesComptablesAdhesionManuelle(state) {
      return state.codesComptablesAdhesionManuelle;
    },
    getCodesComptablesAdhesionEnLigne(state) {
      return state.codesComptablesAdhesionEnLigne;
    },
    getCdpeId(state) {
      return state.cdpeId;
    },
    getUnavailableClIds(state) {
      return state.unavailableClIds;
    },
  },
};