import xhr from '../../plugins/axios.js';

export default {
  namespaced: true,
  state: {
    rolesByPerimetreId: {},
    loading: false,
    currentDepartement: null,
  },
  mutations: {
    setLoading(state, toggle) {
      state.loading = toggle;
    },
    setRolesbyPerimetreId(state, roles) {
      state.rolesByPerimetreId = roles;
    },
    setCurrentDepartement(state, perimetre) {
      state.currentDepartement = perimetre;
    },
  },
  actions: {
    toggleLoading({ commit }, { stateLoading }) {
      commit('setLoading', stateLoading);
    },
    loadRolesByPerimetreId({ commit }, PerimetreId) {
      if (PerimetreId === 0 || !PerimetreId) {
        commit('setRolesbyPerimetreId', []);
        return;
      }
      commit('setLoading', true);
      return xhr
        .get(`api/admin/parametrages/role/?perimetreId=${PerimetreId}`)
        .then((response) => {
          commit('setRolesbyPerimetreId', response.data);
          commit('setLoading', false);
        })
        .catch((error) => {
          commit('setRolesbyPerimetreId', []);
          commit('setLoading', false);
          throw error;
        });
    },
    setCurrentDepartement({ commit }, curentCpde) {
      commit('setCurrentDepartement', curentCpde);
    },
    createRole({ dispatch, commit }, { roleToCreate, perimetreId }) {
      commit('setLoading', true);
      return xhr.post('api/admin/parametrages/role', roleToCreate).then(() => {
        dispatch('loadRolesByPerimetreId', perimetreId);
      });
    },
    deleteRole({ dispatch, commit }, { roleToDelete, perimetreId }) {
      commit('setLoading', true);
      return xhr
        .delete(`api/admin/parametrages/role/${roleToDelete}`)
        .then(() => {
          dispatch('loadRolesByPerimetreId', perimetreId);
        });
    },
  },
  getters: {
  },
};
