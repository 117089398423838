 export const ARBO_DROITS = {
          general: {
            label: 'Général',
            icon: 'mdi-format-list-bulleted-square',
            perimetre: {
              label: 'Départements / Conseil Locaux',
              recherche: {
                droit: 'PERIMETRE_RECHERCHE',
                label: 'Visualisation',
              },
              creation: {
                droit: 'PERIMETRE_CREATION',
                label: 'Création',
              },
              modification: {
                droit: 'PERIMETRE_MODIFICATION',
                label: 'Modification',
              },
              suppression: {
                droit: 'PERIMETRE_SUPPRESSION',
                label: 'Suppression',
              },
            },
            parent: {
              label: 'Parents',
              recherche: {
                droit: 'FOYER_RECHERCHE',
                label: 'Visualisation',
              },
              recufiscal: {
                droit: 'RECU_FISCAL',
                label: 'Reçu fiscal',
              },
              tableauxBord: {
                droit: 'TABLEAUX_BORD',
                label: 'Tableaux de bord',
              },
              creation: {
                droit: 'FOYER_CREATION',
                label: 'Création',
              },
              modification: {
                droit: 'FOYER_MODIFICATION',
                label: 'Modification',
              },
              suppression: {
                droit: 'FOYER_SUPPRESSION',
                label: 'Suppression',
              },
              responsabiliteNationale: {
                droit: 'AFFECTATION_RESPONSABILITE_NATIONALE',
                label: 'Affectation des responsabilités nationales',
              },
              responsabiliteDepartementale: {
                droit: 'AFFECTATION_RESPONSABILITE_DEPARTEMENTALE',
                label: 'Affectation des responsabilités départementales',
              },
              responsabiliteLocale: {
                droit: 'AFFECTATION_RESPONSABILITE_LOCALE',
                label: 'Affectation des responsabilités locales',
              },
              delegationDepartementale: {
                droit: 'AFFECTATION_DELEGATION_DEPARTEMENTALE',
                label: 'Affectation des délégations départementales',
              },
              delegationEtablissement: {
                droit: 'AFFECTATION_DELEGATION_ETABLISSEMENT',
                label: 'Affectation des délégations établissement',
              },
              validationDons: {
                droit: 'FOYER_VALIDATION_DONS',
                label: 'Validation des dons',
              },
              validationImportBL: {
                droit: 'FOYER_VALIDATION_IMPORT_BL',
                label: 'Validation import bourse aux livres',
              },
              validationAdhesionEnLigne: {
                droit: 'FOYER_VALIDATION_ADHESIONS_EN_LIGNE',
                label: 'Validation adhésions en ligne',
              },
              validationCreationLocale: {
                droit: 'FOYER_VALIDATION_CREATION_LOCALE',
                label: "Validation des saisies d'adhésions",
              },
              validationReceptionConseilLocal: {
                droit: 'FOYER_VALIDATION_RECEPTION_CONSEIL_LOCAL',
                label: 'Réception départementale des adhésions',
              },
              validationSaisieCdpe: {
                droit: 'FOYER_VALIDATION_SAISIE_CDPE',
                label: 'Transfert des adhésions / abonnements vers le national',
              },
              validationReceptionCdpe: {
                droit: 'FOYER_VALIDATION_RECEPTION_CDPE',
                label: 'Validation nationale',
              },
            },
            etablissementScolaire: {
              label: 'Etablissements scolaires',
              recherche: {
                droit: 'ETABLISSEMENT_RECHERCHE',
                label: 'Visualisation',
              },
              rechercheExport: {
                droit: 'ETABLISSEMENT_RECHERCHE_EXPORT',
                label: 'Export',
              },
              creation: {
                droit: 'ETABLISSEMENT_CREATION',
                label: 'Création',
              },
              modification: {
                droit: 'ETABLISSEMENT_MODIFICATION',
                label: 'Modification',
              },
              suppression: {
                droit: 'ETABLISSEMENT_SUPPRESSION',
                label: 'Suppression',
              },
            },
          },
          donnees: {
            label: 'Données',
            icon: 'mdi-database-edit',
            requetes: {
              label: 'Requêtes multi-critères',
              simpleExecution: {
                droit: 'RMC_SIMPLE_EXECUTION',
                label: "Exécution d'une requête simple",
              },
              simpleCreation: {
                droit: 'RMC_SIMPLE_CREATION',
                label: "Création d'une requête simple",
              },
              simpleImpression: {
                droit: 'RMC_SIMPLE_IMPRESSION',
                label: "Impression des résultats d'une requête simple",
              },
              simpleExport: {
                droit: 'RMC_SIMPLE_EXPORT',
                label: "Export des résultats d'une requête simple",
              },
              experteExecution: {
                droit: 'RMC_EXPERTE_EXECUTION',
                label: "Exécution d'une requête experte",
              },
              experteCreation: {
                droit: 'RMC_EXPERTE_CREATION',
                label: "Création d'une requête experte",
              },
              experteImpression: {
                droit: 'RMC_EXPERTE_IMPRESSION',
                label: "Impression des résultats d'une requête experte",
              },
              experteExport: {
                droit: 'RMC_EXPERTE_EXPORT',
                label: "Export des résultats d'une requête experte",
              },
              publicationUnNiveau: {
                droit: 'RMC_PUBLICATION_UN_NIVEAU',
                label: 'Publication à mon niveau',
              },
              publicationPlusieursNiveaux: {
                droit: 'RMC_PUBLICATION_PLUSIEURS_NIVEAUX',
                label: 'Publication à mon niveau et les niveaux inférieurs',
              },
            },
            export: {
              label: 'Imports / Exports',
              importBourse: {
                droit: 'IMPORT_BOURSE',
                label: 'Import bourse aux livres',
              },
              communes: {
                droit: 'EXPORT_COMMUNES',
                label: 'Export des communes',
              },
              ebp: {
                droit: 'EXPORT_EBP',
                label: 'Export vers EBP',
              },
              comptable: {
                droit: 'EXPORT_COMPTABLE',
                label: 'Export comptable',
              },
              routage: {
                droit: 'EXPORT_ROUTAGE',
                label: 'Export routage',
              },
              bourse: {
                droit: 'EXPORT_BOURSE',
                label: 'Export bourse aux livres',
              },
              comptables: {
                droit: 'EXPORT_COMPTABLES',
                label: 'Exports comptables',
              },
              statistique: {
                droit: 'EXPORT_STATISTIQUE',
                label: 'Export statistiques',
              },
            },
            impressions: {
              label: 'Impressions prédéfinies',
              adherentParCpePrincipal: {
                droit: 'IMPRESSION_ADHERENT_PAR_CPE_PRINCIPAL',
                label: 'Adhérents par CL principal',
              },
              adherentParCpeAppartenance: {
                droit: 'IMPRESSION_ADHERENT_PAR_CPE_APPARTENANCE',
                label: "Adhérents par Conseil Local d'appartenance",
              },
              adherentsParEtablissementClasse: {
                droit: 'IMPRESSION_ADHERENTS_PAR_ETABLISSEMENT_CLASSE',
                label: 'Adhérents par établissement et classe',
              },
              abonnesParCpePrincipal: {
                droit: 'IMPRESSION_ABONNES_PAR_CPE_PRINCIPAL',
                label: 'Abonnés par conseil local principal',
              },
              abonnesActifsRevueDepartementale: {
                droit: 'IMPRESSION_ABONNES_ACTIFS_REVUE_DEPARTEMENTALE',
                label: 'Abonnés actifs à la revue départementale',
              },
              anciensAdherents: {
                droit: 'IMPRESSION_ANCIENS_ADHERENTS',
                label: 'Anciens adhérents',
              },
              responsablesDepartementaux: {
                droit: 'IMPRESSION_RESPONSABLES_DEPARTEMENTAUX',
                label: 'Responsables départementaux',
              },
              responsablesCpe: {
                droit: 'IMPRESSION_RESPONSABLES_CPE',
                label: 'Responsables conseil locaux',
              },
              cpe: {
                droit: 'IMPRESSION_CPE',
                label: 'Conseils locaux',
              },
              extractionEmailNewsletter: {
                droit: 'IMPRESSION_EXTRACTION_EMAIL_NEWSLETTER',
                label: 'Extraction emails des parents inscrits à la newsletter',
              },
              ficheSignaletiqueCpe: {
                droit: 'IMPRESSION_FICHE_SIGNALETIQUE_CPE',
                label: 'Fiche signalétique conseils locaux',
              },
              rapprochementComptableGlobal: {
                droit: 'IMPRESSION_RAPPROCHEMENT_COMPTABLE_GLOBAL',
                label: 'Rapprochement comptable global',
              },
              rapprochementComptableAdhesionsParCpe: {
                droit: 'IMPRESSION_RAPPROCHEMENT_COMPTABLE_ADHESIONS_PAR_CPE',
                label:
                  'Rapprochement comptable des adhésions par conseil local et par niveau',
              },
              rapprochementComptableAbonnementsParCpe: {
                droit: 'IMPRESSION_RAPPROCHEMENT_COMPTABLE_ABONNEMENTS_PAR_CPE',
                label:
                  'Rapprochement comptable des abonnements par conseil local',
              },
              rapprochementComptableDonsParCpe: {
                droit: 'IMPRESSION_RAPPROCHEMENT_COMPTABLE_DONS_PAR_CPE',
                label: 'Rapprochement comptable des dons par conseil local',
              },
              saisiesDesFoyers: {
                droit: 'IMPRESSION_SAISIES_DES_FOYERS',
                label: 'Saisies des parents',
              },
              suiviStatistique: {
                droit: 'IMPRESSION_SUIVI_STATISTIQUE',
                label: 'Suivi statistique',
              },
              suiviStatistiqueN1: {
                droit: 'IMPRESSION_SUIVI_STATISTIQUE_N_1',
                label: 'Impression du suivi des statistiques année n-1',
              },
              suiviStatistiqueN2: {
                droit: 'IMPRESSION_SUIVI_STATISTIQUE_N_2',
                label: 'Impression du suivi des statistiques année n-2',
              },
              foyersValidesParDepartement: {
                droit: 'IMPRESSION_FOYERS_VALIDES_PAR_DEPARTEMENT',
                label: 'Adhérents et abonnées validés par département',
              },
              foyersNonValidesParDepartement: {
                droit: 'IMPRESSION_FOYERS_NONVALIDES_PAR_DEPARTEMENT',
                label: 'Adhérents et abonnées non validés par département',
              },
              extractionRecusDons: {
                droit: 'IMPRESSION_EXTRACTION_RECUS_DONS',
                label: 'Extraction des reçus fiscaux des parents',
              },
              cdpe: {
                droit: 'IMPRESSION_CDPE',
                label:
                  'Fiche conseil départemental, Liste des CDPE, des présidents et des trésoriers',
              },
              adhesionsEnLigne: {
                droit: 'IMPRESSION_ADHESIONS_EN_LIGNE',
                label: 'Adhésions en ligne',
              },
              suiviAdhesionsEnLigne: {
                droit: 'IMPRESSION_SUIVI_ADHESIONS_EN_LIGNE',
                label: 'Suivi adhésion en ligne par Conseil Local',
              },
              adhesionsEnLigneMisEnAttente: {
                droit: 'IMPRESSION_ADHESIONS_EN_LIGNE_MISE_EN_ATTENTE',
                label: 'Adhésions en ligne mises en attente',
              },
              adhesionsAbonnementsCl: {
                droit: 'IMPRESSION_ADHESIONS_ABONNEMENTS_CL',
                label: 'Informations complètes adhérents',
              },
              recusFiscaux: {
                droit: 'IMPRESSION_RECUS_FISCAUX',
                label: 'Informations reçus fiscaux par département',
              },
            },
          },
          parametrage: {
            label: 'Paramétrage',
            icon: 'mdi-web',
            general: {
              label: 'Général',
              anneeScolaire: {
                droit: 'PARAMETRAGE_ANNEE_SCOLAIRE',
                label: 'Année scolaire',
              },
              signataire: {
                droit: 'PARAMETRAGE_SIGNATAIRE',
                label: 'Signataire',
              },
              commune: {
                droit: 'PARAMETRAGE_COMMUNE',
                label: 'Communes',
              },
              secteurGeographique: {
                droit: 'PARAMETRAGE_SECTEUR_GEOGRAPHIQUE',
                label: 'Secteurs géogrphiques',
              },
              degreEnseignement: {
                droit: 'PARAMETRAGE_DEGRE_ENSEIGNEMENT',
                label: "Degrés d'enseignements",
              },
              niveauEnseignement: {
                droit: 'PARAMETRAGE_NIVEAU_ENSEIGNEMENT',
                label: "Niveaux d'enseignements",
              },
              classe: {
                droit: 'PARAMETRAGE_CLASSE',
                label: 'Classes',
              },
              civilite: {
                droit: 'PARAMETRAGE_CIVILITE',
                label: 'Civilités',
              },
              apiWeb: {
                droit: 'API_WEB',
                label: 'authentification API Web',
              },
            },
            adhesionAbonnement: {
              label: 'Adhésions et Abonnements',
              niveauAdhesion: {
                droit: 'PARAMETRAGE_NIVEAU_ADHESION',
                label: "Niveaux d'adhésion",
              },
              revueTypeAbonnement: {
                droit: 'PARAMETRAGE_REVUE_TYPE_ABONNEMENT',
                label: "Revue et type d'abonnement",
              },
              codeComptable: {
                droit: 'PARAMETRAGE_CODE_COMPTABLE',
                label: 'Codes compatbles',
              },
            },
            responsabilitesDelegations: {
              label: 'Responsabilités et Délégations',
              responsabiliteDepartementale: {
                droit: 'PARAMETRAGE_RESPONSABILITE_DEPARTEMENTALE',
                label: 'Responsabilités départementales',
              },
              delegationDepartementale: {
                droit: 'PARAMETRAGE_DELEGATION_DEPARTEMENTALE',
                label: 'Délégations départementales',
              },
              responsabiliteLocale: {
                droit: 'PARAMETRAGE_RESPONSABILITE_LOCALE',
                label: 'Responsabilités locales',
              },
              delegationEtablissement: {
                droit: 'PARAMETRAGE_DELEGATION_ETABLISSEMENT',
                label: "Délégations d'établissements",
              },
            },
            rolesUtilisateurs: {
              label: 'Rôles / Utilisateurs',
              role: {
                droit: 'PARAMETRAGE_ROLE',
                label: 'Rôles',
              },
              utilisateur: {
                droit: 'PARAMETRAGE_UTILISATEUR',
                label: 'Utilisateurs',
              },
            },
            statistiques: {
              label: 'Statistiques',
              statistiques: {
                droit: 'STATISTIQUE',
                label: 'Statistiques',
              },
            },
          },
          gestionnaires: {
            label: 'Sites FCPE',
            icon: 'mdi-settings',
            intranet: {
              label: 'Intranet',
              access: {
                droit: 'INTRANET',
                label: 'Accès intranet',
              },
              baseDocumentaire: {
                droit: 'GESTIONNAIRE_BASE_DOCUMENTAIRE',
                label: 'Gestionnaire base documentaire',
              },
              congres: {
                droit: 'GESTIONNAIRE_CONGRES',
                label: 'Gestionnaire congrès',
              },
              accessDepartementalCongres: {
                droit: 'ACCES_DEPARTEMENTAL_CONGRES',
                label: 'Accès module congrès',
              },
              evenements: {
                droit: 'GESTIONNAIRE_EVENEMENTS',
                label: 'Gestionnaire événements et formations',
              },
              fdva: {
                droit: 'GESTIONNAIRE_FDVA',
                label:
                  'Gestionnaire FDVA (Fond de développement de la vie associative)',
              },
              accessDepartementalFdva: {
                droit: 'ACCES_DEPARTEMENTAL_FDVA',
                label:
                  'Accès module FDVA (Fond de développement de la vie associative)',
              },
              ndf: {
                droit: 'GESTIONNAIRE_NDF',
                label: 'Gestionnaire notes de frais',
              },
            },
            ouyapacours: {
              label: 'Ouyapacours',
              national: {
                droit: 'GESTIONNAIRE_OUYAPACOURS',
                label: 'Gestionnaire national',
              },
              departemental: {
                droit: 'GESTIONNAIRE_OUYAPACOURS_DEPARTEMENTAL',
                label: 'Gestionnaire départemental',
              },
            },
            formation: {
              label: 'J\'aime mon école publique',
              access: {
                droit: 'ACCES_FORMATION',
                label: 'Accès Formation',
              },
            },
            notilus: {
              label: 'Notes de frais',
              access: {
                droit: 'ACCES_NOTILUS',
                label: 'Accès notes de frais',
              },
            },
            emailing: {
              label: 'Emailing',
              access: {
                droit: 'ACCES_EMAILING',
                label: 'Accès Emailing',
              },
            },
          },
        };
 
export default {
  ACCES_PERIMETRE_NATIONAL: 'ACCES_PERIMETRE_NATIONAL',
  ACCES_PERIMETRE_DEPARTEMENTAL: 'ACCES_PERIMETRE_DEPARTEMENTAL',
  ACCES_PERIMETRE_LOCAL: 'ACCES_PERIMETRE_LOCAL',
  AFFECTATION_DELEGATION_DEPARTEMENTALE:
    ARBO_DROITS.general.parent.delegationDepartementale.droit,
  AFFECTATION_DELEGATION_ETABLISSEMENT:
    ARBO_DROITS.general.parent.delegationEtablissement.droit,
  AFFECTATION_RESPONSABILITE_DEPARTEMENTALE:
    ARBO_DROITS.general.parent.responsabiliteDepartementale.droit,
  AFFECTATION_RESPONSABILITE_LOCALE:
    ARBO_DROITS.general.parent.responsabiliteLocale.droit,
  AFFECTATION_RESPONSABILITE_NATIONALE:
    ARBO_DROITS.general.parent.responsabiliteNationale.droit,
  API_WEB: ARBO_DROITS.parametrage.general.apiWeb.droit,
  ETABLISSEMENT_CREATION:
    ARBO_DROITS.general.etablissementScolaire.creation.droit,
  ETABLISSEMENT_MODIFICATION:
    ARBO_DROITS.general.etablissementScolaire.modification.droit,
  ETABLISSEMENT_RECHERCHE_EXPORT:
    ARBO_DROITS.general.etablissementScolaire.rechercheExport.droit,
  ETABLISSEMENT_RECHERCHE:
    ARBO_DROITS.general.etablissementScolaire.recherche.droit,
  ETABLISSEMENT_SUPPRESSION:
    ARBO_DROITS.general.etablissementScolaire.suppression.droit,
  EXPORT_BOURSE: ARBO_DROITS.donnees.export.bourse.droit,
  EXPORT_COMMUNES: ARBO_DROITS.donnees.export.communes.droit,
  EXPORT_COMPTABLES: ARBO_DROITS.donnees.export.comptables.droit,
  EXPORT_EBP: ARBO_DROITS.donnees.export.ebp.droit,
  EXPORT_ROUTAGE: ARBO_DROITS.donnees.export.routage.droit,
  EXPORT_COMPTABLE: ARBO_DROITS.donnees.export.comptable.droit,
  EXPORT_STATISTIQUE: ARBO_DROITS.donnees.export.statistique.droit,
  FOYER_CREATION: ARBO_DROITS.general.parent.creation.droit,
  FOYER_MODIFICATION: ARBO_DROITS.general.parent.modification.droit,
  FOYER_RECHERCHE: ARBO_DROITS.general.parent.recherche.droit,
  FOYER_SUPPRESSION: ARBO_DROITS.general.parent.suppression.droit,
  FOYER_VALIDATION_ADHESIONS_EN_LIGNE:
    ARBO_DROITS.general.parent.validationAdhesionEnLigne.droit,
  FOYER_VALIDATION_CREATION_LOCALE:
    ARBO_DROITS.general.parent.validationCreationLocale.droit,
  FOYER_VALIDATION_DONS: ARBO_DROITS.general.parent.validationDons.droit,
  FOYER_VALIDATION_IMPORT_BL:
    ARBO_DROITS.general.parent.validationImportBL.droit,
  FOYER_VALIDATION_RECEPTION_CDPE:
    ARBO_DROITS.general.parent.validationReceptionCdpe.droit,
  FOYER_VALIDATION_RECEPTION_CONSEIL_LOCAL:
    ARBO_DROITS.general.parent.validationReceptionConseilLocal.droit,
  FOYER_VALIDATION_SAISIE_CDPE:
    ARBO_DROITS.general.parent.validationSaisieCdpe.droit,
  IMPORT_BOURSE: ARBO_DROITS.donnees.export.importBourse.droit,
  // IMPRESSION_ABONNES_ACTIFS_REVUE_DEPARTEMENTALE: ARBO_DROITS.donnees.impressions.abonnesActifsRevueDepartementale.droit,
  IMPRESSION_ABONNES_PAR_CPE_PRINCIPAL:
    ARBO_DROITS.donnees.impressions.abonnesParCpePrincipal.droit,
  IMPRESSION_ADHERENT_PAR_CPE_APPARTENANCE:
    ARBO_DROITS.donnees.impressions.adherentParCpeAppartenance.droit,
  IMPRESSION_ADHERENT_PAR_CPE_PRINCIPAL:
    ARBO_DROITS.donnees.impressions.adherentParCpePrincipal.droit,
  IMPRESSION_ADHERENTS_PAR_ETABLISSEMENT_CLASSE:
    ARBO_DROITS.donnees.impressions.adherentsParEtablissementClasse.droit,
  IMPRESSION_ADHESIONS_EN_LIGNE_MISE_EN_ATTENTE:
    ARBO_DROITS.donnees.impressions.adhesionsEnLigneMisEnAttente.droit,
  IMPRESSION_ADHESIONS_EN_LIGNE:
    ARBO_DROITS.donnees.impressions.adhesionsEnLigne.droit,
  IMPRESSION_ANCIENS_ADHERENTS:
    ARBO_DROITS.donnees.impressions.anciensAdherents.droit,
  IMPRESSION_CDPE: ARBO_DROITS.donnees.impressions.cdpe.droit,
  IMPRESSION_CPE: ARBO_DROITS.donnees.impressions.cpe.droit,
  // IMPRESSION_DELEGUES_DEPARTEMENTAUX: ARBO_DROITS.donnees.impressions.deleguesDepartementaux.droit,
  // IMPRESSION_DELEGUES_ETABLISSEMENT: ARBO_DROITS.donnees.impressions.deleguesEtablissement.droit,
  IMPRESSION_EXTRACTION_EMAIL_NEWSLETTER:
    ARBO_DROITS.donnees.impressions.extractionEmailNewsletter.droit,
  IMPRESSION_EXTRACTION_RECUS_DONS:
    ARBO_DROITS.donnees.impressions.extractionRecusDons.droit,
  IMPRESSION_FICHE_SIGNALETIQUE_CPE:
    ARBO_DROITS.donnees.impressions.ficheSignaletiqueCpe.droit,
  IMPRESSION_FOYERS_NONVALIDES_PAR_DEPARTEMENT:
    ARBO_DROITS.donnees.impressions.foyersNonValidesParDepartement.droit,
  IMPRESSION_FOYERS_VALIDES_PAR_DEPARTEMENT:
    ARBO_DROITS.donnees.impressions.foyersValidesParDepartement.droit,
  IMPRESSION_RAPPROCHEMENT_COMPTABLE_ABONNEMENTS_PAR_CPE:
    ARBO_DROITS.donnees.impressions.rapprochementComptableAbonnementsParCpe
      .droit,
  IMPRESSION_RAPPROCHEMENT_COMPTABLE_ADHESIONS_PAR_CPE:
    ARBO_DROITS.donnees.impressions.rapprochementComptableAdhesionsParCpe.droit,
  IMPRESSION_RAPPROCHEMENT_COMPTABLE_DONS_PAR_CPE:
    ARBO_DROITS.donnees.impressions.rapprochementComptableDonsParCpe.droit,
  IMPRESSION_RAPPROCHEMENT_COMPTABLE_GLOBAL:
    ARBO_DROITS.donnees.impressions.rapprochementComptableGlobal.droit,
  IMPRESSION_RESPONSABLES_CPE:
    ARBO_DROITS.donnees.impressions.responsablesCpe.droit,
  IMPRESSION_RESPONSABLES_DEPARTEMENTAUX:
    ARBO_DROITS.donnees.impressions.responsablesDepartementaux.droit,
  IMPRESSION_SAISIES_DES_FOYERS:
    ARBO_DROITS.donnees.impressions.saisiesDesFoyers.droit,
  IMPRESSION_SUIVI_ADHESIONS_EN_LIGNE:
    ARBO_DROITS.donnees.impressions.suiviAdhesionsEnLigne.droit,
  IMPRESSION_SUIVI_STATISTIQUE_N_1:
    ARBO_DROITS.donnees.impressions.suiviStatistiqueN1.droit,
  IMPRESSION_SUIVI_STATISTIQUE_N_2:
    ARBO_DROITS.donnees.impressions.suiviStatistiqueN2.droit,
  IMPRESSION_SUIVI_STATISTIQUE:
    ARBO_DROITS.donnees.impressions.suiviStatistique.droit,
  IMPRESSION_ADHESIONS_ABONNEMENTS_CL:
    ARBO_DROITS.donnees.impressions.adhesionsAbonnementsCl.droit,
  IMPRESSION_RECUS_FISCAUX:
    ARBO_DROITS.donnees.impressions.recusFiscaux.droit,
  PARAMETRAGE_ANNEE_SCOLAIRE:
    ARBO_DROITS.parametrage.general.anneeScolaire.droit,
  PARAMETRAGE_SIGNATAIRE:
    ARBO_DROITS.parametrage.general.signataire.droit,
  PARAMETRAGE_CIVILITE: ARBO_DROITS.parametrage.general.civilite.droit,
  PARAMETRAGE_CLASSE: ARBO_DROITS.parametrage.general.classe.droit,
  PARAMETRAGE_CODE_COMPTABLE:
    ARBO_DROITS.parametrage.adhesionAbonnement.codeComptable.droit,
  PARAMETRAGE_COMMUNE: ARBO_DROITS.parametrage.general.commune.droit,
  PARAMETRAGE_DEGRE_ENSEIGNEMENT:
    ARBO_DROITS.parametrage.general.degreEnseignement.droit,
  PARAMETRAGE_DELEGATION_DEPARTEMENTALE:
    ARBO_DROITS.parametrage.responsabilitesDelegations.delegationDepartementale
      .droit,
  PARAMETRAGE_DELEGATION_ETABLISSEMENT:
    ARBO_DROITS.parametrage.responsabilitesDelegations.delegationEtablissement
      .droit,
  PARAMETRAGE_ROLE: ARBO_DROITS.parametrage.rolesUtilisateurs.role.droit,
  PARAMETRAGE_NIVEAU_ADHESION:
    ARBO_DROITS.parametrage.adhesionAbonnement.niveauAdhesion.droit,
  PARAMETRAGE_NIVEAU_ENSEIGNEMENT:
    ARBO_DROITS.parametrage.general.niveauEnseignement.droit,
  PARAMETRAGE_RESPONSABILITE_DEPARTEMENTALE:
    ARBO_DROITS.parametrage.responsabilitesDelegations
      .responsabiliteDepartementale.droit,
  PARAMETRAGE_RESPONSABILITE_LOCALE:
    ARBO_DROITS.parametrage.responsabilitesDelegations.responsabiliteLocale
      .droit,
  PARAMETRAGE_REVUE_TYPE_ABONNEMENT:
    ARBO_DROITS.parametrage.adhesionAbonnement.revueTypeAbonnement.droit,
  PARAMETRAGE_SECTEUR_GEOGRAPHIQUE:
    ARBO_DROITS.parametrage.general.secteurGeographique.droit,
  PARAMETRAGE_UTILISATEUR:
    ARBO_DROITS.parametrage.rolesUtilisateurs.utilisateur.droit,
  PERIMETRE_CREATION: ARBO_DROITS.general.perimetre.creation.droit,
  PERIMETRE_MODIFICATION: ARBO_DROITS.general.perimetre.modification.droit,
  PERIMETRE_RECHERCHE: ARBO_DROITS.general.perimetre.recherche.droit,
  PERIMETRE_SUPPRESSION: ARBO_DROITS.general.perimetre.suppression.droit,
  RECU_FISCAL: ARBO_DROITS.general.parent.recufiscal.droit,
  RMC_EXPERTE_CREATION: ARBO_DROITS.donnees.requetes.experteCreation.droit,
  RMC_EXPERTE_EXECUTION: ARBO_DROITS.donnees.requetes.experteExecution.droit,
  RMC_EXPERTE_EXPORT: ARBO_DROITS.donnees.requetes.experteExport.droit,
  RMC_EXPERTE_IMPRESSION: ARBO_DROITS.donnees.requetes.experteImpression.droit,
  RMC_PUBLICATION_PLUSIEURS_NIVEAUX:
    ARBO_DROITS.donnees.requetes.publicationUnNiveau.droit,
  RMC_PUBLICATION_UN_NIVEAU:
    ARBO_DROITS.donnees.requetes.publicationPlusieursNiveaux.droit,
  RMC_SIMPLE_CREATION: ARBO_DROITS.donnees.requetes.simpleCreation.droit,
  RMC_SIMPLE_EXECUTION: ARBO_DROITS.donnees.requetes.simpleExecution.droit,
  RMC_SIMPLE_EXPORT: ARBO_DROITS.donnees.requetes.simpleExport.droit,
  RMC_SIMPLE_IMPRESSION: ARBO_DROITS.donnees.requetes.simpleImpression.droit,
  STATISTIQUE: ARBO_DROITS.parametrage.statistiques.statistiques.droit,
  TABLEAUX_BORD: ARBO_DROITS.general.parent.tableauxBord.droit,
  INTRANET: ARBO_DROITS.gestionnaires.intranet.access.droit,
  GESTIONNAIRE_BASE_DOCUMENTAIRE:
    ARBO_DROITS.gestionnaires.intranet.baseDocumentaire.droit,
  GESTIONNAIRE_CONGRES: ARBO_DROITS.gestionnaires.intranet.congres.droit,
  ACCES_DEPARTEMENTAL_CONGRES:
    ARBO_DROITS.gestionnaires.intranet.accessDepartementalCongres.droit,
  GESTIONNAIRE_EVENEMENTS: ARBO_DROITS.gestionnaires.intranet.evenements.droit,
  GESTIONNAIRE_FDVA: ARBO_DROITS.gestionnaires.intranet.fdva.droit,
  ACCES_DEPARTEMENTAL_FDVA:
    ARBO_DROITS.gestionnaires.intranet.accessDepartementalFdva.droit,
  GESTIONNAIRE_NDF: ARBO_DROITS.gestionnaires.intranet.ndf.droit,
  GESTIONNAIRE_OUYAPACOURS:
    ARBO_DROITS.gestionnaires.ouyapacours.national.droit,
  GESTIONNAIRE_OUYAPACOURS_DEPARTEMENTAL:
    ARBO_DROITS.gestionnaires.ouyapacours.departemental.droit,
  ACCES_FORMATION: ARBO_DROITS.gestionnaires.formation.droit,
  ACCES_NOTILUS: ARBO_DROITS.gestionnaires.notilus.droit,
  ACCES_EMAILING: ARBO_DROITS.gestionnaires.emailing.droit,
};