import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    loading: false,
    exportsComptables: [],
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setExportsComptables(state, exportsComptables) {
      state.exportsComptables = exportsComptables;
    },
  },

  actions: {
    loadExportsComptablesNationaux({ commit }, { anneeScolaire }) {
      commit('setLoading', true);
      return xhr
        .get(`api/admin/exportCompta/${anneeScolaire}/NAT`)
        .then(response => {
          commit('setExportsComptables', response.data.files);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    loadExportsComptablesDepartementaux(
      { commit },
      { anneeScolaire, cdpeCode }
    ) {
      commit('setLoading', true);
      return xhr
        .get(`api/admin/exportCompta/${anneeScolaire}/${cdpeCode}`)
        .then(response => {
          commit('setExportsComptables', response.data.files);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    loadExportsComptablesLocaux(
      { commit },
      { anneeScolaire, cdpeCode, conseilLocalCode }
    ) {
      commit('setLoading', true);
      return xhr
        .get(
          `api/admin/exportCompta/${anneeScolaire}/${cdpeCode}/${conseilLocalCode}`
        )
        .then(response => {
          commit('setExportsComptables', response.data.files);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
  },

  getters: {},
};
