import parentStore from './ParentStore';
import workflowStore from './WorkflowStore';

/**
 * HACK : for reseting state, we need a copy of the states not modified,
 * need to export a function which copies (JSON.parse(JSON.stringify)
 */
export function initialState () {
  return JSON.parse(JSON.stringify({
  ...parentStore,
  ...workflowStore,
  }));
}

const store = initialState;

export default store;

