<template>
  <Recherche />
</template>

<script>
import Recherche from '../components/parent/recherche/Recherche';

export default {
  components: {
    Recherche,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
