<template>
  <div class="pa-5">
    <form
      ref="form"
      @submit.prevent="beforeSendParts"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          Année scolaire {{ partNationale == undefined ? '' : partNationale.annee_scolaire.libelle }}
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <DatePicker
            label="Date d'adhésion"
            :disabled="!dateAdhesionIsEnable()"
            :date="dateAdhesion"
            :required="true"
            :between="{ 
              after: partNationale == undefined ? null : partNationale.annee_scolaire.dateDebutSaisie, 
              before: partNationale == undefined ? null : partNationale.annee_scolaire.dateFinSaisie, 
              inclusion: '[]'
            }"
            @changeDate="changeDateAdhesion"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="niveauAdhesionDepartemental"
            v-validate="'required'"
            required
            label="Choisir un niveau"
            no-data-text="Aucun niveau de disponible"
            item-text="libelle"
            :items="niveauxAdhesionDepartementaux"
            item-disabled="adhesion_en_ligne"
            :loading="loadingNiveauxDepartemental"
            data-vv-name="niveau"
            :error-messages="errors.collect('niveau')"
            return-object
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <PaiementSelect
            :loaded-moyen-paiement="loadedMoyenPaiement"
            @moyenPaiementSelected="updateMoyenPaiement"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <p class="body-1">
            Part Nationale
          </p>Montant:
          <span class="price">{{ partNationale == undefined ? 0 : partNationale.tarif | currency }}</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <p class="body-1">
            Part Départementale
          </p>Montant:
          <span class="price">{{ tarifPartDepartementale | currency }}</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            type="submit"
            :disabled="!canAddPart"
            color="primary"
          >
            <v-icon>
              {{ adhesionButtonIcon }}
            </v-icon>{{ isAdherent ?
              'Modifier':
              'Ajouter'
            }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
    <ConfirmedModal
      v-if="shouldConfirmAdhesionModification"
      sentence="La modification de niveau de la part implique une modification du montant de l'adhésion"
      btn-action="J'accepte"
      @close="cancelAdhesionModification()"
      @confirmed="confirmAdhesionModification()"
    />
    <v-dialog
      v-model="displayAbonnementChangedTypePopup"
      max-width="300"
      :persistent="true"
    >
      <v-card>
        <div class="pa-5">
          <v-card-title class="headline info">
            Info abonnement
          </v-card-title>
          <v-card-text>La création d'une adhésion a changé le type d'abonnement, et donc son tarif.</v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click.stop="displayTypeChangedWarningPopup(false)"
            >
              Compris
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from '../../partials/DatePicker';
import PaiementSelect from '../../partials/paiement/select';
import { mapActions, mapGetters } from 'vuex';
import { getDefaultMoyenPaiement } from '../../../config/moyenPaiement';
import ConfirmedModal from './../../reusableComponents/confirmedModal';
import Vue from 'vue';

export default {
  name: 'AdhesionInfo',
  components: {
    DatePicker,
    PaiementSelect,
    ConfirmedModal,
  },
  data: () => ({
    dep: null,
    niveauAdhesionDepartemental: null,
    enable: false,
    montantDP: null,
    shouldConfirmAdhesionModification: false,
    isSavingPartsAdhesion: false,
    loadedMoyenPaiement: getDefaultMoyenPaiement(),
    dateAdhesion: null,
  }),

  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
    ...mapGetters('adhesion', {
      niveauxAdhesionDepartementaux: 'getNiveauxDEP',
      partNationale: 'getPartNAT',
      loadingNiveauxDepartemental: 'getNiveauxDepartementalLoading',
      adhesion: 'getAdhesion',
    }),
    ...mapGetters('abonnement', {
      parentAbonnements: 'getParentAbonnements',
      displayAbonnementChangedTypePopup: 'getDisplayAbonnementChangedTypePopup',
    }),
    isAdherent() {
      return this.adhesion != undefined;
    },
    tarifPartDepartementale() {
      return this.niveauAdhesionDepartemental ? this.niveauAdhesionDepartemental.tarif : 0;
    },

    canAddPart() {
      if (this.isSavingPartsAdhesion) {
        return false;
      }
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },

    adhesionButtonIcon() {
      if (this.isSavingPartsAdhesion) {
        return 'mdi-loading';
      }
      return this.isAdherent ? 'edit':'add';
    },
  },
  watch: {
    niveauxAdhesionDepartementaux: {
      immediate: true,
      handler(val) {
        if (val.length) {
          this.setSelectedNiveauAdhesionDepartemental();
        }
      },
    },
    adhesion: {
      immediate: true,
      handler(val) {
        if(!val) {
          this.dateAdhesion = new Date().toISOString().substring(0,10);
        } else {
          this.dateAdhesion = val.date;
        }

        if (!val || !val.parts.departemental || !val.parts.departemental.niveau) {
          this.niveauAdhesionDepartemental = null;
          Vue.nextTick(() => {
            Vue.nextTick(() => {
              this.errors.clear(); // vee-validate reset
            });
          });
        } else {
          this.setSelectedNiveauAdhesionDepartemental();
        }

        if (!val || !val.parts.departemental || !val.parts.departemental.moyen_paiement) {
          this.loadedMoyenPaiement = null;
          Vue.nextTick(() => {
            Vue.nextTick(() => {
              this.errors.clear(); // vee-validate reset
            });
          });
        } else {
          this.loadedMoyenPaiement = val.parts.departemental.moyen_paiement;
        }

      },

    },
  },
  created() {
    this.loadPartNational();
  },
  methods: {
    leave() {},
    ...mapActions('adhesion', [
      'loadPartNational',
      'sendParts',
    ]),
    ...mapActions('abonnement', [
      'displayTypeChangedWarningPopup',
    ]),
    updateMoyenPaiement(moyenPaiement) {
      this.moyenPaiement = moyenPaiement;
    },
    setSelectedNiveauAdhesionDepartemental() {
      let niveauAdhesionDepartemental = this.niveauxAdhesionDepartementaux.find((niveau) => {
        if (!this.adhesion || !this.adhesion.parts.departemental || !this.adhesion.parts.departemental.niveau) {
          return false;
        }
        return niveau.id === this.adhesion.parts.departemental.niveau.id;
      });

      if (!niveauAdhesionDepartemental) {
        return;
      }
      this.niveauAdhesionDepartemental = niveauAdhesionDepartemental;
    },

    beforeSendParts() {
      this.isSavingPartsAdhesion = true;
      if (this.isAdherent) {
        if(this.adhesion.parts.departemental.niveau.id !== this.niveauAdhesionDepartemental.id) {
          this.shouldConfirmAdhesionModification = true;
        } else {
          this.validate();
        }
      } else {
        this.validate();
      }
    },

    confirmAdhesionModification() {
      this.shouldConfirmAdhesionModification = false;
      this.validate();
    },

    cancelAdhesionModification() {
      this.shouldConfirmAdhesionModification = false;
      this.isSavingPartsAdhesion = false;
    },

    validate() {
      // Creates a copy of parts that is not reactive
      let parts;
      if(this.isAdherent) {
        parts = JSON.parse(JSON.stringify(this.adhesion.parts));
      } else {
        parts = { departemental: { niveau: {} }, local: [], national: true };
      }
      parts.departemental = {
        niveau: {
          id: this.niveauAdhesionDepartemental.id,
        },
        moyen_paiement: this.moyenPaiement,
      };

      let adhesionWasAdherent = true;
      if (!this.isAdherent) {
        parts.national = true;
        adhesionWasAdherent = false;
      }

      this.sendParts({
        parentId: this.parent.id,
        parts,
        date: this.dateAdhesion,
        isAdherent: this.isAdherent,
      }).then(() => {
        this.isSavingPartsAdhesion = false;
        if (!adhesionWasAdherent && this.parentAbonnements.length) {
          return this.displayTypeChangedWarningPopup(true);
        }
      });

    },

    changeDateAdhesion(date) {
      this.dateAdhesion = date;
    },

    dateAdhesionIsEnable() {
      const date = new Date();

      // Possibilité de modifié la date d'adhésion que sur les adhésions saisie (non en ligne)
      const isAdhesionEnLigne = this.adhesion ? this.niveauxAdhesionDepartementaux.some((niveau) => (niveau.id === this.adhesion.parts.departemental.niveau.id && niveau.adhesion_en_ligne)) : false;

      // Possibilité de modifié la date d'adhésion que sur la période [DEBUT_ANNEE_SCOLAIRE - 01/04/XX à 00:00]
      const isAuthorizedToUpdateDate = this.partNationale ? (new Date(this.partNationale.annee_scolaire.dateDebutSaisie) < date) && ((new Date(date.getFullYear(), 3, 1)) > date) : true;
      return !isAdhesionEnLigne && isAuthorizedToUpdateDate;
    },
  },
};
</script>
