const parentStore = {
  step: 1,
  parent: {
    adresse: {
      commune: {
        code_postal: '',
        id: null,
        ville: '',
      },
      ligne1: '',
      ligne2: '',
    },
    code: '',
    commentaire: '',
    conseil_local: {
      commune: {
        code_postal: '',
        id: null,
        ville: '',
      },
      id: null,
    },
    contact: {
      abonne_newsletter: false,
      email: '',
      email2: '',
      telephone_fixe: '',
      telephone_mobile: '',
    },
    id: null,
    personne: {
      civilite: 7,
      nom: '',
      prenom: '',
    },
    statut: {
      id: 0,
      libelle: '',
    },
  }, // edit
  parent_loading: false,
  parent_saving: false,
  enfants: [], // edit
  enfants_loading: false, 
  defaultEtablissementsScolaires: [],
};

export default parentStore;
