<template>
  <v-select
    v-model="responsabiliteSelected"
    no-data-text="Aucune responsabilité de choisie"
    :items="responsabilitesWithAdditionalOption"
    item-text="nom"
    item-value="id"
    :label="label"
    :loading="loading"
    required
    return-object
    single-line
    :clearable="!withNoneOption"
    @input="changed"
  >
    <template v-slot:selection="{ item, selected }">
      {{ item.nom }}
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.nom }}
          <span
            v-if="displayResponsables && item.responsables && item.is_responsabilite_unique === true && item.responsables.length > 0"
          >({{ item.responsables[0].nom }} {{ item.responsables[0].prenom }})</span>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>

export default {
  name: 'ResponsabilitesAutocomplete',
  props: {
    displayResponsables: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default() {
        return 'Selectionner une responsabilité *';
      },
    },
    perimetreId: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'local',
    },
    responsabilites: {
      type: Array,
      default(){
        return [];
      },
    },
    currentParentId: {
      type: Number,
      default: null,
    },
    currentResponsabiliteId: {
      type: Number,
      default: null,
    },
    withNoneOption: {
      type: Boolean,
      default: false,
    },
    withAllOption: {
      type: Boolean,
      default: false,
    },
    fakeReload: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    responsabiliteSelected: {
      nom: 'Aucune',
      id: 0,
    },
    lastResponsabilite: null,
  }),
  computed: {
    loading(){
      return !this.responsabilites.length;
    },
    responsabilitesWithAdditionalOption() {
      let responsabilites =  [
          ...this.responsabilites,
        ];
      if (this.withAllOption) {
        responsabilites =  [
          { id: -1, nom: 'Toutes les responsabilités', responsables: [] },
          ...responsabilites,
        ];
      }
      if (this.withNoneOption) {
        responsabilites =  [
          { id: 0, nom: 'Aucune', responsables: [] },
          ...responsabilites,
        ];
      }
      return responsabilites;
    },
  },
  watch: {
    currentParentId() {
      this.initResponsabiliteSelected();
    },
    currentResponsabiliteId() {
      this.initResponsabiliteSelected();
    },
    responsabilites() {
      this.initResponsabiliteSelected();
    },
    fakeReload() {
      this.initResponsabiliteSelected();
    },
  },
  methods: {
    initResponsabiliteSelected() {
      this.responsabiliteSelected = this.lastResponsabilite =
          this.responsabilitesWithAdditionalOption.find((resp) => (this.hasCurrentThisResponsabilite(resp)), null);

      if (!this.responsabiliteSelected && this.withNoneOption) {
        this.responsabiliteSelected = this.lastResponsabilite =  { id: 0, nom: 'Aucune', responsables: [] };
      }    
    },
    hasCurrentThisResponsabilite(resp) {
      if (this.currentResponsabiliteId) {
        return resp.id === this.currentResponsabiliteId;
      }
      
      if (!resp.responsables) {
        return false;
      }

      return resp.responsables.find((responsable) => (responsable.parent_id === this.currentParentId), false);
    },
    changed() {
      this.$emit('changedResponsabilite', {
        responsabilite: this.responsabiliteSelected,
        lastResponsabilite: this.lastResponsabilite,
        perimetreId: this.perimetreId,
        type: this.type,
      });
      this.lastResponsabilite = this.responsabiliteSelected;
    },
  },
};
</script>
