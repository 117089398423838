import xhr from '../../plugins/axios';
import queryString from 'query-string';

export default {
  namespaced: true,
  state: {
    dons: [],
    cdpeId: 0,
    loading: false,
  },
  mutations: {
    setCdpeId (state, id) {
      state.cdpeId = id;
    },
    setLoading (state, loading) {
      state.loading = loading;
    },
    setDons (state, dons) {
      state.dons = dons;
    },
  },
  actions: {
    loadValidationDons ({ commit }, { cdpeId }) {
      commit ('setCdpeId', cdpeId);
      commit ('setLoading', true);
      let parameters = queryString.stringify ({
        cdpe_id: cdpeId,
      });
      return xhr
        .get (`api/admin/validation/saisie/don/departemental?${parameters}`)
        .then (response => {
          commit ('setDons', response.data);
          commit ('setLoading', false);
        })
        .finally (() => {
          commit( 'setLoading', false );
        });
    },

    sendValidationDons ({ commit }, { donsId }) {
      commit ('setLoading', true);
      let params = {
        dons: donsId,
      };
      commit ('setDons', []);
      return xhr
        .post ('api/admin/validation/saisie/don/departemental', params)
        .then (response => {
          commit ('setLoading', false);
          return response.data;
        }).finally(() =>
        {
          commit( 'setLoading', false );
        });
    },
  },
  getters: {
    getValidationDons (state) {
      const donsFlat = [];
      state.dons.forEach (parent => {
        parent.dons.departemental.forEach(don =>
          donsFlat.push({
            key: don.don_id,
            id: don.don_id,
            parentId: parent.parent_id,
            nom: parent.nom,
            prenom: parent.prenom,
            cpe_principal: parent.cpe_principal.nom,
            statut_juridique: parent.cpe_principal.statut_juridique.nom,
            ...don,
          }),
        );
        }
      );
      return donsFlat;
    },
  },
};
