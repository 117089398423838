<template>
  <div>
    <DonForm />
    <div class="pa-5">
      <v-card flat>
        <h3 class="title">
          Liste des dons
        </h3>
        <DonListe :dons="dons" />
      </v-card>
    </div>
  </div>
</template>
<script>
import DonForm from './Form';
import DonListe from './Liste';
import { mapGetters } from 'vuex';

export default {
  name: 'DonLayout',
  components: {
    DonForm,
    DonListe,
  },
  computed: {
    ...mapGetters('don', {
      dons: 'getDons',
    }),
  },
};
</script>