/**
 * Camelcase chaine
 */
export function camelcase(s) {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
}
/**
 * Format date in french
 * @param {*} inputDate
 */
export function formatDate(inputDate) {
  if (!inputDate) {
    return '';
  }
  let date = new Date(inputDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return day + '/' + month + '/' + year;
}

/**
 * Get uniq value of array
 * @param {*} arrArg
 * @param {*} criteria
 */
export function uniq(tab, criteria) {
  const seen = {};
  return tab.filter(item =>
    seen.hasOwnProperty(item[criteria]) ? false : (seen[item[criteria]] = true)
  );
}

/**
 * Remove all dots from a string
 * @param {*} number
 */
export function removeDots(number) {
  return number.replace(/\./g, '');
}

export function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
