const DEFAULT_ENFANT_DATA = {
  classe: {
    etablissement_scolaire: {
      commune: {
        code_postal: '',
        id: null,
        ville: '',
      },
      conseil_local: {
        commune: {
          code_postal: '',
          id: null,
          ville: '',
        },
        id: null,
        nom: '',
      },
      id: null,
      nom: '',
    },
    type: {
      id: null,
      nom: '',
    },
  },
  date_de_naissance: '',
  id: null,
  nom: '',
  prenom: '',
  section: '',
};

export default DEFAULT_ENFANT_DATA;