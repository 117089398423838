import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    historique: {
      id: null,
      adhesions: [],
      abonnements: [],
      dons_departementaux: [],
      dons_locaux: [],
    },
    loading: false,
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setHistorique(state, historique) {
      state.historique = historique;
    },
  },

  actions: {
    loadHistorique({ commit, state }, { parentId }) {
      if (state.historique.id === parentId) {
        return;
      }
      commit('setLoading', true);
      return xhr
        .get(`api/admin/parent/${parentId}/historicalInfo`)
        .then(response => {
          commit('setHistorique', response.data);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
  },

  getters: {
    getHistoriqueOrderedByAnneeScolaire(state) {
      
      let annees = [];
      state.historique.adhesions.forEach(adhesion => {
        let key = adhesion.annee_scolaire.id.toString(); 
        if (annees[key] === undefined) {
          annees[key] = {
            ...adhesion.annee_scolaire,
            adhesion,
          };
        } else {
          annees[key].adhesion = adhesion;
        }
      });
      state.historique.abonnements.forEach(abonnement => {
        let key = abonnement.annee_scolaire.id.toString(); 
        if (annees[key] === undefined) {
          annees[key] = {
            ...abonnement.annee_scolaire,
            abonnements: [],
          };
        } 
        if (annees[key].abonnements === undefined) {
          annees[key].abonnements = [];
        }
        annees[key].abonnements.push(abonnement);
      });
      state.historique.dons_departementaux.forEach(don_departemental => {
        let key = don_departemental.annee_scolaire.id.toString();
        if (annees[key] === undefined) {
          annees[key] = {
            ...don_departemental.annee_scolaire,
            dons: [],
          };
        }
        if (annees[key].dons === undefined) {
          annees[key].dons = [];
        }
        annees[key].dons.push(don_departemental);
      });
      state.historique.dons_locaux.forEach(don_local => {
        let key = don_local.annee_scolaire.id.toString();
        if (annees[key] === undefined) {
          annees[key] = {
            ...don_local.annee_scolaire,
            dons: [],
          };
        }
        if (annees[key].dons === undefined) {
          annees[key].dons = [];
        }
        annees[key].dons.push(don_local);
      });
      annees =  annees
          .sort((anneeA, anneeB) => anneeA.libelle < anneeB.libelle)
          .filter((annee) => annee !== undefined);
      return annees;
    },
  },
};
