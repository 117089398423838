import types from '../actions/types';
import { initialState } from '../state';

const resetMutateur = {
  [types.RESET_STATE](state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
};

export default resetMutateur;
