<template>
  <v-navigation-drawer
    permanent
    width="320"
  >
    <v-list
      id="menu"
      dense
      class="pt-0"
    >
      <v-list-group
        v-for="item in authorizedItems"
        :key="item.title"
        :prepend-icon="item.icon"
        :value="item.active"
      >
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-group
          v-for="subgroup in item.subgroups"
          :key="subgroup.title"
          sub-group
          :value="subgroup.active"
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title>{{ subgroup.title }}</v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subitem in subgroup.subitems"
            :key="subitem.title"
            :to="subitem.link"
          >
            <v-list-item-title>{{ subitem.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-for="subitem in item.subitems"
          :key="subitem.title"
          :to="subitem.link"
        >
          <v-list-item-title>{{ subitem.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider />

      <div class="contact">
        <h3>
          <v-icon>email</v-icon>Contact
        </h3>
        <span>
          <a href="mailto:informatique@fcpe.asso.fr">informatique@fcpe.asso.fr</a>
        </span>
        <span>
          <a
            href="/pdf/FCPE_charte_utilisation_plateforme_fcpe_adhesions.pdf"
            target="_blank"
          >Télécharger la charte</a>
        </span>
        <span>
          Utilisateur connecté : {{ user.prenom }} {{ user.nom }}
        </span>
        <span>
          {{ perimetreUser.code }} {{ perimetreUser.nom }}
        </span>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>


<style lang="scss" scoped>
@import '../../styles/components/Menu.scss';
</style>
<script>
import { mapGetters } from 'vuex';
import { isRouteAuthorized } from '../../router.js';

export default {
  name: 'Menu',
  components: {},
  data() {
    return {
      items: [
        {
          title: 'Département / Conseils locaux',
          icon: 'home',
          subitems: [
            {
              title: 'Rechercher un CDPE',
              route: 'recherche-cdpe',
            },
            {
              title: 'Editer mon CDPE',
              route: 'editer-mon-cdpe',
            },
            {
              title: 'Rechercher un Conseil local',
              route: 'recherche-conseil-local',
            },
            {
              title: 'Créer un Conseil local',
              route: 'creation-conseil-local',
            },
            {
              title: 'Editer mon Conseil local',
              route: 'editer-mon-conseil-local',
            },
            {
              title: 'Voir mon Conseil Local',
              route: 'voir-mon-conseil-local',
            },
          ],
        },
        {
          title: 'Adhesions / Abonnements',
          icon: 'people',
          subgroups: [
            {
              title: 'Parents',
              subitems: [
                {
                  title: 'Recherche parents / adhérents',
                  route: 'recherche-parents',
                },
                {
                  title: 'Créer une fiche parent',
                  route: 'creation-parent',
                },
              ],
            },          
            {
              title: 'Validations',
              subitems: [
                {
                  title: 'Validation dons',
                  route: 'validation-dons',
                },
                {
                  title: 'Validation import bourse aux livres',
                  route: 'validation-import-bourse-livres',
                },
                {
                  title: 'Réception des adhésions en ligne',
                  route: 'reception-adhesion-en-ligne',
                },
                {
                  title: "Validation des saisies d'adhésions",
                  route: 'validation-saisies-adhesion',
                },
                {
                  title: 'Réception départementale des adhésions',
                  route: 'reception-adhesion-departement',
                },
                {
                  title: 'Transfert des adhésions / abonnements vers le national',
                  route: 'transfert-adhesions-departemental',
                },
                {
                  title: 'Validation nationale',
                  route: 'validation-adhesions-national',
                },
              ],
            },          
          ],
        },
        {
          title: 'Etablissements scolaires',
          icon: 'school',
          subitems: [
            {
              title: 'Recherche un établissement',
              route: 'recherche-etablissement',
            },
            {
              title: 'Créer un établissement',
              route: 'creation-etablissement',
            },
          ],
        },
        {
          title: 'Recherches / Impressions',
          icon: 'print',
          subgroups: [
            {
              title: 'Impressions',
              subitems: [
                {
                  title: 'Impressions prédéfinies',
                  route: 'impressions-predefinies',
                },
              ],
            },
            {
              title: 'Import / Export',
              subitems: [
                {
                  title: 'Export routage',
                  route: 'export-routage',
                },
                {
                  title: 'Export bourse aux livres',
                  route: 'export-bourse-aux-livres',
                },
                {
                  title: 'Import bourse aux livres',
                  route: 'import-bourse-aux-livres',
                },
                {
                  title: 'Exports comptables',
                  route: 'exports-comptables',
                },
              ],
            },
          ],
        },
        {
          title: 'Parametrages',
          icon: 'settings',
          subgroups: [
            {
              title: 'Général',
              subitems: [
                {
                  title: 'Année scolaire',
                  route: 'annee-scolaire',
                },
                {
                  title: 'Communes',
                  route: 'communes',
                },
                {
                  title: 'Secteur géographique',
                  route: 'secteur-geographique',
                },
                {
                  title: 'Degrés enseignement',
                  route: 'degres-enseignement',
                },
                {
                  title: 'Niveau enseignement',
                  route: 'niveau-enseignement',
                },
                {
                  title: 'Classes',
                  route: 'classes',
                },
              ],
            },
            {
              title: 'Adhésions et Abonnements',
              subitems: [
                {
                  title: "Niveaux d'adhésion/abonnement",
                  route: 'niveaux-adhesion-abonnement',
                },
                {
                  title: 'Codes comptables',
                  route: 'codes-comptables',
                },
              ],
            },
            {
              title: 'Responsabilités et Délégations',
              subitems: [
                {
                  title: 'Responsabilités départementales',
                  route: 'responsabilites-departementales',
                },
                {
                  title: 'Délégations départementales',
                  route: 'delegations-departementales',
                },
                {
                  title: 'Responsabilités Conseil local',
                  route: 'responsabilites-locales',
                },
                {
                  title: "Délégations d'établissement",
                  route: 'delegations-etablissement',
                },
              ],
            },
            {
              title: 'Rôles et Utilisateurs',
              subitems: [
                {
                  title: 'Utilisateurs',
                  route: 'utilisateurs',
                },
                {
                  title: 'Rôles',
                  route: 'roles',
                },
              ],
            },
            {
              title: 'Statistiques',
              subitems: [
                {
                  title: 'Statistiques',
                  route: 'statistiques',
                },
              ],
            },
          ],
        },
      ],
      authorizedItems: [],
      right: null,
    };
  },
  computed: {
    ...mapGetters('session', {
      user: 'getUser',
      perimetreUser: 'getPerimetreUser',
    }),
  },
  watch: {
    '$route': function() {
      this.initAuthorizedItems();
    },
  },
  created() {
    this.initAuthorizedItems();
  },
  methods: {
    initAuthorizedItems() {

      let menu = this.items.reduce((menu, item) =>  {

        if (item.subitems) {

          let submenu = this.authorizedSubItems(item.subitems);

          // On filtre les items pour ne garder que ceux qui ont au moins un subitem autorisé 
          if (submenu.length > 0) {
            menu.push({ ...item, subitems: submenu, active: this.isActiveGroup(submenu) });
          }

        } 
        
        if (item.subgroups) {

          let submenu = item.subgroups.reduce((submenu, subgroup) => {

              let subsubmenu = this.authorizedSubItems(subgroup.subitems);

              // On filtre les subgroup pour ne garder que ceux qui ont au moins un subitem autorisé 
              if (subsubmenu.length > 0) {
                submenu.push({ ...subgroup, subitems: subsubmenu, active: this.isActiveGroup(subsubmenu) });
              }

              return submenu;

          }, []);  

          // On filtre les items pour ne garder que ceux qui ont au moins un subgroup 
          if (submenu.length > 0) {
            menu.push({ ...item, subgroups: submenu, active: this.isActiveGroup(submenu) });
          }

        }

        return menu; 
      }, []);

      this.$store.commit('navigation/setMenu', menu);
      this.authorizedItems = menu;
    },
    authorizedSubItems(subitems) {

        // On filtre les subitems pour ne garder que les autorisés 
        // et on résoud la route en un lien 
        return subitems.reduce((submenu, subitem) => {
          
          let routeLink = this.$router.resolve({ name: subitem.route });
          if (isRouteAuthorized(routeLink.route)) {
            submenu.push({ ...subitem, link: routeLink.href, active: this.isActiveItem(subitem) });
          }

          return submenu;
        }, []);  

    },
    isActiveGroup(items) {
      return items.reduce((active, item) => (active || item.active), false);
    },
    isActiveItem(item) {
      return item.route === this.$route.name || item.route === this.$route.meta.menu_active_route; 
    },
  },
};
</script>
