<template>
  <div>
    <v-data-table
      dense
      no-data-text="Pas de dons"
      :hide-default-footer="true"
      :headers="headers"
      item-key="id"
      :items="dons"
    >
      <template v-slot:item="props">
        <tr>
          <td v-if="hasActions">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  more_vert
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="editDon(props.item.id)">
                  <v-list-item-title>Modifier</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list>
                <v-list-item @click="confirmDeleteDon(props.item)">
                  <v-list-item-title>Supprimer</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td>{{ props.item.is_local ? props.item.cl.nom : 'Conseil départemental' }}</td>
          <td>{{ props.item.date | formatDate }}</td>
          <td>{{ getMoyenPaiementById(props.item.moyen_paiement).libelle }}</td>
          <td>{{ props.item.montant | currency }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDeleteDonDialog"
      max-width="300"
    >
      <v-card>
        <div class="pa-5">
          <v-card-text v-if="donToDelete">
            Voulez-vous vraiment supprimer le don de {{ donToDelete.montant | currency }} à {{ donToDelete.is_local ? donToDelete.nom : 'Conseil départemental' }} datant du {{ donToDelete.date | formatDate }} ?
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              color="secondary"
              text
              @click="cancelDelete()"
            >
              Abandonner
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteDon()"
            >
              Supprimer
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getMoyenPaiementById } from '../../../config/moyenPaiement';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DonListe',
  props: {
    hasActions: {
      type: Boolean,
      default: true,
    },
    dons: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    donToDelete: null,
    showDeleteDonDialog: false,
  }),

  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
    headers() {
      let headers = [
        { text: 'Destinataire', value: 'destinataire', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Moyen de paiement', value: 'moyen de paiement', sortable: false },
        { text: 'Montant', value: 'montant', sortable: false },
      ];
      if (this.hasActions) {
        headers.unshift({ text: 'Action', value: 'action', sortable: false });
      }

      return headers;
    },
  },

  methods: {
    ...mapActions('don', [
      'deleteDonLocalByIdAndParentId',
      'deleteDonDepartementalByIdAndParentId',
    ]),

    getMoyenPaiementById,

    editDon(donId) {
      this.$router.push({
        name: 'edition-don',
        params: {
          parentId: this.parent.id,
          donId,
        },
      });
    },
    confirmDeleteDon(don) {
      this.showDeleteDonDialog=true;
      this.donToDelete=don;
    },
    deleteDon() {

      let deleteMethod = this.donToDelete.is_local ?
          this.deleteDonLocalByIdAndParentId :
          this.deleteDonDepartementalByIdAndParentId ;

      deleteMethod( { donId: this.donToDelete.id, parentId : this.parent.id })
      .then(() => {
         this.cancelDelete();
      });
    },
    cancelDelete() {
      this.donToDelete=null;
      this.showDeleteDonDialog=false; 
    },

  },
};
</script>