<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
  >
    <v-card>
      <div class="pa-5">
        <v-card-text>{{ sentence }}</v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="close()"
          >
            {{ btnCancel }}
          </v-btn>
          <v-btn
            text
            @click="confirmed()"
          >
            {{ btnAction }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmedModal',
  props: {
    sentence: {
      type: String,
      required: true,
    },
    btnAction: {
      type: String,
      required: true,
    },
    btnCancel: {
      type: String,
      default: () => 'Annuler',
    },
  },
  data: () => ({
    dialog: true,
  }),
  watch: {
    dialog(val) {
      if(!val) this.close();
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirmed() {
      this.$emit('confirmed');
    },
  },
};
</script>
