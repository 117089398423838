<template>
  <v-autocomplete
    v-model="selectedCommune"
    v-validate="`${required ? 'required|propertyRequired:id': ''}`"
    append-icon="location_on"
    :search-input.sync="searching"
    :loading="isLoading"
    :filter="customFilter"
    :label="formattedLabel"
    hide-no-data
    hide-selected
    :disabled="disabled"
    :items="communes"
    :auto-select-first="true"
    :clearable="true"
    :dense="true"
    :data-vv-name="fieldName"
    :error-messages="errors.collect(fieldName)"
    @input="changed"
  >
    <template v-slot:selection="{ item }">
      {{ item && item.id ? item.code_postal + ' - ' + item.ville : '' }}
    </template>
    <template v-slot:item="{ item }">
      {{ item && item.id ? item.code_postal + ' - ' + item.ville : '' }}
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex';
import queryString from 'query-string';
import _debounce from 'lodash/debounce';

export default {
  name: 'CommuneAutocomplete',

  props: {
    loadedCommune: {
      type: Object,
      default() {
        return {};
      },
    },
    perimetreIdFilter: {
      type: Number,
      default: 0,
    },
    havingAtLeastOneEtablissementFilter: {
      type: Boolean,
      default: false,
    },

    fieldName: {
      type: String,
      default: 'Code Postal / Commune',
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    searching: '',
    isLoading: false,
    communes: [],
    selectedCommune: null,
  }),


  /**
   * This component should never have a specific validator instance
   * We inject the parent validator
   */
  inject: [
    '$validator',
  ],



  computed: {
    formattedLabel() {
      return `${this.fieldName}${this.required ? ' *': ''}`;
    },
  },

  watch: {
    loadedCommune: {
      handler(val) {
        if (val && val.id) {
          this.communes = [val];
          this.selectedCommune = val;
        } else {
          this.communes = [];
          this.selectedCommune = null;
        }
      },
      immediate: true, 
    },
    searching(val) {
      return this.doGetCommunes(val);
    },
  },
  created() {
    this.doGetCommunes = _debounce((query) => {
      if (query && query.length >= 2) {
        let parameters = {
          perimetreIdFilter: this.perimetreIdFilter,
          havingAtLeastOneEtablissementFilter: this.havingAtLeastOneEtablissementFilter,
        };

        parameters = queryString.stringify(parameters);

        this.isLoading = true;
        this.searchCommunes({
          query,
          parameters,
        })
            .then(response => {
              this.communes = response.data.map(commune => {
                return {
                  ...commune,
                  ville: commune.nom,
                };
              });
            })
            .finally(() => (this.isLoading = false));
      }
    }, 250);
  },
  methods: {
    ...mapActions(['searchCommunes']),
    customFilter(item, queryText) {
      let textVille = item.ville ? item.ville.toLowerCase() : '';
      let textCodePostal = item.code_postal ? item.code_postal.toLowerCase() : '';
      let searchText = queryText.toLowerCase();
      return (
          textVille.indexOf(searchText) > -1 || textCodePostal.indexOf(searchText) > -1
      );
    },
    changed(commune) {
      this.$emit('changed', commune);
    },
  },

};
</script>

<style>
</style>
