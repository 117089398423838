<template>
  <v-bottom-sheet v-model="sheet">
    <v-sheet class="text-center">
      <v-btn
        class="mt-6"
        @click="sheet = false"
      >
        Fermer
      </v-btn>
      <div 
        id="scroll-target" 
        class="overflow-y-auto" 
        style="max-height: 700px"
      >
        <v-progress-circular
          v-if="loading"
          indeterminate
        />
        <v-timeline 
          v-if="!loading"
        >
          <v-timeline-item
            v-for="(anneeScolaire, index) in getHistoriqueOrderedByAnneeScolaire"
            :key="anneeScolaire.id"
            :left="index%2 == 0"
            :right="index%2 != 0"
            :small="true"
          >
            <span slot="opposite">{{ anneeScolaire.libelle }}</span>
            <v-card class="elevation-2">
              <template v-if="anneeScolaire.adhesion">
                <v-card-text>Adhésion</v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Parts
                        </th>
                        <th class="text-center">
                          Niveau
                        </th>
                        <th class="text-center">
                          Montant
                        </th>
                        <th class="text-center">
                          Date
                        </th>
                        <th class="text-center">
                          Paiement
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="part in anneeScolaire.adhesion.parts"
                        :key="part.id"
                      >
                        <td>{{ displayNomPart(part) }}</td>
                        <td>{{ displayNiveauPart(part) }}</td>
                        <td>{{ part.montant | currency }}</td>
                        <td>{{ anneeScolaire.adhesion.date | formatDate }}</td>
                        <td>{{ part.moyen_paiement }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <template v-if="anneeScolaire.abonnements">
                <v-card-text>Abonnements</v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Revue
                        </th>
                        <th class="text-center">
                          Montant
                        </th>
                        <th class="text-center">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="abonnement in anneeScolaire.abonnements"
                        :key="abonnement.id"
                      >
                        <td>{{ abonnement.type_abonnement.revue.titre }}</td>
                        <td>{{ abonnement.montant | currency }}</td>
                        <td>{{ abonnement.date | formatDate }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <template v-if="anneeScolaire.dons">
                <v-card-text>Dons</v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Destinataire
                        </th>
                        <th class="text-center">
                          Montant
                        </th>
                        <th class="text-center">
                          Date
                        </th>
                        <th class="text-center">
                          Paiement
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="don in anneeScolaire.dons"
                        :key="don.id"
                      >
                        <td>{{ don.cl ? don.cl.nom : 'Département' }}</td>
                        <td>{{ don.montant | currency }}</td>
                        <td>{{ don.date | formatDate }}</td>
                        <td>{{ don.moyen_paiement }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>                  
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import typePerimetre from '../../auth/typePerimetre.js';

export default {
  name: 'ParentHistorique',
  props: {
    parent: {
      type: Object,
      default: () => {},
    },
    shouldShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheet: false,
    };
  },
  computed: {
    ...mapGetters('_parent/historique', [
      'getHistoriqueOrderedByAnneeScolaire',
    ]),
    ...mapState('_parent/historique', {
      loading: state => state.loading,
    }),
  },
  watch: {
    shouldShow(val) {
      if(val) {
        this.loadHistorique({ parentId: this.parent.id });
        this.sheet = true;
      }
    },
    sheet(val) {
      if(!val) this.closeSheet();
    },
  },
  methods: {
    ...mapActions('_parent/historique',[
      'loadHistorique',
    ]),
    closeSheet() {
      this.$emit('close', true);
    },
    displayNomPart(part) {
      switch(part.perimetre.type_perimetre_id) {
        case typePerimetre.NATIONAL:
          return 'National';
        case typePerimetre.DEPARTEMENTAL:
          return `Département ${part.perimetre.nom}`;
        default:
          return `Local ${part.perimetre.nom}`;
      } 
    },
    displayNiveauPart(part) {
      if (part.perimetre.type_perimetre_id === typePerimetre.NATIONAL) {
        return '';
      }
      return part.niveau_adhesion.libelle;
    },
  },
};
</script>