import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// Helpers
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#7fbc56',
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.indigo,
        error: colors.red.darken2,
        info: colors.teal,
        success: '#7fbc56',
        warning: '#d34511',
      },
    },
  },
};

export default new Vuetify(opts);
