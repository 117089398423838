import xhr from '../plugins/axios.js';
import queryString from 'query-string';

const responsabilitesModule = {
  namespaced: true,
  state: {
    responsabilitesLocal: {},
    responsabilitesDepartemental: [],
    responsabilitesNational: [],
  },
  mutations: {
    setResponsabilitesNational(state, responsabilites) {
      state.responsabilitesNational = responsabilites;
    },
    setResponsabilitesDepartemental(state, responsabilites) {
      state.responsabilitesDepartemental = responsabilites;
    },
    setResponsabilitesLocal(state, { conseilLocalOrCdpeId, responsabilites }) {
      state.responsabilitesLocal = { ...state.responsabilitesLocal }; // trick for reload object in view
      state.responsabilitesLocal[conseilLocalOrCdpeId] = responsabilites;
    },
  },
  actions: {
    loadResponsabilitesLocal({ commit }, conseilLocalOrCdpeId) {
      let params = { cl_or_cdpe_id: conseilLocalOrCdpeId };
      const query = queryString.stringify(params);

      return xhr
        .get(`api/admin/responsabilite/local?${query}`)
        .then((response) => {
          commit('setResponsabilitesLocal', {
            conseilLocalOrCdpeId,
            responsabilites: response.data.responsabilite,
          });
        });
    },
    loadResponsabilitesLocalParAppartenance({ dispatch, rootGetters }) {
      rootGetters['_parent/conseilsLocauxParAppartenance'].forEach(
        (conseil) => {
          dispatch('loadResponsabilitesLocal', conseil.id);
        }
      );
    },
    loadResponsabilitesDepartemental({ commit }, cdpeId) {
      let params = { cdpe_id: cdpeId };
      const query = queryString.stringify(params);

      return xhr
        .get(`api/admin/responsabilite/departemental?${query}`)
        .then((response) => {
          commit(
            'setResponsabilitesDepartemental',
            response.data.responsabilite
          );
        });
    },
    loadResponsabilitesNational({ commit }) {
      return xhr.get('api/admin/responsabilite/national').then((response) => {
        commit('setResponsabilitesNational', response.data.responsabilite);
      });
    },
    updateResponsabiliteLocale({ dispatch }, { parentId, data }) {
      return xhr
        .post(`api/admin/parent/${parentId}/responsabilite/local`, data)
        .then(() => {
          dispatch('loadResponsabilitesLocal', data.conseil_local_id);
        });
    },
    updateResponsabiliteDepartementale({ dispatch }, { parentId, data }) {
      return xhr
        .post(`api/admin/parent/${parentId}/responsabilite/departemental`, data)
        .then(() => {
          dispatch('loadResponsabilitesDepartemental', data.cdpe_id);
        });
    },
    updateResponsabiliteNationale({ dispatch }, { parentId, data }) {
      return xhr
        .post(`api/admin/parent/${parentId}/responsabilite/national`, data)
        .then(() => {
          dispatch('loadResponsabilitesNational');
        });
    },
    removeResponsabiliteLocale({ dispatch }, { parentId, data }) {
      return xhr
        .delete(`api/admin/parent/${parentId}/responsabilite/local`, {
          data,
        })
        .then(() => {
          dispatch('loadResponsabilitesLocal', data.conseil_local_id);
        });
    },
    removeResponsabiliteDepartementale({ dispatch }, { parentId, data }) {
      return xhr
        .delete(`api/admin/parent/${parentId}/responsabilite/departemental`, {
          data,
        })
        .then(() => {
          dispatch('loadResponsabilitesDepartemental', data.cdpe_id);
        });
    },
    removeResponsabiliteNationale({ dispatch }, { parentId, data }) {
      return xhr
        .delete(`api/admin/parent/${parentId}/responsabilite/national`, {
          data,
        })
        .then(() => {
          dispatch('loadResponsabilitesNational');
        });
    },
  },
  getters: {
    getResponsabilitesLocal: (state) => (conseilLocalId) => {
      return state.responsabilitesLocal[conseilLocalId];
    },
    getResponsabilitesDepartemental(state) {
      return state.responsabilitesDepartemental;
    },
    getResponsabilitesNational(state) {
      return state.responsabilitesNational;
    },

    getResponsabilitesParent(state, getters, rootState, rootGetters) {
      let local, departemental, parAppartenance, national;

      if (!rootState.parent) {
        return {
          local: [],
          departemental: [],
          parAppartenance: [],
          national: [],
        };
      }

      let localPrincipal =
        state.responsabilitesLocal[rootState.parent.conseil_local.id] || [];
      local = localPrincipal.filter((responsabilite) => {
        return responsabilite.responsables.some((responsable) => {
          return responsable.parent_id === rootState.parent.id;
        });
      });

      departemental = state.responsabilitesDepartemental.filter(
        (responsabilite) => {
          return responsabilite.responsables.some((responsable) => {
            return responsable.parent_id === rootState.parent.id;
          });
        }
      );

      national = state.responsabilitesNational.filter((responsabilite) => {
        return responsabilite.responsables.some((responsable) => {
          return responsable.parent_id === rootState.parent.id;
        });
      });

      parAppartenance = rootGetters['_parent/conseilsLocauxParAppartenance']
        .map((conseilLocal) => {
          let localParAppartenance =
            state.responsabilitesLocal[conseilLocal.id] || [];
          return localParAppartenance
            .filter((responsabilite) => {
              return responsabilite.responsables.some((responsable) => {
                return responsable.parent_id === rootState.parent.id;
              });
            })
            .map((responsabilite) => {
              return {
                ...responsabilite,
                conseilLocal,
              };
            });
        })
        .flat();

      return {
        local,
        departemental,
        parAppartenance,
        national,
      };
    },
    getResponsablesDepartementaux(state) {
      return state.responsabilitesDepartemental
        .flatMap((responsabilite) =>
          responsabilite.responsables.map((responsable) => ({
            ...responsable,
            responsabilite,
          }))
        )
        .sort((r1, r2) => r1.responsabilite.nom > r2.responsabilite.nom);
    },
  },
};

export default responsabilitesModule;
