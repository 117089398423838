<template>
  <v-row>
    <v-col
      cols="2"
      md="2"
      justify="center"
    >
      <v-badge
        inline
        left
        :color="colorInactif"
      >
        Inactif
      </v-badge>
    </v-col>

    <v-col
      cols="2"
      md="2"
      justify="center"
    >
      <v-badge
        inline
        left
        :color="colorAdherentN1"
      >
        Adhérent N+1
      </v-badge>
    </v-col>

    <v-col
      cols="2"
      md="2"
      justify="center"
    >
      <v-badge
        inline
        left
        :color="colorAdhesionEnLigne"
      >
        Inscription en ligne
      </v-badge>
    </v-col>

    <v-col
      cols="2"
      md="2"
      justify="center"
    >
      <v-badge
        inline
        left
        :color="colorEnCoursModification"
      >
        En cours de modification
      </v-badge>
    </v-col>

    <v-col
      cols="2"
      md="2"
      justify="center"
    >
      <v-badge
        inline
        left
        :color="colorEnCoursValidation"
      >
        En cours de validation
      </v-badge>
    </v-col>

    <v-col
      cols="2"
      md="2"
      justify="center"
    >
      <v-badge
        inline
        left
        :color="colorActif"
      >
        Actif
      </v-badge>
    </v-col>
  </v-row>
</template>

<script>
import STATUTS_PARENT, { STATUTS_PARENT_COLORS } from '../../../config/statutParent';

export default {
  computed: {
    colorInactif() {
      return STATUTS_PARENT_COLORS[STATUTS_PARENT.INACTIF];
    },
    colorAdhesionEnLigne() {
      return STATUTS_PARENT_COLORS[STATUTS_PARENT.ADHESION_EN_LIGNE];
    },
    colorEnCoursModification() {
      return STATUTS_PARENT_COLORS[STATUTS_PARENT.EN_COURS_MODIFICATION];
    },
    colorEnCoursValidation() {
      return STATUTS_PARENT_COLORS[STATUTS_PARENT.EN_COURS_VALIDATION];
    },
    colorActif() {
      return STATUTS_PARENT_COLORS[STATUTS_PARENT.ACTIF];
    },
    colorAdherentN1() {
      return 'purple';
    },
  },
};
</script>
