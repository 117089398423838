<template>
  <v-card>
    <div class="pa-5">
      <v-btn
        color="primary"
        @click="back"
      >
        <v-icon>mdi-chevron-left</v-icon>Retour
      </v-btn>
      <v-row>
        <v-col class="ma-4">
          <EditForm
            :enfant="enfant"
            :parent="parent"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import EditForm from '../components/parent/enfant/EditForm';
import defaultEnfantData from '../config/enfant';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    EditForm,
  },
  data: () => ({
    enfant: JSON.parse(JSON.stringify(defaultEnfantData)),
  }),
  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
  },
  created() {
    this.getEnfantById({
      enfantId: this.$route.params.enfantId,
      parentId: this.$route.params.parentId,
    })
      .then(response => {
        this.enfant = response.data;
      });
    this.loadPartsByParentId({ parentId: this.$route.params.parentId });
    this.loadEnfantsByParentId({ parentId: this.$route.params.parentId });
    this.loadParentInfosById({ parentId: this.$route.params.parentId });
  },
  methods: {
    ...mapActions([
      'getEnfantById',
      'loadEnfantsByParentId',
      'loadParentInfosById',
    ]),
    ...mapActions('adhesion', [
      'loadPartsByParentId',
    ]),
    back() {
      this.$router.push({
        name: 'edition-parent',
        params: {
          parentId: this.$route.params.parentId,
        },
      });
    },
  },
};
</script>
