<template>
  <h2>En cours de réalisation ... </h2>
</template>

<script>

export default {
  data() {
    return {};
  },

  methods: {},
};
</script>
