<template>
  <div class="pa-5">
    <v-row
      v-for="parentAbonnement in parentAbonnements"
      :key="parentAbonnement.id"
      class="mb-3"
    >
      <v-col
        cols="12"
        lg="4"
      >
        <h3>{{ parentAbonnement.type_abonnement.revue.titre }}</h3>
        <h4>{{ parentAbonnement.annee_scolaire.libelle }}</h4>
        <h4 v-if="parentAbonnement.annee_scolaire.is_courante">
          {{ isAdherent ? 'Abonnement avec adhésion' : 'Abonnement sans adhésion' }}
        </h4>
        <p>{{ getAbonnementStatusLabel(parentAbonnement) }}</p>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <p>Montant: <span>{{ parentAbonnement.montant | currency }}</span></p>
        <p>Date: <span>{{ parentAbonnement.date | formatDate }}</span></p>
        <p>Valable jusqu'au: <span>{{ parentAbonnement.date_fin | formatDate }}</span></p>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-btn
          v-if="shouldDisplaySuspendButton(parentAbonnement)"
          color="red"
          @click="changeAbonnementSubscription(parentAbonnement.id, !parentAbonnement.actif)"
        >
          {{ parentAbonnement.actif ? 'Suspendre' : 'Activer' }}
        </v-btn>

        <v-btn
          v-if="shouldDisplayDeleteButton(parentAbonnement)"
          color="warning"
          @click="displayConfirmDeletePopup = true"
        >
          Supprimer
        </v-btn>
        <ConfirmedModal
          v-if="displayConfirmDeletePopup"
          sentence="Voulez-vous vraiment supprimer l'abonnement ?"
          btn-action="Oui"
          @close="displayConfirmDeletePopup = false"
          @confirmed="deleteAbonnementById(parentAbonnement.id)"
        />
      </v-col>
      <v-col
        cols="12"
        lg="8"
      >
        <v-form v-model="isFormValid">
          <v-text-field
            v-model="parentAbonnement.email"
            v-validate="'required|email|max:255'"
            append-icon="email"
            type="email"
            label="Email *"
            data-vv-name="email"
            :error-messages="errors.collect('email')"
          />
        </v-form>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-btn
          color="primary"
          :disabled="!isFormValid"
          @click="onUpdateEmailAbonnement(parentAbonnement)"
        >
          Modifier l'email
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmedModal from './../../reusableComponents/confirmedModal';

export default {
  name: 'AbonnementListe',
  components: {
    ConfirmedModal,
  },
  props: {
    parent: { type: Object, default: () => ({}) },
  },

  data: () => ({
    displayConfirmDeletePopup: false,
    isFormValid: false,
  }),

  computed: {
    ...mapGetters('abonnement', {
      parentAbonnements: 'getParentAbonnements',
    }),
    ...mapGetters('adhesion', {
      adhesion: 'getAdhesion',
    }),

    isAdherent() {
      return this.adhesion != undefined;
    },
  },

  methods: {
    ...mapActions('abonnement', [
      'suspendAbonnement',
      'activateAbonnement',
      'updateEmailAbonnement',
      'deleteAbonnement',
    ]),

    changeAbonnementSubscription(parentAbonnementId, toggle) {
      let abonnementMethod = toggle ?
          this.activateAbonnement :
          this.suspendAbonnement;

      abonnementMethod({
        parentId: this.parent.id,
        abonnementId: parentAbonnementId,
      });
    },

    deleteAbonnementById(parentAbonnementId) {
      this.deleteAbonnement({
        parentId: this.parent.id,
        abonnementId: parentAbonnementId,
      })
      .finally(() => this.displayConfirmDeletePopup = false);
    },

    /**
     * Only display the button if abonnement is actif
     * and abonnement was not reconducted for current year
     * and if it's before 31st december of the year
     * @param parentAbonnement
     * @returns {boolean}
     */
    shouldDisplaySuspendButton (parentAbonnement) {
      return parentAbonnement.actif
          && !parentAbonnement.annee_scolaire.is_courante
          && !this.abonnementExistsForCurrentYear(parentAbonnement.type_abonnement.id)
          && this.priorToFollowingYearLastDay(parentAbonnement.date);
    },

    shouldDisplayDeleteButton (parentAbonnement) {
      return parentAbonnement.annee_scolaire.is_courante;
    },

    abonnementExistsForCurrentYear(typeAbonnementId) {
      return this.parentAbonnements.some((parentAbonnement) => {
        return parentAbonnement.annee_scolaire.is_courante
            && parentAbonnement.type_abonnement.id === typeAbonnementId;
      });
    },

    priorToFollowingYearLastDay(abonnementDate) {
      return new Date(abonnementDate).getYear() + 1 >= new Date().getYear();
    },

    getAbonnementStatusLabel(abonnement) {
      if (abonnement.actif) {
        return '';
      }

      if (abonnement.annee_scolaire.is_courante) {
        return 'En cours de validation';
      } else {
        return 'Suspendu';
      }
    },

    onUpdateEmailAbonnement(abonnement) {
      this.updateEmailAbonnement({ 
        parentId: this.parent.id, 
        abonnementId: abonnement.id, 
        abonnementEmail: abonnement.email,
      });
    },
  },
};
</script>