import types from './types';
import xhr from '../plugins/axios.js';

const parentAction = {
  nextStep({ commit }) {
    commit(types.PARENT_FORM_STEP);
  },
  changeConseilLocalParent({ commit, dispatch }, conseilLocal) {
    commit(types.PARENT_FORM_CHANGE_CONSEIL_LOCAL, conseilLocal);
    if (conseilLocal.id) {
      dispatch('getDefaultEtablissementScolaireByConseilLocalId', {
        cdpeId: conseilLocal.cdpe_id,
        conseilLocalId: conseilLocal.id,
      });
    }
  },
  setParentIsLoading({ commit }) {
    commit(types.PARENT_PARENT_LOADING, true);
  },
  loadParentInfosById({ commit }, { parentId }) {
    commit(types.PARENT_PARENT_LOADING, true);
    return xhr
      .get(`api/admin/parent/${parentId}`)
      .then(response => {
        let parent = response.data;
        commit(types.PARENT_LOAD_PARENT, parent);
        return parent;
      })
      .finally(() => commit(types.PARENT_PARENT_LOADING, false));
  },
  loadAllFinancialDatasByParentId({ dispatch }, { parentId }) {
    dispatch('adhesion/loadPartsByParentId', { parentId });
    dispatch('abonnement/loadAbonnementByParentId', { parentId });
    dispatch('don/loadDonsByParentId', { parentId });
    dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId });
  },
  createParent({ commit, rootState, dispatch }, parent ) {
    const anneeScolaireId = rootState.adhesion.anneeScolaireId;
    commit(types.PARENT_PARENT_SAVING, true);
    return xhr.post('api/admin/parent', parentDataForMaj(parent, anneeScolaireId)).then(response => {
      commit('_parent/create/setDefaultConseilLocal', parent.conseil_local);
      dispatch('loadParentInfosById', { parentId: response.data.id });
      return response.data.id;
    }).finally(() => {
      commit(types.PARENT_PARENT_SAVING, false);
    });
  },
  updateParent({ commit, rootState }, parent) {
    const anneeScolaireId = rootState.adhesion.anneeScolaireId;
    commit(types.PARENT_PARENT_SAVING, true);
    return xhr.put(`api/admin/parent/${parent.id}`, parentDataForMaj(parent, anneeScolaireId)).then(() => {
      commit(types.PARENT_UPDATE_PARENT, parent);
    }).finally(() => {
      commit(types.PARENT_PARENT_SAVING, false);
    });
  },
  deleteParentById({ commit ,dispatch }, { parentId }) {
    return xhr.delete(`api/admin/parent/${parentId}`).then(() => {
        commit(types.PARENT_DELETE);
        dispatch('recherche/updateResultParentsAfterDelete', { parentId }, { root: true });
    });
  },
  getDefaultEtablissementScolaireByConseilLocalId({ commit }, { cdpeId, conseilLocalId }) {
    return xhr.get(`/api/admin/cdpe/${cdpeId}/cl/${conseilLocalId}/etablissementScolaire`).then(response => {
      commit(types.PARENT_FORM_CHANGE_DEFAULT_ETABLISSEMENTS, response.data);
    });
  },
};

function parentDataForMaj (parent, anneeScolaireId) {
  const response = {
    adresse: {
      commune_id: parent.adresse.commune.id,
      ligne1: parent.adresse.ligne1,
      ligne2: parent.adresse.ligne2,
    },
    commentaire: parent.commentaire,
    conseil_local_id: parent.conseil_local.id,
    contact: {
      abonne_newsletter: parent.contact.abonne_newsletter,
      email: parent.contact.email,
      telephone_fixe: parent.contact.telephone_fixe,
      telephone_mobile: parent.contact.telephone_mobile,
    },
    personne: {
      civilite: parent.personne.civilite,
      nom: parent.personne.nom,
      prenom: parent.personne.prenom,
    },
  };
  if (anneeScolaireId) {
      return Object.assign(response, { annee_scolaire_id: anneeScolaireId });
  }
  return response;
}

export default parentAction;
