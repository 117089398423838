<template>
  <div>
    <form
      ref="form"
      @submit.prevent="sendParent"
    >
      <h2 class="title">
        Conseil local
      </h2>
      <div>
        <v-row>
          <v-col
            v-if="!isUserLocal"
            cols="12"
            md="6"
          >
            <CommuneAutocomplete
              :disabled="disabledCl"
              :required="communeClRequired"
              :loaded-commune="parent.conseil_local.commune"
              :perimetre-id-filter="perimetreUser.perimetre_id"
              field-name="Code postal / commune du conseil local principal"
              @changed="changedCommuneConseilLocal"
            />
          </v-col>
          <v-col
            cols="12"
            :md="isUserLocal?12:6"
          >
            <ConseilAutocomplete
              class="pt-0 mt-0"
              :readonly="readonly"
              :disabled="disabledCl"
              :conseil-locaux="conseilLocaux"
              :conseil-local="loadedConseilLocal"
              label="Nom du conseil local principal"
              @changedCl="cl => { conseilLocalHasBeenChanged(cl); checkHomonymes(); }"
            />
          </v-col>
        </v-row>
      </div>

      <h2 class="title">
        Parent
      </h2>

      <div>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="parent.personne.civilite"
              item-value="value"
              item-text="text"
              :items="genders"
              label="Civilité"
              @change="parentModified"
            />
          </v-col>
          <v-col :cols="parent.code?4:5">
            <v-text-field
              v-model.trim="parent.personne.nom"
              v-validate="'required|min:2|max:50'"
              label="Nom du parent *"
              data-vv-name="nom"
              :error-messages="errors.collect('nom')"
              @change="() => { parentModified(); checkHomonymes(); }"
            />
          </v-col>
          <v-col :cols="parent.code?4:5">
            <v-text-field
              v-model.trim="parent.personne.prenom"
              v-validate="'required|min:2|max:50'"
              label="Prénom du parent *"
              data-vv-name="prénom"
              :error-messages="errors.collect('prénom')"
              @change="() => { parentModified(); checkHomonymes(); }"
            />
          </v-col>
          <v-col
            v-if="parent.code"
            cols="2"
          >
            <v-text-field
              disabled
              readonly
              label="Code du parent"
              :value="parent.code"
            />
          </v-col>
        </v-row>
      </div>

      <h2 class="title">
        Adresse
      </h2>

      <div>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="parent.adresse.ligne1"
              v-validate="'required|min:2|max:50'"
              label="Adresse postale *"
              data-vv-name="adresse"
              :error-messages="errors.collect('adresse')"
              @change="parentModified"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="parent.adresse.ligne2"
              v-validate="'max:50'"
              label="Adresse complémentaire"
              data-vv-name="adresse-complementaire"
              :error-messages="errors.collect('adresse-complementaire')"
              @change="parentModified"
            />
          </v-col>

          <v-col cols="12">
            <CommuneAutocomplete
              :loaded-commune="parent.adresse.commune"
              field-name="Code postal / commune de l'adresse"
              @changed="changeParentCommuneAdresse"
            />
          </v-col>
        </v-row>
      </div>

      <h2 class="title">
        Contact
      </h2>

      <div>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="parent.contact.telephone_mobile"
              v-validate="'phoneNumber'"
              v-mask="'##.##.##.##.##'"
              append-icon="smartphone"
              type="phone"
              label="Téléphone portable"
              data-vv-name="téléphone portable"
              :error-messages="errors.collect('téléphone portable')"
              @change="parentModified"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="parent.contact.telephone_fixe"
              v-validate="'phoneNumber'"
              v-mask="'##.##.##.##.##'"
              append-icon="phone"
              type="phone"
              label="Téléphone fixe"
              data-vv-name="téléphone fixe"
              :error-messages="errors.collect('téléphone fixe')"
              @change="parentModified"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="parent.contact.email"
              v-validate="'email|max:255'"
              append-icon="email"
              type="email"
              label="Email"
              data-vv-name="email"
              :error-messages="errors.collect('email')"
              @change="changeEmail"
            />
            <p>
              <i>L'email n'est pas obligatoire, mais sans email le parent ne pourra pas accéder aux services en ligne de la FCPE</i>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-checkbox
              v-model="parent.contact.abonne_newsletter"
              :disabled="!parent.contact.email"
              label="Newsletter"
              @change="parentModified"
            />
          </v-col>
        </v-row>
      </div>

      <div class="mb-5">
        <v-row>
          <v-col cols="12">
            <h2 class="title">
              Commentaire
            </h2>
            <v-textarea
              v-model="parent.commentaire"
              :counter="1000"
              append-icon="edit"
              label="Votre commentaire"
              @change="parentModified"
            />
          </v-col>
        </v-row>
      </div>

      <div class="text-right">
        <v-btn
          large
          type="submit"
          :loading="parent_loading || parent_saving"
          :disabled=" parent_loading || parent_saving || !formValid"
          color="primary"
        >
          {{ $route.params.parentId ? "Enregister le parent" : "Créer le parent" }}
          <v-icon
            right
            dark
          >
            check
          </v-icon>
        </v-btn>
      </div>
    </form>
    <ListHomonymesComponent
      :homonymes="homonymes"
      :is-modification="this.$route.params.parentId !== undefined"
      @cancel="homonymes = undefined"
      @modify-homonyme="id => $router.push({
        name: 'edition-parent',
        params: {
          parentId: id,
        }
      })"
    />
  </div>
</template>

<style lang="scss" scoped>
@import './Parent.scss';
</style>

<script>
import CommuneAutocomplete from '../partials/CommuneAutocomplete';
import ConseilAutocomplete from '../partials/ConseilAutocomplete';
import ListHomonymesComponent from './ListHomonymesComponent';
import { statutCanEditParent } from './../../config/statutParent';
import { mapActions, mapGetters, mapState } from 'vuex';
import Vue from 'vue';

export default {
  name: 'ParentForm',
  components: {
    CommuneAutocomplete,
    ConseilAutocomplete,
    ListHomonymesComponent,
  },
  props: {
    parent: {
      type: Object,
      default: () => { },
    },
    fakeSendParent: {
      type: Object,
      default: () => { return { send: false, close: false }; },
    },
  },
  data: () => ({
    genders: [
      { value: 1, text: 'Mlle' },
      { value: 2, text: 'Mme' },
      { value: 3, text: 'M.' },
      { value: 4, text: 'M. et Mme' },
      { value: 5, text: 'Mmes' },
      { value: 6, text: 'MM.' },
      { value: 7, text: 'Aucune' },
    ],
    conseilLocaux: [],
    loadedConseilLocal: {},
    disabled: true,
    isLoading: false,
    loading: false,
    disabledCl: false,
    communeClRequired: true,
    readonly: false,
    homonymes: undefined,
    homonymesCdpe: null,
    homonymesNom: null,
    homonymesPrenom: null,
  }),
  computed: {
    ...mapState('_parent/create', [
      'defaultConseilLocal',
    ]),
    ...mapGetters('session', {
      isUserLocal: 'isUserLocal',
      perimetreUser: 'getPerimetreUser',
    }),
    ...mapGetters({
      parent_loading: 'getParentLoading',
      parent_saving: 'getParentSaving',
    }),
    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      let valid = Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
      //Save validation state in store, container will use it to desactivate save button
      this.$store.commit('_parent/setFormValid', valid);
      return valid;
    },
  },
  watch: {
    parent(val) {
      if (!val || !val.id) {
        Vue.nextTick(() => {
          Vue.nextTick(() => {
            this.errors.clear(); // vee-validate reset
          });
        });

        return;
      }

      if(!this.checkStatutCanEdit()) return;

      // Needs to be done in a next tick otherwise depending on component depth, validation will
      // be executed before the value of the field has changed
      Vue.nextTick(() => {
        this.$validator.validateAll(); // vee-validate reset
      });
      if (!this.parent.id) {
        this.loadedConseilLocal = this.defaultConseilLocalUser();
      } else {
        this.loadedConseilLocal = this.parent.conseil_local;
      }
      this.conseilLocaux = [this.loadedConseilLocal];
      this.disabled = false;
    },
    '$route.params.parentId': function () {
      // called when passing from create to edit to create : the component is not re-created
      this.initConseilLocal();
    },
    fakeSendParent(fakeSendParent) {
      if (fakeSendParent.send) {
        this.sendParent(fakeSendParent);
      }
    },
  },
  created() {
    this.initConseilLocal();
    this.checkStatutCanEdit();
  },
  methods: {
    leave() { },
    ...mapActions([
      'createParent',
      'updateParent',
      'getClByCommuneId',
    ]),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    ...mapActions('_parent', {
      loadAndGetHomonymes: 'loadAndGetHomonymes',
    }),
    checkStatutCanEdit() {
      if( this.parent &&
          this.parent.id &&
          this.parent.statut &&
          !statutCanEditParent(this.parent) ) {
        this.$router.push({ name: 'information-parent', params: { parentId: this.parent.id } });
        return false;
      }
      return true;
    },
    sendParent(fakeSendParent) {
      this.$validator.validateAll();
      if (!this.formValid) {
        return;
      }
      let self = this;
      if (this.$route.params.parentId) {
        //edit
        this.updateParent(this.parent).then(() => {
          this.parentModified(false);
          if (fakeSendParent.leaveToRoute) {
            self.$emit('leaveToRoute', fakeSendParent.leaveToRoute);
          }
          if (fakeSendParent.nextStep) {
            self.$emit('goToNextStep');
          }
        });
      } else {
        //new
        this.createParent(this.parent)
          .then(parentId => {
            this.setSuccess({ info: 'Le parent a bien été créé !' });
            self.$emit('leaveToRoute', { name: 'edition-parent-stepId', params: { parentId, stepId: 2 }, replace: true });
            self.$emit('goToNextStep');
          });
      }
    },
    changeParentCommuneAdresse(commune) {
      this.parent.adresse.commune = commune;
      this.parentModified(commune);
    },
    changedCommuneConseilLocal(commune) {
      if (commune) {
        let emptyConseilLocal = {
          commune,
          id: 0,
          nom: '',
          code: '',
        };
        this.$emit('changeConseilLocal', emptyConseilLocal);
        this.loadedConseilLocal = emptyConseilLocal;
        this.communeClRequired = true;
        this.disabled = false;
        this.conseilLocaux = [];
        this.getClByCommuneId({
          communeId: commune.id,
        }).then(response => {
          this.conseilLocaux = response.data;
          if (response.data.length == 1) {
            let onlyConseilLocal = this.conseilLocaux[0];
            onlyConseilLocal.commune = this.parent.conseil_local.commune;
            this.$emit('changeConseilLocal', onlyConseilLocal);
            this.loadedConseilLocal = onlyConseilLocal;
            this.communeClRequired = false;
            this.checkHomonymes();
          }
        });
      }
    },
    conseilLocalHasBeenChanged(conseilLocal) {
      if (conseilLocal.id) {
        conseilLocal.commune = this.parent.conseil_local.commune;
        this.$emit('changeConseilLocal', conseilLocal);
        this.loadedConseilLocal = conseilLocal;
        this.communeClRequired = false;
      } else {
        this.communeClRequired = true;
      }
      this.parentModified(conseilLocal);
    },
    changeEmail(email) {
      if (!email) {
        this.parent.contact.abonne_newsletter = false;
      }
      this.parentModified(email);
    },
    parentModified(value) {
      if (parent.id) {
        this.$emit('parentModified', value);
      }
    },
    defaultConseilLocalUser() {
      if (this.isUserLocal) {
        this.parent.conseil_local.id = this.perimetreUser.perimetre_id;
        return {
          id: this.perimetreUser.perimetre_id,
          nom: this.perimetreUser.nom,
          code: this.perimetreUser.code,
          cdpe_id: this.perimetreUser.perimetre_appartenance_id,
        };
      } else {
        this.parent.conseil_local = this.defaultConseilLocal;
        return this.defaultConseilLocal;
      }
    },
    initConseilLocal() {
      if (this.isUserLocal) {
        this.disabledCl = true;
        this.communeClRequired = false;
        this.readonly = true;
      }
      if (!this.$route.params.parentId) {
        let conseilLocal = this.defaultConseilLocalUser();
        if (conseilLocal && conseilLocal.id) {
          this.conseilLocaux = [conseilLocal];
          this.communeClRequired = false;
          this.$emit('changeConseilLocal', conseilLocal);
          this.loadedConseilLocal = conseilLocal;
        }
      }
    },
    checkHomonymes() {
      // to check homonymes we need to have the parent's nom, prenom and cdpe_id
      if (!this.parent.personne.nom) return;
      if (!this.parent.personne.prenom) return;
      if (!this.loadedConseilLocal || !this.loadedConseilLocal.cdpe_id) return;
      
      // we only check for homonymes if one of those fields have changed
      if (this.parent.personne.nom != this.homonymesNom ||
        this.parent.personne.prenom != this.homonymesPrenom ||
        this.loadedConseilLocal.cdpe_id != this.homonymesCdpe) {

        this.homonymesNom = this.parent.personne.nom;
        this.homonymesPrenom = this.parent.personne.prenom;
        this.homonymesCdpe = this.loadedConseilLocal.cdpe_id;

        let currentId = 0;
        // dont check ourselves if we are editing a parent, ie parentId exists, ie route /edition-parent/{parentId}
        if(this.$route.params.parentId) {
          currentId = this.$route.params.parentId;
        }

        this.loadAndGetHomonymes({
          cdpeId: this.homonymesCdpe,
          excludeParentId: currentId,
          nom: this.homonymesNom,
          prenom: this.homonymesPrenom,
        })
          .then(response => {
            if (response.data.length > 0)
              this.homonymes = response.data;
          });
      }
    },
  },
};
</script>
