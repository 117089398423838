import Keycloak from 'keycloak-js';
import store from '../store';
import droits from './droits';
import typePerimetre, { PERIMETRE_NATIONAL_ID } from './typePerimetre';
import * as Sentry from '@sentry/browser';
import xhr from '../plugins/axios';

import _includes from 'lodash/includes';

const keycloak = new Keycloak({
  realm: process.env.VUE_APP_FCPE_KEYCLOAK_REALM,
  url: process.env.VUE_APP_FCPE_KEYCLOAK_URL,
  clientId: 'app-fcpe-adhesion-front',
});
export default {
  async checkAuth() {
    if (!store.getters['session/isConnected']) {
      return await this.fetchUserFromSso();
    }

    return store.getters['session/getUser'];
  },
  async fetchUserFromSso() {
    const self = this;
    return new Promise((resolve, reject) => {
      if (window.self !== window.top) {
        // in case we are in an iframe (for widget check-sso reload), we avoid init keycloak because it case errors
        addWidgetPortailScript();
        return reject();
      }
      keycloak
        .init({
          onLoad: 'login-required',
          promiseType: 'native',
        })
        .then(async function (authenticated) {
          if (authenticated) {
            const user = self.getUserFromToken(keycloak.tokenParsed);
            await setUserFCPEAdhesionData(user);
            store.dispatch('session/login', user);
            Sentry.setUser({
              id: user.id,
              email: user.email,
              username: `${user.prenom} ${user.nom}`,
              perimetre: user.perimetre.code,
            });
            addWidgetPortailScript();
            resolve(user);
          } else {
            reject();
          }
        })
        .catch(function(e) {
          console.log(e);
          alert('Failed to initialize authentification from app-fcpeadhesion');
          reject();
        });
        keycloak.onTokenExpired = async function() {
          await keycloak.updateToken(5);
        };
    });
  },
  getUserFromToken(tokenParsed) {
    const user = {};

    user.id = tokenParsed.sub;
    user.nom = tokenParsed.family_name;
    user.prenom = tokenParsed.given_name;
    user.email = tokenParsed.email;

    user.perimetre = {};
    const rolesFcpeAdhesion = tokenParsed.resource_access['app-fcpe-adhesion'].roles;
    if (_includes(rolesFcpeAdhesion, droits.ACCES_PERIMETRE_NATIONAL)) {
      user.perimetre.type_perimetre_id = typePerimetre.NATIONAL;
      user.perimetre.perimetre_id = PERIMETRE_NATIONAL_ID;
      user.perimetre.code = 'National';
      user.perimetre.nom = 'National';
    } else {
      if (_includes(rolesFcpeAdhesion, droits.ACCES_PERIMETRE_DEPARTEMENTAL)) {
        user.perimetre.type_perimetre_id = typePerimetre.DEPARTEMENTAL;
        user.perimetre.perimetre_id = tokenParsed.cdpeId;
        user.perimetre.code = tokenParsed.cdpeCode;
        user.perimetre.nom = tokenParsed.cdpeNom;
      } else {
        if (_includes(rolesFcpeAdhesion, droits.ACCES_PERIMETRE_LOCAL)) {
          user.perimetre.type_perimetre_id = typePerimetre.LOCAL;

          user.perimetre.perimetre_id = tokenParsed.conseilLocalId;
          user.perimetre.code = tokenParsed.conseilLocalCode;
          user.perimetre.nom = tokenParsed.conseilLocalNom;

          user.perimetre.perimetre_appartenance_id = tokenParsed.cdpeId;
          user.perimetre.perimetre_appartenance_code = tokenParsed.cdpeCode;
          user.perimetre.perimetre_appartenance_nom = tokenParsed.cdpeNom;
        } else {
          // Le user ne bénéficie  plus des droits pour accéder à l'application
          // on le redirige vers la mire de login
          this.logout();
          throw new Error("L'utilisateur est identifié mais n'a pas les droits pour accéder à l'application");
        }
      }
    }
    // user.utilisateurLocalBloque =
    //   typeof tokenParsed.utilisateurLocalBloque !== 'undefined' &&
    //   tokenParsed.utilisateurLocalBloque === 'true';
    
    user.droits = rolesFcpeAdhesion;

    return user;
  },
  logout() {
    store.dispatch('session/logout')
         .then(() => {
          try {
            keycloak.logout();
          } catch {
            // Avoid errors in sentry
          }
    });
  },
  async refreshUserInStore() {
    store.dispatch('session/logout');
    return this.checkAuth(store);
  },
  async getToken() {
    // si on est à moins de 10 secondes avant l'expiration du token
    // on le refresh
    if (keycloak.isTokenExpired(10)) {
      await keycloak.updateToken(5);
    }
    return keycloak.token;
  },
};
function setUserFCPEAdhesionData(user) {
  return xhr.get('api/admin/parametrages/utilisateur', { params: { perimetreId: user.perimetre.perimetre_id, email: user.email } })
            .then((response) => {
              const userFCPEAdhesion = response.data[0];
              user.bloque = userFCPEAdhesion.bloque;
              user.idFCPEAdhesion = userFCPEAdhesion.id;
            });
}

function addWidgetPortailScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = process.env.VUE_APP_WIDGET_PORTAIL_URL + '/js/widget.js';
  document.head.append(script);

  const css = document.createElement('link');
  css.href = process.env.VUE_APP_WIDGET_PORTAIL_URL + '/css/widget.css';
  css.rel = 'stylesheet';
  document.head.append(css);
}

