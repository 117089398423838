<template>
  <div>
    <EditForm
      :parent="parent"
      :enfant="enfant"
      :default-etablissements="defaultEtablissements"
      @reloadEnfants="$emit('reloadEnfants')"
      @reinitEnfantData="reinitEnfantData"
    />
  </div>
</template>

<script>
import EditForm from './EditForm';
import _isEmpty from 'lodash/isEmpty';
import defaultEnfantData from '../../../config/enfant';

export default {
  name: 'EnfantForm',

  components: {
    EditForm,
  },

  props: {
    parent: {
      type: Object,
      default: undefined,
    },
    defaultEtablissementsScolaires: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    defaultEtablissements: [],
    // Creates a copy
    enfant: JSON.parse(JSON.stringify(defaultEnfantData)),
  }),
  watch: {
    parent(val) {
      if (!val || _isEmpty(val)) {
        return;
      }
    }, 
    defaultEtablissementsScolaires(val) {
      this.defaultEtablissements = [ ...val];
    },
  },

  methods: {
    reinitEnfantData() {
      this.enfant = {
        ...defaultEnfantData,
        nom: this.parent.personne.nom,
      };
    },
  },
};
</script>
