<template>
  <form
    ref="form"
    @submit.prevent="searching"
  >
    <div>
      <v-row>
        <v-col class="x2">
          <v-autocomplete
            v-model="filterType"
            clearable
            :items="filterTypes"
            item-text="label"
            label="Type de recherche"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="8"
        >
          <v-text-field
            v-model="query"
            label="Recherche"
            data-vv-name="recherche"
            :error-messages="errors.collect('recherche')"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <v-btn
            type="submit"
            :disabled="!formValid"
            color="primary"
          >
            Rechercher
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'FilterForm',
  props: {
    perimetreFilterId: {
      default: 0,
      type: Number,
    },
  },
  data: () => ({
    filterTypes: [
      { value: 'nom_parent', label: 'Nom (Parent)' },
      { value: 'commune', label: 'Commune' },
      { value: 'nom_enfant', label: 'Nom (Enfant)' },
      { value: 'code_foyer', label: 'Code' },
      { value: 'email', label: 'Email' },
    ],
  }),

  computed: {
    ...mapState('recherche', {
      limit: state => state.limit,
      filters: state => state.selectedFilters,
    }),
    query: {
      get() {
        return this.filters.filterQuery;
      },
      set(value) {
        let filters = { ...this.filters, filterQuery: value };
        this.setSelectedFilters(filters);
      },
    },
    filterType: {
      get() {
        return this.filters.filterType;
      },
      set(value) {
        let filters = { ...this.filters, filterType: value };
        this.setSelectedFilters(filters);
      },
    },
    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return (
        this.perimetreFilterId &&
        Object.keys(this.fields).every(field => {
          return this.fields[field] && this.fields[field].valid;
        })
      );
    },
  },

  methods: {

    ...mapActions('recherche', [
        'search',
        'setSelectedFilters',
    ]),
    searching() {
      let filters = { ...this.filters, searchOnLoad: true };
      this.setSelectedFilters(filters);
      this.search({
        q: this.filters.filterQuery,
        limit: this.limit,
        filterType: this.filters.filterType
          ? this.filters.filterType
          : 'tous_criteres',
        perimetre_id: this.perimetreFilterId,
        addParentParAppartenance: this.filters.filterAddParentParAppartenance,
      });
    },
  },
};
</script>