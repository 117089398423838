import xhr from '../plugins/axios.js';
import types from './types';

const enfantAction = {
  async loadEnfantsByParentId({ commit }, { parentId }) {
    commit(types.PARENT_ENFANTS_LOADING, true);
    return xhr
      .get(`api/admin/parent/${parentId}/enfant`)
      .then(response => {
        commit(types.PARENT_LOAD_ENFANTS, response.data);
      })
      .finally(() => commit(types.PARENT_ENFANTS_LOADING, false));
  },
  saveEnfant({ commit, rootState }, { enfant, enfantId, parentId }) {
    const anneeScolaireId = rootState.adhesion.anneeScolaireId;
    commit(types.PARENT_ENFANTS_LOADING, true);
    return xhr
      .put(`api/admin/parent/${parentId}/enfant/${enfantId}`, Object.assign(enfant, { annee_scolaire_id: anneeScolaireId }))
      .finally(() => commit(types.PARENT_ENFANTS_LOADING, false));
  },
  deleteEnfant({ commit }, { enfantId, parentId }) {
    commit(types.PARENT_ENFANTS_LOADING, true);
    return xhr
      .delete(`api/admin/parent/${parentId}/enfant/${enfantId}`)
      .finally(() => commit(types.PARENT_ENFANTS_LOADING, false));
  },
  createEnfant({ commit }, { enfant, parentId }) {
    commit(types.PARENT_ENFANTS_LOADING, true);
    return xhr
      .post(`api/admin/parent/${parentId}/enfant`, enfant)
      .finally(() => commit(types.PARENT_ENFANTS_LOADING, false));
  },
  getEnfantById({ commit }, { enfantId, parentId }) {
    commit(types.PARENT_ENFANTS_LOADING, true);
    return xhr
      .get(`api/admin/parent/${parentId}/enfant/${enfantId}`)
      .finally(() => commit(types.PARENT_ENFANTS_LOADING, false));
  },
};

export default enfantAction;
