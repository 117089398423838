<template>
  <v-row>
    <v-col cols="12">
      <div :class="edit ? '' : 'v-card'">
        <form
          ref="form"
          class="mb-5 pb-5"
          @submit.prevent="validate"
        >
          <v-card-title
            primary-title
            class="title"
          >
            {{ edit ? "Editer cet enfant": "Créer un enfant" }}
          </v-card-title>
          <div>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model.trim="enfant.nom"
                  v-validate="'required|min:2|max:50'"
                  label="Nom de l'enfant *"
                  data-vv-name="nom"
                  :error-messages="errors.collect('nom')"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model.trim="enfant.prenom"
                  v-validate="'required|min:2|max:50'"
                  label="Prénom de l'enfant *"
                  data-vv-name="prénom"
                  :error-messages="errors.collect('prénom')"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <DatePicker
                  label="Date de naissance"
                  :date="enfant.date_de_naissance"
                  :readonly="false"
                  :required="false"
                  @changeDate="changeEnfantDateDeNaissance"
                />
              </v-col>
            </v-row>
          </div>
          <v-card-title
            primary-title
            class="title"
          >
            Etablissement scolaire
          </v-card-title>

          <div>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <CommuneAutocomplete
                  :loaded-commune="commune"
                  :required="false"
                  :having-at-least-one-etablissement-filter="true"
                  @changed="changedCommune"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="etablissementSelected"
                  v-validate="'required'"
                  no-data-text="Aucun établissement de disponible"
                  :items="etablissements"
                  item-text="nom"
                  data-vv-name="nom de l'établissement"
                  :error-messages="errors.collect(`nom de l'établissement`)"
                  label="Nom de l'établissement *"
                  return-object
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="enfant.classe.type.id"
                  v-validate="'required'"
                  item-text="libelle"
                  item-value="id"
                  :items="orderedClasses"
                  data-vv-name="classe"
                  :error-messages="errors.collect(`classe`)"
                  label="Classe *"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="enfant.section"
                  label="Section"
                />
              </v-col>
            </v-row>
          </div>
          <v-alert
            v-if="changeWillAffectParentCL"
            type="warning"
          >
            Le conseil local principal du parent sera modifié
          </v-alert>
          <div class="text-right">
            <v-btn
              :loading="enfant_loading"
              color="primary"
              type="submit"
              :disabled="enfant_loading || !formValid"
            >
              {{ edit ? "Editer cet enfant": "Créer un enfant" }}
              <v-icon
                right
                dark
              >
                edit
              </v-icon>
            </v-btn>
          </div>
        </form>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CommuneAutocomplete from '../../partials/CommuneAutocomplete';
import DatePicker from '../../partials/DatePicker';

export default {
  name: 'EnfantEditForm',
  components: {
    CommuneAutocomplete,
    DatePicker,
  },
  props: {
    enfant: {
      type: Object,
      default: undefined,
    },
    parent: {
      type: Object,
      default: undefined,
    },
    defaultEtablissements: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    etablissements: [],
    etablissementSelected: null,
    classes: [],
  }),
  computed: {
    ...mapGetters({
      enfant_loading: 'getEnfantsLoading',
      enfants: 'getEnfants',
    }),
    ...mapGetters('adhesion', {
      adhesion: 'getAdhesion',
    }),
    isAdherent() {
      return this.adhesion != undefined;
    },
    edit() {
      return this.$route.params.enfantId !== undefined;
    },
    orderedClasses() {
      return this.classes.slice().sort(classe => {
        if (this.etablissementSelected) {
          let ClasseNiveauEnseignementId = classe.niveau_enseignement.id;
          let niveauxEnseignement = this.etablissementSelected.niveaux_enseignement ? this.etablissementSelected.niveaux_enseignement : [];
          let found = niveauxEnseignement.find(niveau => niveau.id === ClasseNiveauEnseignementId);
          if (typeof found !== 'undefined') {
              return -1;
          }
        }

        return 1;

      });
    },
    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },
    commune() {
      try {
        return this.enfant.classe.etablissement_scolaire.commune;
      } catch (exception) {
        return {};
      }
    },
    changeWillAffectParentCL() {
      if(!this.isAdherent) return false;
      if(!this.etablissementSelected) return false;

      let oldClId = this.parent.conseil_local.id;
      let newClId = this.etablissementSelected.perimetre_id;

      if(oldClId === newClId) return false;

      // si il n'y a aucun autre enfant sur l'ancien CL principal du parent
      if(this.enfants.some(e => e.id !== this.enfant.id && e.classe.etablissement_scolaire.conseil_local.id === oldClId)) return false;

      return true;
    },
  },
  watch: {
    parent: {
      deep: true,
      handler: function(newVal){
        if (!this.edit) {
          this.enfant.nom = newVal.personne.nom;
        }
      },
    },
    dob(val) {
      this.dateFormatted = this.formatDate(val);
    },
    enfant(val) {
      // edit a child
      let enfant = val;
      if (enfant.classe.etablissement_scolaire && enfant.classe.etablissement_scolaire.id) {
        this.changedCommune({ id: enfant.classe.etablissement_scolaire.commune.id }).then(() => {
          this.etablissementSelected = this.etablissements.find(el => el.id === enfant.classe.etablissement_scolaire.id);
        });
      }
    },
    defaultEtablissements(val) {
      this.setDefaultEtablissements(val);
    },
  },
  created() {
    this.getClasseTypes().then(response => {
      this.classes = response.data;
    });
  },
  methods: {
    ...mapActions([
        'getClasseTypes',
        'getEtablissementScolaireByCommuneId',
        'searchCommunes',
        'saveEnfant',
        'createEnfant',
    ]),

    changeEnfantDateDeNaissance(date) {
      this.enfant.date_de_naissance = date;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    async changedCommune(commune) {
      if (commune) {
        await this.getEtablissementScolaireByCommuneId({
          communeId: commune.id,
        }).then(response => {
          this.etablissements = [ ...response.data];
          if (response.data.length == 1) {
            this.etablissementSelected = response.data[0];
          }
        });
      }
    },
    validate() {
      this.$validator.validateAll();
      if (!this.formValid) {
        return;
      }

      let enfant = {
        classe: {
          etablissement_scolaire_id: this.etablissementSelected.id,
          type_id: this.enfant.classe.type.id,
        },
        date_naissance: this.enfant.date_de_naissance,
        nom: this.enfant.nom,
        prenom: this.enfant.prenom,
        section: this.enfant.section,
      };
      if (this.edit) {
        this.saveEnfant({
          enfantId: this.$route.params.enfantId,
          parentId: this.$route.params.parentId,
          enfant,
        }).then(() => {
          this.$router.push({
            name: 'edition-parent-stepId',
            params: {
              parentId: this.$route.params.parentId,
              stepId: 2,
            },
          }).catch(err => { err; });
        });
      } else {
        this.createEnfant({
          parentId: this.$route.params.parentId,
          enfant,
        }).then(() => {
          this.reinitForCreate();
          this.$emit('reloadEnfants');
        });
      }
    },
    reinitForCreate() {
      if (!this.parent) {
        throw new Error('reinitForCreate() method is used for creation, then nomParent and defaultEtablissement props must be set');
      }

      this.$emit('reinitEnfantData');
      this.setDefaultEtablissements(this.defaultEtablissements);
      //Need 3 nextTick because a validation is triggered before
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.errors.clear();
          });
        });
      });
    },

    setDefaultEtablissements(defaultEtablissements) {
      this.etablissements = [ ...defaultEtablissements];
      this.etablissementSelected = defaultEtablissements ? defaultEtablissements.reduce((etablissementPrincipal, etablissement) => (etablissement.principal ? etablissement : etablissementPrincipal), null) : null;
    },
  },

};
</script>
