<template>
  <div
    v-if="conseilsLocauxSansParts.length"
    class="pa-2"
  >
    <v-row>
      <v-col cols="12">
        <h1 class="title">
          Adhésion Locale
        </h1>
      </v-col>
    </v-row>
    <form
      ref="form"
      @submit.prevent="validate"
    >
      <v-row v-if="conseilsLocauxSansParts.length>0">
        <v-col
          cols="12"
          md="4"
        >
          <ConseilAutocomplete
            :conseil-local="conseilLocal"
            :conseil-locaux="conseilsLocauxSansParts"
            :label="'Nom du conseil'"
            @changedCl="changedCl"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="chosenNiveauId"
            v-validate="'required'"
            required
            item-value="id"
            item-text="libelle"
            no-data-text="Aucun niveau de disponible"
            :items="niveauxCL"
            item-disabled="adhesion_en_ligne"
            :loading="niveauxLoading"
            :label="labelNiveau"
            data-vv-name="niveau"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <PaiementSelect
            :loaded-moyen-paiement="loadedMoyenPaiement"
            @moyenPaiementSelected="updateMoyenPaiement"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          Montant:
          <span
            v-if="montantPartLocale !== null"
            class="price"
          >{{ montantPartLocale | currency }}</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            :disabled="!canAddPart"
            type="submit"
            color="primary"
          >
            <v-icon>{{ "add" }}</v-icon>
            {{ "Ajouter" }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        
        
        v-if="conseilsLocauxSansParts.length==0"
      >
        {{ "Pas de conseil local sans part" }}
      </v-row>
    </form>
  </div>
</template>

<script>
import ConseilAutocomplete from '../../../partials/ConseilAutocomplete';
import { mapActions, mapGetters } from 'vuex';
import PaiementSelect from '../../../partials/paiement/select';
import { getDefaultMoyenPaiement } from '../../../../config/moyenPaiement';

export default {
  name: 'PartForm',
  components: {
    ConseilAutocomplete,
    PaiementSelect,
  },
  props: {
    parent: {
      type: Object,
      default: () => {
        return {};
      },
    },
    conseilLocaux: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data: () => ({
    labelNiveau: 'Choisissez le Niveau *',
    part: null,
    conseilLocal: null,
    chosenNiveauId: null,
    defaultNiveau: {
      id: 0,
      libelle: '',
      position: 0,
      tarif: 0,
    },
    selectedMoyenPaiement: null,
    loadedMoyenPaiement: getDefaultMoyenPaiement(),
  }),

  computed: {
    ...mapGetters('adhesion', {
      niveauxCL: 'getNiveauxCL',
      niveauxLoading: 'getNiveauxLocalLoading',
      adhesion: 'getAdhesion',
    }),
    isAdherent() {
      return this.adhesion != undefined;
    },
    canAddPart() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },
    montantPartLocale() {
     return this.chosenNiveau && this.chosenNiveau.id ?
          this.chosenNiveau.tarif :
          null;
    },

    chosenNiveau() {
      if (!this.chosenNiveauId) {
        return this.defaultNiveau;
      }

      return this.niveauxCL.find((niveau) => {
        return niveau.id === this.chosenNiveauId;
      });
    },
    /**
     * Filters Cls, the ones that are already in parts will not appear
     */
    conseilsLocauxSansParts() {
      let clWithPartsIds = this.adhesion.parts.local.map(part => {
        return part.cl.id;
      });
      let conseilsLocauxSansParts = this.conseilLocaux.filter(cl => {
        return !clWithPartsIds.includes(cl.id);
      });
      if (conseilsLocauxSansParts.length > 0) {
        if (this.conseilLocal == null) {
          this.changedCl(conseilsLocauxSansParts[0]);
        }
      }
      return conseilsLocauxSansParts;
    },
  },
  methods: {
    ...mapActions([
      'addPart',
    ]),
    ...mapActions('adhesion', [
      'loadNiveauxPartLocal',
      'sendParts',
    ]),

    updateMoyenPaiement(moyenPaiement) {
      this.selectedMoyenPaiement = moyenPaiement;
    },

    validate() {
      this.$validator.validateAll();
      if (!this.canAddPart) {
        throw new Error('Cannot create part right now');
      }

      // Creates a copy of parts that is not reactive
      let parts = JSON.parse(JSON.stringify(this.adhesion.parts));

      parts.local.push({
        cl: {
          id: this.conseilLocal.id,
        },
        moyen_paiement: this.selectedMoyenPaiement,
        niveau: {
          id: this.chosenNiveau.id,
        },
      });

      this.sendParts({
        parentId: this.parent.id,
        parts,
        date: this.adhesion.date,
        isAdherent: this.isAdherent,
      }).then(this.resetForm);
    },

    resetForm() {
      this.conseilLocal = {};
      this.chosenNiveauId = null;
    },
    changedCl(cl) {
      this.conseilLocal = cl;
      this.loadNiveauxPartLocal(cl.id);
    },
  },
};
</script>
