import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    filterAnneesScolaires: [],
  },

  mutations: {
    setFilterAnneesScolaires(state, data) {
      state.filterAnneesScolaires = data;
    },
    ADD_ANNEE_SCOLAIRE(state, data) {
      state.filterAnneesScolaires.unshift(data);
    },
    UPDATE_ANNEE_SCOLAIRE(state, data) {
      let annee = state.filterAnneesScolaires.find(as => as.id === data.id);
      annee.libelle = data.libelle;
      annee.date_debut = data.date_debut;
      annee.date_fin = data.date_fin;
      annee.date_saisie_debut = data.date_saisie_debut;
      annee.date_saisie_fin = data.date_saisie_fin;
    },
    DELETE_ANNEE_SCOLAIRE(state, data) {
      state.filterAnneesScolaires = state.filterAnneesScolaires.filter(as => as.id !== data.id);
    },
  },

  actions: {
    loadFilterAnneesScolaires({ commit }) {
      return xhr.get('api/admin/parametrages/anneescolaire').then(response => {
        commit('setFilterAnneesScolaires', response.data);
      });
    },
    createAnneeScolaire({ commit }, anneeScolaire) {
      let query = {
        date_debut: anneeScolaire.date_debut,
        date_fin: anneeScolaire.date_fin,
        date_saisie_debut: anneeScolaire.date_saisie_debut,
        date_saisie_fin: anneeScolaire.date_saisie_fin,
        libelle: anneeScolaire.libelle,
      };
      return xhr.post('api/admin/parametrages/anneescolaire', query)
        .then(response => commit('ADD_ANNEE_SCOLAIRE', { ...query, id: response.data.id } ));
    },
    updateAnneeScolaire({ commit }, anneeScolaire) {
      let query = {
        date_debut: anneeScolaire.date_debut,
        date_fin: anneeScolaire.date_fin,
        date_saisie_debut: anneeScolaire.date_saisie_debut,
        date_saisie_fin: anneeScolaire.date_saisie_fin,
        libelle: anneeScolaire.libelle,
      };
      return xhr.put(`api/admin/parametrages/anneescolaire/${anneeScolaire.id}`, query)
        .then(() => commit('UPDATE_ANNEE_SCOLAIRE', anneeScolaire ));

    },
    deleteAnneeScolaire({ commit }, anneeScolaire) {
      return xhr.delete(`api/admin/parametrages/anneescolaire/${anneeScolaire.id}`)
        .then(() => commit('DELETE_ANNEE_SCOLAIRE', { id: anneeScolaire.id } ));
    },
  },

  getters: {
    getAnneeScolaireCourante(state) {
      return state.filterAnneesScolaires.find(a => a.courante);
    },
    getAnneesScolaires(state) {
      return state.filterAnneesScolaires;
    },
    listAnneeScolaireSaisie(state) {
      return state.filterAnneesScolaires.filter(a => a.saisie);
    },
  },
};
