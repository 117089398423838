<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        v-validate="validationRule"
        v-mask="'##/##/####'"
        append-icon="event"
        :disabled="disabled"
        :readonly="readonly"
        :label="formattedLabel"
        :data-vv-name="label"
        :error-messages="errors.collect(label)"
        v-on="on"
        @change="changeDate"
      />
    </template>
    <v-date-picker
      v-model="dateSelected"
      locale="fr-FR"
      no-title
      scrollable
      :readonly="false"
      @click="menu = false"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Annuler
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(dateSelected)"
      >
        Ok
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    label: {
      type: String,
      default: 'Picker in menu',
    },
    date: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    between: {
      type: Object,
      default: () => {
        return { after: '', before: '', inclusion: '()' };
      },
    },
    after: {
      type: String,
      default: '',
    },
    before: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dateSelected: '',
    dateFormatted: '',
    menu: false,
  }),
  /**
   * This component should never have a specific validator instance
   * We inject the parent validator
   */
  inject: [
    '$validator',
  ],

  computed: {
    formattedLabel() {
      return `${this.label}${this.required ? ' *': ''}`;
    },
    validationRule() {
      let rule = `${this.required ? 'required': ''}|date_format:dd/MM/yyyy`;
      if (this.between.after && this.between.before) {
        // see doc here : http://vee-validate.logaretm.com/v2/guide/rules.html#date-between
        rule += `|date_between:${this.formatDate(this.between.after)},${this.formatDate(this.between.before)},${this.formatDate(this.between.inclusion)}`;
      }
      if (this.after) {
        // see doc here : http://vee-validate.logaretm.com/v2/guide/rules.html#after
        rule += `|after:${this.formatDate(this.after)}`;
      }
      if (this.before) {
        // see doc here : http://vee-validate.logaretm.com/v2/guide/rules.html#before
        rule += `|before:${this.formatDate(this.before)}`;
      }
      return rule;
    },
  },
  watch: {
    dateSelected(val) {
      this.dateFormatted = this.formatDate(val);
      this.$emit('changeDate', val);
    },

    date: {
      immediate: true,
      handler(val) {
        this.dateSelected = val;
      },
    },
  },

  methods: {
    changeDate(date) {
      let dateEmit = null;
      if (date) {
        const [day, month, year] = date.split('/');
        dateEmit = `${year}-${month}-${day}`;
      }
      this.$emit('changeDate', dateEmit);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

