<template>
  <div class="pa-2"> 
    <form
      ref="form"
      @submit.prevent="edit"
    >
      <h1>Editer ce don</h1>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            disabled
            required
            label="Destinataire"
            :value="don.is_local ? don.cl.nom : 'Conseil Départemental'"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            disabled
            required
            label="Date"
            :value="don.date"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="don.montant"
            required
            type="number"
            label="Montant"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <PaiementSelect
            :loaded-moyen-paiement="loadedMoyenPaiement"
            @moyenPaiementSelected="updateMoyenPaiement"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            type="submit"
            color="primary"
          >
            <v-icon>{{ "edit" }}</v-icon>
            {{ "Modifier" }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import PaiementSelect from '../../partials/paiement/select';
import { mapActions } from 'vuex';

export default {
  name: 'EditDonForm',

  components: {
    PaiementSelect,
  },

  props: {
    don: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    labelNiveau: 'Choisissez le Niveau *',
    chosenNiveau: null,
    selectedMoyenPaiement: null,
  }),
  computed: {

    loadedMoyenPaiement() {
      return this.don.moyen_paiement;
    },
  },

  methods: {
    ...mapActions('don', [
      'editDonDepartemental',
      'editDonLocal',
    ]),

    updateMoyenPaiement(moyenPaiement) {
      this.selectedMoyenPaiement = moyenPaiement;
    },

    edit() {
      let don,
          editMethod;

      don = {
        date: this.don.date,
        montant: this.don.montant,
        moyen_paiement: this.selectedMoyenPaiement,
      };

      editMethod = this.don.is_local ?
          this.editDonLocal :
          this.editDonDepartemental ;

      editMethod({
        don,
        donId: this.don.id,
        parentId: this.parent.id,
      });

      this.$router.push({
        name: 'edition-parent-stepId',
        params: {
          parentId: this.$route.params.parentId,
          stepId: 3,
        },
        hash: '#dons',
      });
    },
  },
};
</script>
