import xhr from '../../plugins/axios';

export default {
  namespaced: true,
  state: {
    adhesionenligne: {
      active: false,
      periode_inactivite_start: null,
      periode_inactivite_end: null,
      additional_info: null,
      paypal: {
        active: false,
        contact: null,
        technical: {
          username: null,
          password: null,
          signature: null,
        },
      },
      hipay: {
        active: false,
        technical: {
          login: null,
          password: null,
          website_id: null,
          category_id: null,
          rating: null,
          locale: null,
          ws_base_url: null,
          validate_signature: null,
        },
      },
      money_tigo: {
        active: false,
        technical: {
          compte_national: null,
          login: null,
          password: null,
          merchant_key: null,
          secret_key: null,
        },
      },
      cheque: {
        active: false,
        ordre: null,
      },
      virement: {
        active: false,
        iban: null,
      },
    },
  },

  mutations: {
    setAdhesionenligne(state, adhesionenligne) {
      state.adhesionenligne = adhesionenligne;
    },
  },

  actions: {
    loadByCdpeId({ commit }, { cdpeId }) {
      return xhr
        .get(`api/admin/cdpe/${cdpeId}/adhesion_en_ligne`)
        .then(response => {
          commit('setAdhesionenligne', response.data);
        });
    },

    save(store, { adhesionenligne, cdpeId }) {
      return xhr.put(
        `api/admin/cdpe/${cdpeId}/adhesion_en_ligne`,
        adhesionenligne
      );
    },
  },

  getters: {},
};
