import parentMutateur from './ParentMutateur';
import resetMutateur from './ResetMutateur';
import workflowMutateur from './WorkflowMutateur';

const mutations = {
  ...parentMutateur,
  ...resetMutateur,
  ...workflowMutateur,
};

export default mutations;
