const workflowGetter = {
  getWorkflowLoading(state) {
    return state.workflow.loading;
  },
  getValidationSaisiesAdhesionLocal(state) {
    return state.workflow.validation_saisies_adhesion_local.results;
  },
  getValidationSaisiesAdhesionLocalCdpeId(state) {
    return state.workflow.validation_saisies_adhesion_local.cdpeId;
  },
  getValidationSaisiesAdhesionLocalCdpeOrClId(state) {
    return state.workflow.validation_saisies_adhesion_local.cdpeOrClId;
  },
  getReceptionAdhesionDepartementLots(state) {
    return state.workflow.reception_adhesion_departement.results;
  },
  getLotsTransfertAdhesionsDepartemental(state) {
    return state.workflow.transfert_adhesions_departemental.results;
  },
  getValidationAdhesionsEnLigne(state) {
    return state.workflow.validation_adhesions_en_ligne.results;
  },
};

export default workflowGetter;
