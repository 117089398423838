import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';

const rules = {
  /**
   * Correct format : 04.78.28.06.77
   */
  phoneNumber: {
    validation: {
      getMessage: () => "Le numéro de téléphone n'est pas valide",
      validate: phone => /^(\d{2}\.){4}\d{2}$/.test(phone),
    },
  },
  /**
   * Correct format : {} with at least one property not empty
   */
  notEmpty: {
    validation: {
      getMessage: field => `Le champ ${field} est obligatoire`,
      validate: value => !_isEmpty(value),
    },
  },
  /**
   * Correct format : {} with a required property
   */
  propertyRequired: {
    validation: {
      getMessage: field => `Le champ ${field} est obligatoire`,
      validate: (value, args) => {
        return !_isEmpty(value) && _has(value, args[0]) && Boolean(value[args[0]]);
      },
    },
  },
};

export default rules;
