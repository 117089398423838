import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr';
import rules from '../rules/list';

Validator.localize('fr', fr);
for (let ruleName in rules) {
  if (!rules.hasOwnProperty(ruleName)) {
    continue;
  }

  let rule = rules[ruleName];
  Validator.extend(ruleName, rule.validation, rule.options);
}
Vue.use(VeeValidate, {
  locale: 'fr',
  // inject: false,
});


export default VeeValidate;
