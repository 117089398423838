import xhr from '../plugins/axios.js';

export default {
    namespaced: true,
    state: {
        loading: false,
        lots: [],
    },
    mutations: {
        SET_LOTS (state, _lots) {
            state.lots = _lots;
        },
        SET_LOADING (state, _loading) {
            state.loading = _loading;
        },
    },
    actions: {
        loadLotsValidationsNationales ({ commit }) {
            commit('SET_LOADING', true);
            return xhr
                .get('api/admin/validation/reception/adhesion/national')
                .then(response => {
                    commit('SET_LOTS', response.data.lots);
                })
                .finally(() => commit('SET_LOADING', false));
        },
        sendValidationsNationales ({ commit, dispatch }, { lots }) {
            commit('SET_LOADING', true);
            let params = lots.map(el => ({ id: el.id }));
            return xhr
                .post('api/admin/validation/reception/adhesion/national', params)
                .then(() => {
                    // Don't commit WORKFLOW_TOGGLE_LOADING here, because it's done after in loadLotsValidationsNationales
                })
                .finally(() => {
                    dispatch('loadLotsValidationsNationales');
                    commit('SET_LOADING', false);
                });
        },
    },
    getters: {
        getLoading (state) {
            return state.loading;
        },
        getLots (state) {
            return state.lots;
        },
    },
};
