<template>
  <v-card v-if="recapFinancier">
    <v-list
      :two-line="true"
    >
      <v-subheader>Récapitulatif</v-subheader>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Adhésion nationale</v-list-item-title>
          <v-list-item-subtitle>
            Montant: <span>{{ recapFinancier.adhesion.national | currency }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Adhésion départementale</v-list-item-title>
          <v-list-item-subtitle>
            Montant: <span>{{ recapFinancier.adhesion.departemental | currency }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Adhésion locale</v-list-item-title>
          <v-list-item-subtitle>
            Montant: <span>{{ recapFinancier.adhesion.local | currency }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Abonnements</v-list-item-title>
          <v-list-item-subtitle>
            Montant: <span>{{ totalAbonnement | currency }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Dons</v-list-item-title>
          <v-list-item-subtitle>
            Montant: <span>{{ dons | currency }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Adhésion, abonnements et dons: {{ total | currency }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-btn
      class="ma-2"
      :disabled="total === 0"
      color="warning"
      @click="displayDeleteFinancialDataPopup = true"
    >
      Supprimer les données financières
    </v-btn>
    <ConfirmedModal
      v-if="displayDeleteFinancialDataPopup"
      sentence="Voulez-vous vraiment supprimer les données financières de ce parent (adhésion, abonnement et dons) ?"
      btn-action="Oui"
      @close="displayDeleteFinancialDataPopup=false"
      @confirmed="deleteFinancialData()"
    />
  </v-card>
</template>


<style lang="scss" scoped>
  @import "./Recapitulatif.scss";
</style>
<script>
import _reduce from 'lodash/reduce';
import { mapActions, mapGetters } from 'vuex';
import ConfirmedModal from './../../reusableComponents/confirmedModal';

export default {
  name: 'Recapitulatif',
  components: {
    ConfirmedModal,
  },
  props: {
    recapFinancier: {
      type: Object,
      default: null,
    },
    parent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    displayDeleteFinancialDataPopup: false,
    displayDeleteFinancialDataLoader: false,
  }),
  computed: {
    ...mapGetters('parametrages/anneeScolaire', [
      'getAnneeScolaireCourante',
    ]),
    dons() {
      return _reduce(this.recapFinancier.don, (sum, amount) => {
        return sum + amount;
      }, 0);
    },

    totalAbonnement() {
      return _reduce(this.recapFinancier.abonnement, (sum, abonnement) => {
        return sum + abonnement.montant;
      }, 0);
    },

    total() {
      let total;

      total = this.dons;
      total += _reduce(this.recapFinancier.adhesion, (sum, amount) => {
        return sum + amount;
      }, 0);
      total += this.totalAbonnement;

      return total;
    },
  },

  created() {
    this.loadAnneesScolaires();
  },

  methods: {
    ...mapActions('recapFinancier', [
      'sendDeleteFinancialData',
    ]),
    ...mapActions('parametrages/anneeScolaire', {
      loadAnneesScolaires: 'loadFilterAnneesScolaires',
    }),
    deleteFinancialData() {
      this.displayDeleteFinancialDataLoader = true;
      this.sendDeleteFinancialData({ parentId: this.parent.id })
          .then(() => {
            this.displayDeleteFinancialDataPopup = false;
            this.displayDeleteFinancialDataLoader = false;
          });
    },
    leave() {},
  },
};
</script>