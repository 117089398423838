<template>
  <div v-if="canAffecteResponsabiliteLocale">
    <h1 class="title">
      Responsabilités locales
    </h1>
    <h3>Responsabilité conseil local principal</h3>
    <br>
    <p>Conseil local {{ parent.conseil_local.nom }}</p>
    <ResponsabilitesAutocomplete
      :responsabilites="responsabilitesLocal[parent.conseil_local.id]"
      :current-parent-id="parent.id"
      :perimetre-id="parent.conseil_local.id"
      :with-none-option="true"
      type="local"
      :fake-reload="fakeReloadResponsabilite"
      @changedResponsabilite="changedResponsabilite"
    />
    <br>
    <div v-if="showConseilsLocauxParAppartenance">
      <br>
      <h3>Responsabilité conseil local par appartenance</h3>
      <br>
      <div
        v-for="conseilLocal in conseilsLocauxParAppartenance"
        :key="conseilLocal.id"
      >
        <p>Conseil local {{ conseilLocal.nom }}</p>
        <ResponsabilitesAutocomplete
          :perimetre-id="conseilLocal.id"
          :current-parent-id="parent.id"
          :with-none-option="true"
          type="local"
          :fake-reload="fakeReloadResponsabilite"
          :responsabilites="responsabilitesLocal[conseilLocal.id]"
          @changedResponsabilite="changedResponsabilite"
        />
      </div>
    </div>

    <div v-if="canAffecteResponsabiliteDepartementale && !isUserLocal">
      <h1 class="title">
        Responsabilités Départementales
      </h1>
      <ResponsabilitesAutocomplete
        type="departemental"
        :perimetre-id="parent.conseil_local.cdpe_id"
        :current-parent-id="parent.id"
        :with-none-option="true"
        :fake-reload="fakeReloadResponsabilite"
        :responsabilites="getResponsabilitesDepartemental"
        @changedResponsabilite="changedResponsabilite"
      />
    </div>

    <v-dialog
      v-model="showUniqueResponsabiliteWarning"
      max-width="450"
    >
      <v-card>
        <div class="pa-5">
          <v-card-text>
            La responsabilité: {{ currentResponsabilite.nom }} est déjà portée par
            {{ getResponsableName(currentResponsabilite) }} <br>
            Souhaitez-vous remplacer ce responsable avec le parent actuel
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="closeShowUniqueResponsabiliteWarning"
              v-text="'Fermer'"
            />
            <v-btn
              color="primary"
              @click="proceedResponsabiliteChange"
              v-text="'Remplacer ce responsable'"
            />
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <div v-if="showResponsabiliteNationale">
      <h1 class="title">
        Responsabilité Nationale
      </h1>
      <v-switch
        v-model="isAdministrateurNational"
        label="Administrateur(trice) National(e)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ResponsabilitesAutocomplete from '../partials/ResponsabilitesAutocomplete';

export default {
  name: 'ResponsabiliteForm',
  components: {
    ResponsabilitesAutocomplete,
  },
  props: {
    isUserLocal:{
      type: Boolean,
      default: true,
    },
    isUserNational:{
      type: Boolean,
      default: false,
    },
    parent:{
      type: Object,
      default: null,
    },
  },

  data: () => ({
    showUniqueResponsabiliteWarning: false,
    currentResponsabilite: {},
    lastResponsabilite: {},
    fakeReloadResponsabilite: false,
  }),
  computed: {
    ...mapGetters('session', [
      'getUserCdpeId',
      'canAffecteResponsabiliteLocale',
      'canAffecteResponsabiliteDepartementale',
      'canAffecteResponsabiliteNationale',
    ]),
    ...mapGetters('responsabilites',[
      'getResponsabilitesParent',
      'getResponsabilitesDepartemental',
      'getResponsabilitesNational',
    ]),
    ...mapState('responsabilites',{
      responsabilitesLocal: state => state.responsabilitesLocal,
    }),
    ...mapGetters('_parent', [
      'conseilsLocauxParAppartenance',
    ]),
    showConseilsLocauxParAppartenance() {
      return this.conseilsLocauxParAppartenance.length && !this.isUserLocal;
    },
    showResponsabiliteNationale() {
      let isParentCourantAdministrateurDepartemental = this.getResponsabilitesDepartemental.filter((responsabilite) => {
        return responsabilite.administrateur &&
            responsabilite.responsables.filter(responsable => {
               return responsable.parent_id === this.parent.id; }).length > 0;
            }).length > 0;
      return this.isUserNational && isParentCourantAdministrateurDepartemental && this.canAffecteResponsabiliteNationale;
    },
    isAdministrateurNational: {
      get: function () {
        return this.getResponsabilitesParent.national.length > 0;
      },
      // setter
      set: function (newValue) {
        this.changeAdministrateurNational(newValue);
      },
    },
  },
  methods: {
    ...mapActions('responsabilites', [
      'updateResponsabiliteLocale',
      'updateResponsabiliteDepartementale',
      'updateResponsabiliteNationale',
      'removeResponsabiliteLocale',
      'removeResponsabiliteDepartementale',
      'removeResponsabiliteNationale',
    ]),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    getResponsableName(responsabilite) {
      try {
        return `${responsabilite.responsables[0].nom} ${responsabilite.responsables[0].prenom}`;
      } catch (exception) {
        return '';
      }
    },

    closeShowUniqueResponsabiliteWarning() {
      this.fakeReloadResponsabilite = true;

      this.$nextTick(() => {
        this.fakeReloadResponsabilite = false;
      });
      this.showUniqueResponsabiliteWarning = false;
    },

    deleteLastResponsabilite(showMessage = true) {
      let data,
          parentId = this.parent.id;

      if (this.lastResponsabilite.type === 'local') {
        data = {
          conseil_local_id : this.lastResponsabilite.perimetreId,
          responsabilite_id: this.lastResponsabilite.id,
        };

        this.removeResponsabiliteLocale({
          parentId,
          data,
        }).then(() => {
          if (showMessage) {
            this.setSuccess({ info: 'La responsabilité locale a bien été supprimée.' });
          }
        });
      } else {
        data = {
          cdpe_id : this.lastResponsabilite.perimetreId,
          responsabilite_id: this.lastResponsabilite.id,
        };

        this.removeResponsabiliteDepartementale({
          parentId,
          data,
        }).then(() => {
          if (showMessage) {
            this.setSuccess({ info: 'La responsabilité départementale a bien été supprimée.' });
          }
        });
      }
    },

    updateCurrentResponsabilite() {
      let data,
          parentId = this.parent.id;

      if (this.currentResponsabilite.type === 'local') {
        data = {
          conseil_local_id : this.currentResponsabilite.perimetreId,
          responsabilite_id: this.currentResponsabilite.id,
        };

        this.updateResponsabiliteLocale({
          parentId,
          data,
        }).then(() => {
          this.setSuccess({ info: 'La responsabilité locale a bien été modifiée.' });
        });
      } else {
        data = {
          cdpe_id : this.currentResponsabilite.perimetreId,
          responsabilite_id: this.currentResponsabilite.id,
        };

        this.updateResponsabiliteDepartementale({
          parentId,
          data,
        }).then(() => {
          this.setSuccess({ info: 'La responsabilité départementale a bien été modifiée.' });
        });
      }
    },

    proceedResponsabiliteChange() {
      let shouldDelete = 
        this.currentResponsabilite.id === 0 && 
        this.lastResponsabilite.id !== 0;

      let shouldDeleteAdministrateurNational = 
        !this.currentResponsabilite.administrateur &&
        this.lastResponsabilite.administrateur && 
        this.isAdministrateurNational;

      if (shouldDeleteAdministrateurNational) {
        this.changeAdministrateurNational(false);
      }

      this.showUniqueResponsabiliteWarning = false;
      if (shouldDelete) {
        this.deleteLastResponsabilite();
        return;
      }

      if(this.currentResponsabilite.id !== this.lastResponsabilite.id) {
        this.updateCurrentResponsabilite();
      }

    },

    checkResponsabiliteIsUnique() {
      this.showUniqueResponsabiliteWarning =
          this.currentResponsabilite.is_responsabilite_unique &&
          this.currentResponsabilite.responsables.length
      ;

      return this.showUniqueResponsabiliteWarning;
    },

    changedResponsabilite(item) {
      this.currentResponsabilite = {
        ...item.responsabilite,
        type: item.type,
        perimetreId: item.perimetreId,
      };
      this.lastResponsabilite = {
        ...item.lastResponsabilite,
        type: item.type,
        perimetreId: item.perimetreId,
      };

      if (this.checkResponsabiliteIsUnique()) {
        return;
      }

      this.proceedResponsabiliteChange();
    },

    changeAdministrateurNational(value) {
      let data,
          parentId = this.parent.id,
          responsabiliteAdministrateurNationalId = this.getResponsabilitesNational[0].id;

      if (value) {
        data = {
          responsabilite_id: responsabiliteAdministrateurNationalId,
        };

        this.updateResponsabiliteNationale({
          parentId,
          data,
        }).then(() => {
          this.setSuccess({ info: 'La responsabilité nationale a bien été ajoutée.' });
        });
      } else {
        data = {
          responsabilite_id: responsabiliteAdministrateurNationalId,
        };

        this.removeResponsabiliteNationale({
          parentId,
          data,
        }).then(() => {
          this.setSuccess({ info: 'La responsabilité nationale a bien été supprimée.' });
        });

      }
    },
  },
};
</script>
