<template>
  <div>
    <v-card>
      <v-tabs
        v-model="active"
        :center-active="true"
        :centered="true"
        :grow="true"
      >
        <v-tab
          key="adhesions"
          href="#adhesions"
          ripple
        >
          Adhésions
        </v-tab>
        <v-tab
          key="abonnement"
          href="#abonnement"
          ripple
        >
          Abonnement
        </v-tab>
        <v-tab
          key="dons"
          href="#dons"
          ripple
        >
          Dons
        </v-tab>
        <v-tab-item value="adhesions">
          <AdhesionForm />
        </v-tab-item>
        <v-tab-item value="abonnement">
          <AbonnementForm />
          <AbonnementListe 
            :parent="parent"
          />
        </v-tab-item>
        <v-tab-item value="dons">
          <DonLayout />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import AdhesionForm from '../adhesion/Form';
import AbonnementForm from '../abonnement/Form';
import AbonnementListe from '../abonnement/Liste';
import DonLayout from '../don/Layout';

export default {
  name: 'AdditionalTabLayout',
  components: {
    AdhesionForm,
    AbonnementForm,
    AbonnementListe,
    DonLayout,
  },
  props: {
    parent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    let active,
        hash;

    hash = this.$route.hash.replace('#', '');
    active =  hash ? hash : 'adhesions';

    return {
      active,
    };
  },
  methods: {
    leave() {},
  },
};
</script>
