var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.environment !== 'prod')?_c('div',{staticClass:"text-center pa-2",class:{
      'red darken-4 white--text': _vm.environment === 'formation',
      'blue darken-2 white--text': _vm.environment === 'preprod'
    }},[_vm._v(" Base de "+_vm._s(_vm.environment)+" ")]):_vm._e(),(!_vm.isConnected)?_c('div',[_c('div',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"flex-grow-0"},[_c('spring-spinner',{attrs:{"animation-duration":3000,"size":60,"color":"#7fbc56"}})],1)],1)],1)]):_vm._e(),(_vm.isConnected)?_c('v-app-bar',[_c('v-toolbar-title',{class:{
        'red accent-2': _vm.environment === 'formation',
        'blue darken-2': _vm.environment === 'preprod'
      }},[_c('img',{staticClass:"logo",attrs:{"src":require("./assets/logo.png"),"alt":"FCPE Adhésions"},on:{"click":_vm.goHome}})]),_c('span',[_vm._v(_vm._s(_vm.getMenuActiveGroup ? _vm.getMenuActiveGroup.title : '')+" ")]),_vm._v(" "),(_vm.getMenuActiveSubgroup)?_c('span',[_vm._v(" - "+_vm._s(_vm.getMenuActiveSubgroup.title))]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":_vm.disconnect}},[_c('v-icon',[_vm._v("lock")]),_vm._v("Deconnexion ")],1)],1):_vm._e(),(_vm.isConnected)?_c('v-main',[_c('v-row',{staticClass:"no-gutters h-100"},[_c('v-col',{staticClass:"col-auto"},[_c('Menu',{class:{
            'red accent-2': _vm.environment === 'formation',
            'blue darken-2': _vm.environment === 'preprod'
          }})],1),_c('v-col',{staticClass:"col col-custom"},[_c('div',{staticClass:"pa-5"},[_c('router-view')],1)])],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"value":_vm.getError.display,"vertical":true}},[(_vm.getError.info)?_c('p',[_vm._v(" "+_vm._s(_vm.getError.info)+" ")]):_vm._e(),(_vm.getError.message)?_c('p',[_vm._v(" "+_vm._s(_vm.getError.message)+" ")]):_vm._e(),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.hideErrors}},[_vm._v(" Fermer ")])],1),_c('v-snackbar',{attrs:{"value":_vm.getSuccess.display,"color":"success"}},[(_vm.getSuccess.info)?_c('p',[_vm._v(" "+_vm._s(_vm.getSuccess.info)+" ")]):_vm._e(),(_vm.getSuccess.message)?_c('p',[_vm._v(" "+_vm._s(_vm.getSuccess.message)+" ")]):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.hideSuccess}},[_vm._v(" Fermer ")])],1),(_vm.isConnected)?_c('v-footer',{staticClass:"pa-3"},[_c('v-spacer'),_c('div',[_vm._v("© "+_vm._s(new Date().getFullYear()))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }