<template>
  <v-select
    v-model="conseilLocalSelected"
    v-validate="'required|propertyRequired:id'"
    no-data-text="Aucun conseil de disponible dans cette commune"
    :items="conseilLocaux"
    :label="formattedLabel"
    :readonly="readonly"
    :item-text="(item) => `${item.nom} (${item.code})`"
    :data-vv-name="label"
    :error-messages="errors.collect(label)"
    return-object
    @input="changed"
  />
</template>

<script>
export default {
  name: 'ConseilAutocomplete',
  props: {
    label: {
      type: String,
      default() {
        return 'Nom du Conseil';
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    conseilLocal: {
      type: Object,
      default() {
        return {};
      },
    },
    conseilLocaux: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    conseilLocalSelected: {},
  }),

  computed: {
    formattedLabel() {
      return `${this.label}${this.required ? ' *': ''}`;
    },
  },

  watch: {
    conseilLocal: {
      immediate: true,
      handler(val) {
        this.conseilLocalSelected = val;
      },
    },
  },

  created() {
    if (this.conseilLocal && this.conseilLocal.id) {
      this.conseilLocalSelected = this.conseilLocal;
    }
  },

  /**
   * This component should never have a specific validator instance
   * We inject the parent validator
  */
  inject: [
    '$validator',
  ],

  methods: {
    changed() {
      this.$emit('changedCl', this.conseilLocalSelected);
    },
  },
};
</script>

