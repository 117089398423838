import xhr from '../plugins/axios';

export default {
  namespaced: true,
  state: {
    anneeScolaireId: null,
    recapFinanciers: [],
  },

  mutations: {
    LOAD_RECAP_FINANCIERS(state, recapFinanciers) {
      state.recapFinanciers = recapFinanciers;
    },

    SET_ANNEE_SCOLAIRE_ID(state, anneeId) {
      state.anneeScolaireId = anneeId;
    },
  },

  actions: {
    async loadRecapFinanciersByParentId({ commit }, { parentId }) {
        return xhr.get(`api/admin/parent/${parentId}/recapitulatif_financier`).then(response => {
          commit('LOAD_RECAP_FINANCIERS', response.data);
        });
      },
    async sendDeleteFinancialData({ dispatch, state }, { parentId } ) {
      return xhr
        .delete(`api/admin/parent/${parentId}/donnee_financiere?annee_scolaire_id=${state.anneeScolaireId}`)
        .then(() => { 
          dispatch('loadAllFinancialDatasByParentId', { parentId }, { root: true }); 
        } );
    },
  },

  getters: {
    getRecapFinancier(state) {
      const recap = state.recapFinanciers.find(el => el.annee_scolaire_id === state.anneeScolaireId);
      if (recap) {
        return recap;
      }
      return { adhesion: { national:0, departemental:0, local:0 }, abonnement: [], don: [], donLocal:[] };
    },
    getAnneeScolaireId(state) {
      return state.anneeScolaireId;
    },
  },
};
  