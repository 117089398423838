import xhr from '../../plugins/axios';

export default {
    namespaced: true,
    state: {
        loading: false,
        dernierImport: null,
    },

    mutations: {
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_DERNIER_IMPORT(state, data) {
            state.dernierImport = data;
        },
    },

    actions: {
        uploadImportBourseAuxLivres({ commit }, { fichier }) {
            commit('SET_LOADING', true);
            const formData = new FormData();
            formData.append('file', fichier);
            return xhr
                .post('importBourseAuxLivres', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .finally(() => {
                    commit('SET_LOADING', false);
                });
        },
        loadDateDernierImport({ commit }) {
            return xhr.get('api/admin/validation/bal/dernierImport').then(({ data }) =>
                commit('SET_DERNIER_IMPORT', data));
        },
    },
    getters: {
        isLoading(state) {
            return state.loading;
        },
        getDernierImport(state) {
            return state.dernierImport;
        },
    },
};
