<template>
  <div class="pa-5">
    <v-row
      v-for="typeAbonnement in typeAbonnementsAvailable"
      :key="typeAbonnement.id"
      align="center"
    >
      <v-col
        cols="12"
        lg="4"
      >
        <h3>{{ typeAbonnement.revue.titre }}</h3>
        <h4>{{ isAdherent ? 'Abonnement avec adhésion' : 'Abonnement sans adhésion' }}</h4>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <p>Montant: <span>{{ typeAbonnement.tarif_unitaire | currency }}</span></p>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-btn
          color="primary"
          @click="subscribeToRevueWithDate(typeAbonnement)"
        >
          S'abonner
        </v-btn> 
      </v-col>
      <v-col
        cols="12"
        lg="6"
        offset-lg="3"
        align="center"
      >
        <v-text-field
          v-model="editingEmail"
          v-validate="'required|email|max:255'"
          append-icon="email"
          type="email"
          label="Email *"
          data-vv-name="email"
          :error-messages="errors.collect('email')"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import _filter from 'lodash/filter';

export default {
  name: 'AbonnementForm',
  data: () => ({
    email: undefined,
  }),
  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
    ...mapGetters('adhesion', {
      adhesion: 'getAdhesion',
    }),
    ...mapGetters('abonnement', {
      typeAbonnements: 'getTypeAbonnements',
      parentAbonnements: 'getParentAbonnements',
    }),

    isAdherent() {
      return this.adhesion != undefined;
    },

    /**
     * This property takes every type of abonnement available and then
     * filters them based on whether current parent is adherent
     * Filters it furthermore to only display those the parent is not already subscribed to
     * @returns {Array}
     */
    typeAbonnementsAvailable() {
      // Creates a copy
      let typesAbo = JSON.parse(JSON.stringify(this.typeAbonnements)),
          typeAbonnements = {};

      // If parent does not have an adhesion, we take the maximum price
      let extremum = this.isAdherent ?
          Math.min:
          Math.max;

      // Here we merge typesAbonnement base on a revue ID
      for (let i in typesAbo) {
        if (!typesAbo.hasOwnProperty(i)) {
          continue;
        }

        let typeAbo,
            tempTypeAbo,
            revueId;

        typeAbo = typesAbo[i];
        revueId = typeAbo.revue.id;
        tempTypeAbo = typeAbonnements[revueId];

        if (!tempTypeAbo) {
          typeAbonnements[revueId] = typeAbo;
          continue;
        }
        tempTypeAbo.tarif_unitaire = extremum(
            tempTypeAbo.tarif_unitaire,
            typeAbo.tarif_unitaire,
        );
      }

      let revueWithParentAbonnementForCurrentYearIds = this.parentAbonnements
            .map(parentAbonnement => parentAbonnement.type_abonnement.revue.id);

      return _filter(typeAbonnements, typeAbonnement => !revueWithParentAbonnementForCurrentYearIds.includes(typeAbonnement.revue.id));
    },

    editingEmail: {
      get() {
        return this.email !== undefined ? this.email : this.parent.contact.email;
      },
      set(email) {
        this.email = email;
      },
    },
  },

  created() {
    this.loadAnneesScolaires();
  },

  methods: {
    leave() {},
    ...mapActions('abonnement',[
      'subscribeToRevue',
    ]),
    ...mapActions('parametrages/anneeScolaire', {
      loadAnneesScolaires: 'loadFilterAnneesScolaires',
    }),

    subscribeToRevueWithDate(typeAbonnement) {
      
      if (typeAbonnement.subscriptionAsked) {
        //avoid double-click
        return;
      }
      typeAbonnement.subscriptionAsked=true;

      this.subscribeToRevue({
        parentId: this.parent.id,
        email: this.editingEmail,
        date: new Date().toISOString(),
        revueId: typeAbonnement.revue.id,
      }).catch(() => {
        typeAbonnement.subscriptionAsked=false;
      });
    },
  },
};
</script>