import xhr from '../plugins/axios';

export default {
  namespaced: true,
  state: {
    dons: [],
    date: new Date().toISOString().substring(0, 10),

    anneeScolaireId: null,
  },

  mutations: {
    SET_DATE(state, date) {
      state.date = date;
    },
    LOAD_DONS(state, dons) {
      state.dons = dons;
    },
    SET_ANNEE_SCOLAIRE_ID(state, anneeId) {
      state.anneeScolaireId = anneeId;
    },
  },

  actions: {
    addDonDepartemental({ dispatch, state }, { parentId, don }) {
      return xhr.post(`api/admin/parent/${parentId}/don/departemental`, { ...don, annee_scolaire_id: state.anneeScolaireId }).then(() => {
        dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
        dispatch('loadDonsByParentId', { parentId });
      });
    },
    addDonLocal({ dispatch, state }, { parentId, don }) {
      return xhr.post(`api/admin/parent/${parentId}/don/local`, { ...don, annee_scolaire_id: state.anneeScolaireId }).then(() => {
        dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
        dispatch('loadDonsByParentId', { parentId });
      });
    },

    editDonDepartemental(commit, { donId, parentId,  don }) {
      return xhr.put(`api/admin/parent/${parentId}/don/departemental/${donId}`, don);
    },

    editDonLocal(commit, { donId, parentId,  don }) {
      return xhr.put(`api/admin/parent/${parentId}/don/local/${donId}`, don);
    },

    loadDonsByParentId({ commit }, { parentId }) {
      return xhr.get(`api/admin/parent/${parentId}/don`).then(response => {
        commit('LOAD_DONS', response.data);
      });
    },

    deleteDonDepartementalByIdAndParentId({ dispatch }, { donId, parentId }) {
      return xhr.delete(`api/admin/parent/${parentId}/don/departemental/${donId}`).then(() => {
        dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
        dispatch('loadDonsByParentId', { parentId });
      });
    },

    deleteDonLocalByIdAndParentId({ dispatch }, { donId, parentId }) {
      return xhr.delete(`api/admin/parent/${parentId}/don/local/${donId}`).then(() => {
        dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
        dispatch('loadDonsByParentId', { parentId });
      });
    },

    changeDonDate({ commit }, date) {
      commit('SET_DATE', date);
    },
  },

  getters: {
    getDons(state) {
      return state.dons.filter(d => d.annee_scolaire_id === state.anneeScolaireId);
    },

    getDonDate(state) {
      return state.date;
    },
  },
};
