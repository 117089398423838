const workflowStore = {
  workflow: {
    validation_saisies_adhesion_local: {
      results: [],
      cdpeOrClId: 0,
      cdpeId: 0,
    },

    reception_adhesion_departement: {
      results: [],
    },

    transfert_adhesions_departemental: {
      results: [],
    },

    validation_adhesions_en_ligne: {
      results: [],
    },
    
    loading: false,
  },
};

export default workflowStore;
