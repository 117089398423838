import xhr from '../plugins/axios.js';

const communeAction = {
  getClByCommuneId(commit, { communeId }) {
    return xhr.get(`api/admin/commune/${encodeURIComponent(communeId)}/cl`);
  },
  searchCommunes(commit, { query, parameters }) {
    return xhr.get(`api/admin/commune/search/${query}?${parameters}`);
  },

  getEtablissementScolaireByCommuneId(commit, { communeId }) {
    return xhr.get(`api/admin/commune/${communeId}/etablissementScolaire`);
  },
};

export default communeAction;
