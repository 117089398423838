<script>
import { statutCanEditParent, statutCanDeleteParent } from '../../config/statutParent';
import { mapGetters } from 'vuex';
export default {
  name: 'DroitsMixin',

  computed: {
    ...mapGetters('session', {
      getPerimetreUser: 'getPerimetreUser',
      isUserLocal: 'isUserLocal',
      canEditParent: 'canEditParent',
      canDeleteParent: 'canDeleteParent',
      canViewTableauBordParent: 'canViewTableauBordParent',
    }),
  },
  methods: {
    canEdit(parent) {
      return statutCanEditParent(parent) && this.canEditParent && (!this.isUserLocal || parent.conseil_local.id === this.getPerimetreUser.perimetre_id);
    },
    canDelete(parent) {
      return statutCanDeleteParent(parent) && this.canDeleteParent && (!this.isUserLocal || parent.conseil_local.id === this.getPerimetreUser.perimetre_id);
    },
  },
};
</script>