<template>
  <div>
    <v-form ref="form">
      <h1>Editer cette part</h1>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <ConseilAutocomplete
            :conseil-local="conseilLocal"
            :conseil-locaux="conseilLocaux"
            :label="'Nom du conseil'"
            :disabled="true"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="selectedNiveau"
            v-validate="'required'"
            required
            :item-value="(item) => item"
            item-text="libelle"
            no-data-text="Aucun niveau de disponible"
            :items="niveauxCL"
            :loading="niveauxLoading"
            label="Choisissez le Niveau *"
            data-vv-name="niveau"
            :error-messages="errors.collect('niveau')"
            item-key="id"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <PaiementSelect
            :loaded-moyen-paiement="loadedMoyenPaiement"
            @moyenPaiementSelected="updateMoyenPaiement"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          Montant:
          <span
            v-if="selectedNiveau && selectedNiveau.id"
            class="price"
          >{{ selectedNiveau.tarif | currency }}</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            :disabled="!canEdit"
            color="primary"
            @click="validate"
          >
            <v-icon>{{ "edit" }}</v-icon>
            {{ "Modifier" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ConseilAutocomplete from '../../../partials/ConseilAutocomplete';
import PaiementSelect from '../../../partials/paiement/select';
import { mapActions, mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';

export default {
  name: 'EditPartForm',
  components: {
    ConseilAutocomplete,
    PaiementSelect,
  },
  props: {
    parent: {
      type: Object,
      default: () => {
        return {};
      },
    },
    part: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    selectedNiveau: { id: 0 , tarif: 0 },
    selectedMoyenPaiement: null,
  }),
  computed: {
    ...mapGetters('adhesion', {
      niveauxCL: 'getNiveauxCL',
      niveauxLoading: 'getNiveauxLocalLoading',
      adhesion: 'getAdhesion',
    }),
    isAdherent() {
      return this.adhesion != undefined;
    },
    loadedMoyenPaiement() {
      return this.part.moyen_paiement;
    },
    conseilLocaux() {
      return [this.conseilLocal];
    },
    conseilLocal() {
      if (_isEmpty(this.part)) {
        return {
          id: null,
          nom: '',
          code: '',
        };
      }
      return {
        id: this.part.cl.id,
        nom: this.part.cl.nom,
        code: this.part.cl.code,
      };
    },

    canEdit() {
      return this.selectedMoyenPaiement
          && this.selectedMoyenPaiement !== this.loadedMoyenPaiement
          ||
          !_isEmpty(this.selectedNiveau) && !_isEmpty(this.part.niveau)
          && this.selectedNiveau.id !== this.part.niveau.id
        ;
    },
  },

  watch: {
    part: {
      immediate: true,
      handler(value) {
        this.updateSelectedNiveau(value.niveau);
      },
    },
    niveauxCL() {
      //Sometimes niveauxCL is loaded after part, then need to call updateSelectedNiveau again
      this.updateSelectedNiveau(this.selectedNiveau);
    },
  },

  methods: {
    ...mapActions('adhesion', [
      'sendParts',
    ]),

    updateMoyenPaiement(moyenPaiement) {
      this.selectedMoyenPaiement = moyenPaiement;
    },

    /**
     * Niveau in part does not return all information needed
     * Informations are contained in niveauxCL so we find them base on id
     * @param niveau
     */
    updateSelectedNiveau(niveau) {
      if (!niveau) {
        return;
      }

      if (this.niveauxCL.length === 0) {
          this.selectedNiveau = niveau;
        return;
      }

      let foundNiveau = this.niveauxCL.find((_niveau) => {
        return _niveau.id === niveau.id;
      });

      this.selectedNiveau = foundNiveau;
    },

    validate() {
      // Creates a copy of parts that is not reactive
      let parts = JSON.parse(JSON.stringify(this.adhesion.parts));

      let self = this;

      parts.local.forEach((part) => {
        if (part.cl.id === this.conseilLocal.id) {
          part.niveau = !_isEmpty(this.selectedNiveau) ? this.selectedNiveau : this.niveau;
          part.moyen_paiement = this.selectedMoyenPaiement;
        }
      });

      this.sendParts({
        parentId: this.parent.id,
        parts,
        date: this.adhesion.date,
        isAdherent: this.isAdherent,
      }).then(() => {
        self.$emit('partModified');
      });
    },
  },
};
</script>
