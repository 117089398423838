import types from '../actions/types';
import typePerimetre from '../auth/typePerimetre';
import droits from '../auth/droits.js';
import xhr from '../plugins/axios.js';

export const sessionGetter = {
         getUser: (state) => {
           return state.user;
         },
         getError: (state) => {
           return state.error;
         },
         getSuccess: (state) => {
           return state.success;
         },
         isConnected: (state) => {
           return Object.keys(state.user).length > 0;
         },
         getDroits: (state) => {
           return sessionGetter.isConnected(state) ? state.user.droits : [];
         },
         getPerimetreUser: (state) => {
           return sessionGetter.isConnected(state) ? state.user.perimetre : {};
         },
         getUserCdpeId: (state) => {
           return sessionGetter.isUserDepartemental(state)
             ? state.user.perimetre.perimetre_id
             : sessionGetter.isUserLocal(state)
             ? state.user.perimetre.perimetre_appartenance_id
             : 0;
         },
         getUserConseilLocalId: (state) => {
           return sessionGetter.isUserLocal(state)
             ? state.user.perimetre.perimetre_id
             : 0;
         },
         isUserNational: (state) => {
           return sessionGetter.isConnected(state)
             ? state.user.perimetre.type_perimetre_id === typePerimetre.NATIONAL
             : false;
         },
         isUserDepartemental: (state) => {
           return sessionGetter.isConnected(state)
             ? state.user.perimetre.type_perimetre_id ===
                 typePerimetre.DEPARTEMENTAL
             : false;
         },
         isUserLocal: (state) => {
           return sessionGetter.isConnected(state)
             ? state.user.perimetre.type_perimetre_id === typePerimetre.LOCAL
             : false;
         },
         canEditParent: (state) =>
           sessionGetter.getDroits(state).includes(droits.FOYER_MODIFICATION),
         canDeleteParent: (state) =>
           sessionGetter.getDroits(state).includes(droits.FOYER_SUPPRESSION),
         canViewTableauBordParent: (state) =>
           sessionGetter.getDroits(state).includes(droits.TABLEAUX_BORD),
         canEditPerimetre: (state) =>
           sessionGetter
             .getDroits(state)
             .includes(droits.PERIMETRE_MODIFICATION),
         canDeletePerimetre: (state) =>
           sessionGetter
             .getDroits(state)
             .includes(droits.PERIMETRE_SUPPRESSION),
         canAffecteResponsabiliteLocale: (state) =>
           sessionGetter
             .getDroits(state)
             .includes(droits.AFFECTATION_RESPONSABILITE_LOCALE),
         canAffecteResponsabiliteDepartementale: (state) =>
           sessionGetter
             .getDroits(state)
             .includes(droits.AFFECTATION_RESPONSABILITE_DEPARTEMENTALE),
         canAffecteResponsabiliteNationale: (state) =>
           sessionGetter
             .getDroits(state)
             .includes(droits.AFFECTATION_RESPONSABILITE_NATIONALE),
       };

function initialState() {
    return {
        user: {},
        error: {
            display: false,
            info: '',
            message: '',
        },
        success: {
            display: false,
            info: '',
            message: '',
        },
    };
}
export default {
  namespaced: true,
  state: initialState,
  mutations: {
      [types.SESSION_LOGOUT](state) {
          state.user = {};
      },
      [types.SESSION_LOGIN](state, user) {
          state.user = user;
      },
      [types.SESSION_SET_BLOQUE](state, perimetre) {
        state.user.perimetre.bloque_dans_x_jours = perimetre.bloque_dans_x_jours;
        state.user.perimetre.date_blocage = perimetre.date_blocage;
      },
      [types.ERRORS_MESSAGE](state, errors) {
          state.error.info = errors.info ? errors.info : 'Un problème technique est survenu, veuillez contacter le support technique';
          state.error.message = errors.message;
          state.error.display = true;
          setTimeout(() => {
              state.error.display = false;
              state.error.info = '';
              state.error.message = '';
          }, 7000);
      },
      [types.ERRORS_HIDE](state) {
          state.error.display = false;
          state.error.info = '';
          state.error.message = '';
      },
      [types.SUCCESS_MESSAGE](state, success) {
          state.success.info = success.info ? success.info : 'Un problème technique est survenu, veuillez contacter le support technique';
          state.success.message = success.message;
          state.success.display = true;
          setTimeout(() => {
              state.success.display = false;
              state.success.info = '';
              state.success.message = '';
          }, 2500);
      },
      [types.SUCCESS_HIDE](state) {
          state.success.display = false;
          state.success.info = '';
          state.success.message = '';
      },
  },
  actions: {
      logout({ commit }) {
          commit(types.SESSION_LOGOUT);
          commit('recherche/resetState', {}, { root: true });
      },
      login({ commit }, user) {
          commit(types.SESSION_LOGIN, user);
      },
      async checkPerimetreUserIsBlocked({ commit, state }) {
        const perimetre = state.user.perimetre;
        if (typeof perimetre.bloque_dans_x_jours == 'undefined') {
          if (perimetre.type_perimetre_id === typePerimetre.LOCAL) {
            const response = await xhr.get(`api/admin/cdpe/${perimetre.perimetre_appartenance_id}`);
            commit(types.SESSION_SET_BLOQUE, response.data);
          } else if (perimetre.type_perimetre_id === typePerimetre.DEPARTEMENTAL) {
            const response = await xhr.get(`api/admin/cdpe/${perimetre.perimetre_id}`);
            commit(types.SESSION_SET_BLOQUE, response.data);
          }
        }
      },
      setErrors({ commit }, errors) {
          commit(types.ERRORS_MESSAGE, errors);
      },
      hideErrors({ commit }) {
          commit(types.ERRORS_HIDE);
      },
      setSuccess({ commit }, success) {
          commit(types.SUCCESS_MESSAGE, success);
      },
      hideSuccess({ commit }) {
          commit(types.SUCCESS_HIDE);
      },
  },
  getters: sessionGetter,
};
