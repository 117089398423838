import xhr from '../../plugins/axios.js';

export default {
  namespaced: true,
  state: {
    roleByName: {},
    loading: false,
    loadingUpdate: null,
  },
  mutations: {
    setLoading(state, toggle) {
      state.loading = toggle;
    },
    setLoadingUpdate(state, toggle) {
      state.loadingUpdate = toggle;
    },
    setRoleByName(state, role) {
      state.roleByName = role;
    },
  },
  actions: {
    toggleLoading({ commit }, { stateLoading }) {
      commit('setLoading', stateLoading);
    },
    loadRoleByName({ commit }, roleName) {
      commit('setLoading', true);
      return xhr
        .get(`api/admin/parametrages/role/${roleName}`)
        .then((response) => {
          commit('setRoleByName', response.data);
          commit('setLoading', false);
        });
    },
    activateRight({ commit }, { droit, role }) {
      commit('setLoadingUpdate', droit);
      return xhr
        .post(`/api/admin/parametrages/role/${role.nom}/droit/${droit}`)
        .then(() => {
          commit('setRoleByName', role);
          commit('setLoadingUpdate', null);
        });
    },
    deactivateRight({ commit }, { droit, role }) {
      commit('setLoadingUpdate', droit);
      return xhr
        .delete(`/api/admin/parametrages/role/${role.nom}/droit/${droit}`)
        .then(() => {
          commit('setRoleByName', role);
          commit('setLoadingUpdate', null);
        });
    },
  },
  getters: {
    getRoleRights(state, getters, rootState, rootGetters) {
      let listDroits = state.roleByName.droits;
      if (listDroits == undefined) {
        return [];
      }
      const roleName = state.roleByName.nom;
      const listDroitsUtilisateurConnecte = rootState.session.user.droits;
      const isUserNational = rootGetters['session/isUserNational'];
      let listDroitsModifiable = listDroits.filter((droit) => {
        return droitEstModifiableDansRole(droit, roleName) &&
          (listDroitsUtilisateurConnecte.includes(droit.nom) || isUserNational);
      });
      return listDroitsModifiable;
    },
  },
};

function droitEstModifiableDansRole(droit, roleName) {
  return (droit.national && roleName.includes('ROLE_NATIONAL'))
      || (droit.departemental && roleName.includes('ROLE_DEPARTEMENTAL'))
      || (droit.local && roleName.includes('ROLE_LOCAL'));
}
