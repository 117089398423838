import xhr from '../plugins/axios';
import { removeAccents } from '../config/helpers';
import _get from 'lodash/get';
import { STATUTS_JURIDIQUE } from '../config/statutJuridique';

function initialState() {
  return {
    filterConseilsLocaux : [],
    list: [],
    loading: false,
    filters: [],
    statutsJuridique: [],
    degreEnseignement: {},
    secteurGeographique: {},

    currentSecteursGeographique: [],
    currentDegresEnseignement: [],

    currentConseilLocal: {
      adresse_legale: {
        commune: {
          code_postal: '',
          id: 0,
          ville: '',
        },
        ligne1: '',
        ligne2: '',
      },
      code: '',
      commentaire: null,
      contact: {
        email: '',
        id: 0,
        telephone_fixe: '',
        telephone_mobile: '',
      },
      degre_enseignement: {
        codification: 0,
        degre_enseignement_id: 0,
        libelle: '',
        nom_court: '',
      },
      id: 0,
      nb_adherents: 0,
      nbr_rp_direct: 0,
      nbr_rp_service: 0,
      nom: '',
      secteur_geographique: {
        lettre: '',
        libelle: '',
        secteur_geographique_id: 0,
      },
      statut_juridique: {
        description: '',
        nom: '',
        position: 0,
        statut_juridique_id: 0,
      },
    },
    currentCdpe: {
      id: 0,
    },
  };
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setFilterConseilsLocaux(state, data) {
      state.filterConseilsLocaux = data;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    setCommune(state, commune) {
      state.currentConseilLocal.adresse_legale.commune = commune;
    },
    /**
     *
     * @param state
     * @param cdpeId
     */
    setCurrentDegresEnseignement(state, cdpeId) {
      state.currentDegresEnseignement = state.degreEnseignement[cdpeId];
    },

    /**
     *
     * @param state
     * @param cdpeId
     */
    setCurrentSecteurGeographique(state, cdpeId) {
      state.currentSecteursGeographique = state.secteurGeographique[cdpeId];
    },

    /**
     * @param state
     * @param conseilLocaux
     */
    populateList(state, conseilLocaux) {
      if (typeof conseilLocaux !== 'object') {
        throw new Error('conseilLocal.list should always be an array.');
      }

      state.list = conseilLocaux;
    },

    /**
     * @param state
     * @param toggle
     */
    toggleLoading(state, toggle) {
      state.loading = toggle;
    },

    /**
     * @param state
     * @param filters
     */
    setFilters(state, filters) {
      state.filters = filters;
    },

    setCurrentConseilLocal(state, conseilLocal) {
      state.currentConseilLocal = conseilLocal;
    },

    populateSecteurGeographique(state, { cdpeId, secteurGeographique }) {
      state.secteurGeographique[cdpeId] = secteurGeographique;
    },

    populateDegreEnseignement(state, { cdpeId, degreEnseignement }) {
      state.degreEnseignement[cdpeId] = degreEnseignement;
    },

    populateStatutsJuridique(state, statutsJuridique) {
      state.statutsJuridique = statutsJuridique;
    },
  },
  actions: {
    loadFilterConseilsLocaux({ commit }, cdpeId) {
      return xhr.get(`api/admin/cdpe/${cdpeId}/cl`).then(response => {
        commit('setFilterConseilsLocaux', response.data);
      });
    },
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Loads degreEnseignement for given cdpeId only if not already present in state
     * Allows caching
     *
     * @param commit
     * @param cdpeId
     * @returns {*}
     */
    loadSecteurGeographique({ commit, state }, { cdpeId }) {
      if (typeof state.secteurGeographique[cdpeId] !== 'undefined') {
        commit('setCurrentSecteurGeographique', cdpeId);
        return;
      }

      return xhr
        .get(`api/admin/parametrages/secteurgeographique?cdpe=${cdpeId}`)
        .then(response => {
          commit('populateSecteurGeographique', {
            cdpeId,
            secteurGeographique: response.data,
          });

          commit('setCurrentSecteurGeographique', cdpeId);
        });
    },
    /**
     * Loads degreEnseignement for given cdpeId only if not already present in state
     * Allows caching
     *
     * @param commit
     * @param cdpeId
     * @returns {*}
     */
    loadDegreEnseignement({ commit, state }, { cdpeId }) {
      if (typeof state.degreEnseignement[cdpeId] !== 'undefined') {
        commit('setCurrentDegresEnseignement', cdpeId);
        return;
      }

      return xhr
        .get(`api/admin/parametrages/degredenseignement?perimetre=${cdpeId}`)
        .then(response => {
          commit('populateDegreEnseignement', {
            cdpeId,
            degreEnseignement: response.data,
          });

          commit('setCurrentDegresEnseignement', cdpeId);
        });
    },
    loadStatutJuridique({ commit }) {
      commit('populateStatutsJuridique', STATUTS_JURIDIQUE);
    },

    /**
     *
     * @param commit
     * @param cdpeId
     * @returns {*}
     */
    loadList({ commit }, { cdpeId }) {
      commit('toggleLoading', true);
      return xhr
        .get(`api/admin/cdpe/${cdpeId}/cl/withEtablissements`)
        .then(response => {
          commit('populateList', response.data);
        })
        .finally(() => {
          commit('toggleLoading', false);
        });
    },

    loadConseilLocal({ commit }, { cdpeId, conseilLocalId }) {
      return xhr
        .get(`api/admin/cdpe/${cdpeId}/cl/${conseilLocalId}`)
        .then(response => {
          commit('setCurrentConseilLocal', response.data);
        });
    },

    create(store, { conseilLocal, cdpeId }) {
      return xhr
        .post(`api/admin/cdpe/${cdpeId}/cl`, conseilLocal)
        .then(response => response.data);
    },

    save(store, { cdpeId, conseilLocal, conseilLocalId }) {
      return xhr.put(
        `api/admin/cdpe/${cdpeId}/cl/${conseilLocalId}`,
        conseilLocal
      );
    },

    /**
     *
     * @param dispatch
     * @param conseilLocalId
     * @param cdpeId
     * @returns {*}
     */
    'delete': ({ dispatch }, { conseilLocalId, cdpeId }) => {
      return xhr
        .delete(`api/admin/cdpe/${cdpeId}/cl/${conseilLocalId}`)
        .then(() => {
          dispatch('loadList', { cdpeId });
        });
    },
  },
  getters: {
    getFilterConseilsLocaux(state) {
      return state.filterConseilsLocaux;
    },
    getStatutsJuridique(state) {
      return state.statutsJuridique.sort(
        (statutA, statutB) => statutA.position - statutB.position
      );
    },

    getAllConseilLocaux(state) {
      return state.list.map(conseilLocal => {
        conseilLocal.telephone = conseilLocal.contact.telephone_fixe
          ? conseilLocal.contact.telephone_fixe
          : conseilLocal.contact.telephone_mobile;
        return conseilLocal;
      });
    },

    getConseilLocaux(state, getters) {
      if (!state.filters.length) {
        return getters.getAllConseilLocaux;
      }
      return getters.getAllConseilLocaux.filter(conseilLocal => {
        return state.filters.some(filter => {
          // Standard Behavior
          if (filter.name !== 'nom_etablissement') {
            /**
             * Compare case-insensitive, accent replaced filter value with conseilLocal field
             */
            return removeAccents(_get(conseilLocal, filter.name)).match(
              new RegExp(`${removeAccents(filter.value)}`, 'i')
            );
          }

          /**
           * Custom behavior for etablissements
           *
           * @quickNDirty Filter should probably have a handler with its own comparison strategy
           * to respect open/closed principle
           */
          return conseilLocal.etablissements.some(etablissement => {
            if (
              filter.shouldEtablissementBePrincipal &&
              !etablissement.is_principal
            ) {
              return false;
            }
            return removeAccents(etablissement.nom).match(
              new RegExp(`${removeAccents(filter.value)}`, 'i')
            );
          });
        });
      });
    },
  },
};