<template>
  <v-card>
    <div class="pa-5">
      <v-row>
        <v-col cols="12">
          <v-btn
            class="mb-2"
            color="primary"
            @click.stop="returnToDons"
          >
            <v-icon>mdi-chevron-left</v-icon>Retour
          </v-btn>
        </v-col>
        <v-col cols="12">
          <EditDonForm
            v-if="don"
            :don="don"
            :parent="parent"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import EditDonForm from '../components/parent/don/EditForm';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EditionDonContainer',
  components: {
    EditDonForm,
  },
  computed: {
    ...mapGetters({
      parent: 'getParent',
    }),
    ...mapGetters('don', {
      dons: 'getDons',
    }),

    don() {
      return this.dons.find((don) => {
        return don.id === parseInt(this.$route.params.donId);
      });
    },
  },
  created() {
    let parentId = this.$route.params.parentId;
    this.loadParentInfosById({ parentId }).then(() => {
      this.loadDonsByParentId({ parentId });
    });
  },
  methods: {
    ...mapActions('don', [
      'loadDonsByParentId',
    ]),
    ...mapActions([
      'loadParentInfosById',
      'setParentIsLoading',
    ]),
    returnToDons() {
      this.setParentIsLoading();
      this.$router.push({
        name: 'edition-parent-stepId',
        params: {
          parentId: this.parent.id,
          stepId: 3,
        },
        hash: '#dons',
      });
    },

  },
};
</script>
