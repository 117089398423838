import xhr from '../plugins/axios';
import { uniq } from '../config/helpers.js';

export default {
  namespaced: true,
  state: {
    anneeScolaireId: null,

    partsNAT: [],
    niveauxDEP: [],
    niveauxCL: [],
    adhesions: [],

    conseilLocauxParent: [],
    
    loading: {
      niveaux_local: false,
      niveaux_departemental: false,
    },
  },

  mutations: {
    SET_PARTS_NAT(state, parts) {
      state.partsNAT = parts;
    },
    SET_NIVEAUX_DEP(state, niveaux) {
      state.niveauxDEP = niveaux;
    },
    SET_NIVEAUX_CL(state, niveaux) {
      state.niveauxCL = niveaux;
    },
    SET_ADHESIONS(state, adhesions) {
      state.adhesions = adhesions;
    },
    NIVEAUX_LOCAL_TOGGLE_LOADING(state, toggle) {
      state.loading.niveaux_local = toggle;
    },
    NIVEAUX_DEPARTEMENTAL_TOGGLE_LOADING(state, toggle) {
      state.loading.niveaux_departemental = toggle;
    },
    LOAD_CLS(state, cl) {
      state.conseilLocauxParent = cl;
    },
    SET_ANNEE_SCOLAIRE_ID(state, anneeId) {
      state.anneeScolaireId = anneeId;
    },
  },

  actions: {
    loadPartNational({ commit }) {
      return xhr.get('api/admin/adhesion/part/national').then(response => {
          commit('SET_PARTS_NAT', response.data);
      });
    },
    loadNiveauxPartDepart({ commit }, conseilLocalId) {
      commit('NIVEAUX_DEPARTEMENTAL_TOGGLE_LOADING', true);

      return xhr.get(`api/admin/adhesion/niveau/departemental/${conseilLocalId}`).then(response => {
        commit('SET_NIVEAUX_DEP', response.data);
        commit('NIVEAUX_DEPARTEMENTAL_TOGGLE_LOADING', false);
      });
    },
    loadNiveauxPartLocal({ commit }, id) {
      commit('NIVEAUX_LOCAL_TOGGLE_LOADING', true);
  
      return xhr.get(`api/admin/adhesion/niveau/local/${id}`).then(response => {
        commit('SET_NIVEAUX_CL', response.data);
        commit('NIVEAUX_LOCAL_TOGGLE_LOADING', false);
      });
    },

    loadCLs({ commit, rootGetters }) {


      let cls = rootGetters['getEnfants'].map(enfant => {
        return {
          nom: enfant.classe.etablissement_scolaire.conseil_local.nom,
          code: enfant.classe.etablissement_scolaire.conseil_local.code,
          id: enfant.classe.etablissement_scolaire.conseil_local.id,
        };
      });

      commit('LOAD_CLS', uniq(cls, 'nom'));     
    },

    loadPartsByParentId({ commit }, { parentId }) {
      return xhr.get(`api/admin/parent/${parentId}/adhesion`).then(response => {
        commit('SET_ADHESIONS', response.data);
      });
    },

    sendParts({ dispatch, getters, state }, { parentId, parts, date, isAdherent }) {
      let formattedParts = formatPartForSend(parts, isAdherent);
      let promise = null;
      if(isAdherent) {
        let params = { date, parts: formattedParts };
        promise = xhr.put(`api/admin/parent/${parentId}/adhesion/${getters.getAdhesion.id}`, params);
      } else {
        promise = dispatch('parametrages/anneeScolaire/loadFilterAnneesScolaires', null, { root: true }).then(() => {
          let params = { date, parts: formattedParts, annee_scolaire_id: state.anneeScolaireId };
          return xhr.post(`api/admin/parent/${parentId}/adhesion`, params);
        });
      }
      return promise.then(
        response => {
          dispatch('loadPartsByParentId', { parentId });
          dispatch('abonnement/loadAbonnementByParentId', { parentId }, { root: true });
          dispatch('recapFinancier/loadRecapFinanciersByParentId', { parentId }, { root: true });
          dispatch('session/setSuccess', { info: 'L\'adhésion a bien été prise en compte', message: '' }, { root: true });
          return response.data;
        }
      );
    },
  },

  getters: {
    getPartNAT(state) {
      return state.partsNAT.find(el => el.annee_scolaire.id === state.anneeScolaireId);
    },
    getNiveauxDEP(state) {
      return state.niveauxDEP
        .filter(el => el.annee_scolaire_id === state.anneeScolaireId)
        .sort(
          // adhesions clasiques par ordre de position puis adhesion en ligne par ordre de position
          // ie ordre lexicographique sur (adhesion_en_ligne, position)
          (niveauA, niveauB) => 
            niveauA.adhesion_en_ligne === niveauB.adhesion_en_ligne ?
              niveauA.position - niveauB.position : 
              niveauA.adhesion_en_ligne - niveauB.adhesion_en_ligne
        );
    },
    getNiveauxCL(state) {
      return state.niveauxCL
        .filter(el => el.annee_scolaire_id === state.anneeScolaireId)
        .sort((niveauA, niveauB) => niveauA.position - niveauB.position);
    },
    getAdhesion(state) {
      return state.adhesions.find(el => el.annee_scolaire_id === state.anneeScolaireId);
    },
    getNiveauxLocalLoading(state) {
      return state.loading.niveaux_local;
    },
    getNiveauxDepartementalLoading(state) {
      return state.loading.niveaux_departemental;
    },

    getConseilLocauxParent(state) {
      return state.conseilLocauxParent;
    },
    getConseilLocauxParentPourPartLocal(state, getters, rootState, rootGetters) {
      //Mantis 18909 : On ne doit pouvoir saisir de part locale que sur un conseil local du même département.
      const codeCdpe = rootGetters['getParent'].conseil_local.code.substring(0,3);    
      return state.conseilLocauxParent.filter(
        cl => cl.code.substring(0, 3) === codeCdpe
      );
    },
  },
};
  
function formatPartForSend(parts) {
  let local = parts.local.map((part) => {
    return {
      cl: {
        id: part.cl.id,
      },
      niveau: {
        id: part.niveau.id,
      },
      moyen_paiement: part.moyen_paiement,
    };
  });

  let departemental = {
    niveau: {
      id: parts.departemental.niveau.id,
    },
    moyen_paiement: parts.departemental.moyen_paiement,
  };

  return {
    departemental,
    local,
  };
}